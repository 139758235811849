import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsProps () {

  let mainTitle = "ReactJS Props"
  let titleDescription = "When we designing a webpage with ReactJS, some components will be reused mutliple times, e.g. the navigation bar, similar to reuse content with EJS in NodeJS, with ReactJS & Props, you could mantain the component in a single location!"


  let advancedTopicsReactJsPropsList = [
    { "adrjsprid": 1, "subtitle": "Step 1", "imgSrc": "reuse_title", "subTitleDescription": "First, I created a title.js to input the title that will be used multiple times:" },
    { "adrjsprid": 2, "subtitle": "Step 2", "imgSrc": "reuse_component", "subTitleDescription": "To use it, I just simple import the title.js file into the file I would like to use it, simple right?" },
    { "adrjsprid": 3, "subtitle": "Step 3", "imgSrc": "title_props", "subTitleDescription": "Sometimes, we would also like to have a flexibility to change something depends on situation rather than hardcode the title in title.js, to do so, props comes to play. Props (Properties) allow user to send information from parent component to child component."  },
    { "adrjsprid": 4, "subtitle": "Step 4", "imgSrc": "productlist_props", "subTitleDescription": "In my example, the parent components are Product List, Product & Checkout, while the child component will be the title.js. Now, we modify the title.js as above, where mainTitle is a customized name that you could have a difference naming."  },
    { "adrjsprid": 5, "subtitle": "Multiple props", "imgSrc": "sub_props", "subTitleDescription": "Multiple props are allowed to."  },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsPropsList.map(advancedTopicsReactJsPropsList => (
          <div key={advancedTopicsReactJsPropsList.adrjsprid}>
            <Content
              subtitle={advancedTopicsReactJsPropsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsPropsList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsPropsList.imgSrc}
              subTitleDescription={advancedTopicsReactJsPropsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}