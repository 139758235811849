import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesInstallationOperation () {

  let mainTitle = "Kubernetes Installation & Operation"
  let titleDescription = ""

  let containerOrchestrationKubernetesInstallationOperationList = [
    { "ctorchkuioid": 1, "subtitle": "Minikube", "imgSrc": "kubernetes-minikube", "subTitleDescription": "In a production setup, dealing with multiple nodes demands beefier software and hardware for local machines. Enter Minikube, a lightweight Kubernetes tool tailored for testing and development on a single node. This nifty tool saves your PC from the heavyweight requirements of managing a full-blown Kubernetes cluster. Ideal for tinkering and experimenting without burdening your machine." },
    { "ctorchkuioid": 2, "subtitle": "Kubectl", "imgSrc": "kubernetes-kubectl", "subTitleDescription": "Kubectl is a command tool (like the terminal in VS code) that communicates with the Kube-apiserver in Master-Node. You could also use WebUI or API to communicate with Kube-apiserver, but Kubectl offers more functionality than WebUI while easier to use compared to API." },
    { "ctorchkuioid": 3, "subtitle": "Step 1 - Install MiniKube in VS code", "imgSrc": "kubernetes-install", "subTitleDescription": "Install MiniKube from their offical website, input the code on the command panel depends on your OS, or simply download the application" },
    { "ctorchkuioid": 4, "subtitle": "Step 2 - Check minikube status", "imgSrc": "kubernetes-minikube-command", "subTitleDescription": "After that, you could input [minkube version] to check the version of your minikube to confirm installation, and input [minikube] to show the command available." },
    { "ctorchkuioid": 5, "subtitle": "Step 3 - Start a local Kubernetes cluster", "imgSrc": "kubernetes-minikube-start", "subTitleDescription": "Make sure your docker is running, and input [minikube start] to create a local kubernetes cluster." },
    { "ctorchkuioid": 6, "subtitle": "Step 4 - Check cluster status", "imgSrc": "kubernetes-minikube-status", "subTitleDescription": "Input [minikube status] to check the status of the cluster." },
    { "ctorchkuioid": 7, "subtitle": "Step 5 - kubectl get [nodes]", "imgSrc": "kubernetes-kubectl-nodes", "subTitleDescription": "[kubectl get nodes] can check the number and the deatils of the node we have. You could replace nodes by other kubernetes objects such as pod, svc(service), deployment etc" },
    { "ctorchkuioid": 8, "subtitle": "Step 6 - Create a Pod", "imgSrc": "kubernetes-kubectl-run-pod", "subTitleDescription": "Input [kubectl run nginx --image=nginx] to create a pod base on an image indicate after [--image=]." },
    { "ctorchkuioid": 9, "subtitle": "Step 7 - Create other Kubernetes Object", "imgSrc": "kubernetes-kubectl-create-deployment", "subTitleDescription": "For other Kubernetes object, we use create command, where nginx-deployment (the value after [create (the Kubernetes object)]) is the customized object name." },
    { "ctorchkuioid": 10, "subtitle": "Step 8 - Check ReplicaSet", "imgSrc": "kubernetes-kubectl-get-replicaset", "subTitleDescription": "A pod & ReplicaSet will automatically created when we create a deployment and this pod is within the ReplicaSet as shown. ReplicaSet doesn't require user to create manually. The random number following the pod and ReplicaSet is the ReplicaSet ID, and b2l8v is the Pod id." },
    { "ctorchkuioid": 11, "subtitle": "Step 9 - Edit a deployment", "imgSrc": "kubernetes-kubectl-edit-deployment", "subTitleDescription": "Input [kubectl edit deployment nginx-deployment], a YAML document will be pop up." },
    { "ctorchkuioid": 12, "subtitle": "Step 10 - Edit the YAML", "imgSrc": "kubernetes-kubectl-edit-yaml", "subTitleDescription": "This YAML document is the setting for this deployment, and we can change the replicas from 1 to 3, and save." },
    { "ctorchkuioid": 13, "subtitle": "Step 11 - Show all object in a cluster", "imgSrc": "kubernetes-kubectl-get-all", "subTitleDescription": "Use [ kubctl get all] to show all object in the local cluster." },
    { "ctorchkuioid": 14, "subtitle": "Step 12 - Delete a deployment", "imgSrc": "kubernetes-kubectl-delete-deployment", "subTitleDescription": "Input [kubectl delete deployment nginx-deployment] to delete the deployment, please note that the corresponding pod and ReplicaSet will also be deleted." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesInstallationOperationList.map(containerOrchestrationKubernetesInstallationOperationList => (
          <div key={containerOrchestrationKubernetesInstallationOperationList.ctorchkuioid}>
            <Content
              subtitle={containerOrchestrationKubernetesInstallationOperationList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesInstallationOperationList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesInstallationOperationList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesInstallationOperationList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}