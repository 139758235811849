import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonFrameworkDjango() {

  let pythonFrameworkDjangoList = [
    { "pyfrdjid": 1, "name": "Overview", "urlname": "overview", "Description": "What is Django?"},
    { "pyfrdjid": 2, "name": "Django Structure", "urlname": "structure", "Description": "In a typical Django project, you'll find a structured layout designed to organize different components of your application. Here's a breakdown of the main directories and files in a Django project:"},
    { "pyfrdjid": 3, "name": "Installation & Operation", "urlname": "installation-&-operation", "Description": "Install and operate Django here!"},
    { "pyfrdjid": 4, "name": "URL Dispatcher", "urlname": "url-dispatcher", "Description": "URL dispatcher in Django is a mechanism that helps map URL patterns to the appropriate view functions or classes. It allows Django to determine which view should handle a particular incoming HTTP request based on the requested URL."},
    { "pyfrdjid": 5, "name": "Migrate with SQLite", "urlname": "sqlite", "Description": "SQLite is the default database in a django project, so I will alos use SQLite to demo to you."},
    { "pyfrdjid": 6, "name": "Super User", "urlname": "super-user", "Description": "Check how to create a superuser here!"},
    { "pyfrdjid": 7, "name": "Start an App", "urlname": "start-app", "Description": "Check how to start an App here!"},
    { "pyfrdjid": 8, "name": "Create Model Class", "urlname": "model-class", "Description": "Check how to create a model class here!"},
    { "pyfrdjid": 9, "name": "Django QuerySet", "urlname": "query-set", "Description": "In Django, a QuerySet is a collection of database queries that can be used to retrieve data from the database. It acts as an intermediary between your Django application and the database, allowing you to perform various operations like filtering, sorting, updating, and deleting data from your database tables."},
    { "pyfrdjid": 10, "name": "Template API", "urlname": "template-api", "Description": "Learn how to maintain static & dynamic content seperately!"},
  ]


  return (
    <div>
      <h1>Django</h1><hr></hr>
      <div className="card-wrapper">{
        pythonFrameworkDjangoList.map(pythonFrameworkDjangoList => (
          <div key={pythonFrameworkDjangoList.pyfrdjid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoList.urlname + ".png"}
              imgAlt={pythonFrameworkDjangoList.urlname}
              title={<Link to={'/programming-language/python/framework/django/' + pythonFrameworkDjangoList.urlname}>{pythonFrameworkDjangoList.name}</Link>}
              description={pythonFrameworkDjangoList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
