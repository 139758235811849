import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsCloudComputing() {

  let advancedTopicsCloudComputingList = [
    { "adccid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a Cloud Computing?" },
    { "adccid": 2, "name": "AWS", "urlname": "aws", "Description": "" },
    { "adccid": 3, "name": "Microsoft Azure", "urlname": "azure", "Description": "" },
  ]


  return (
    <div>
      <h1>Cloud Computing</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsCloudComputingList.map(advancedTopicsCloudComputingList => (
          <div key={advancedTopicsCloudComputingList.adccid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsCloudComputingList.urlname + ".png"}
              imgAlt={advancedTopicsCloudComputingList.urlname}
              title={<Link to={'/advanced-topics/cloud-computing/' + advancedTopicsCloudComputingList.urlname}>{advancedTopicsCloudComputingList.name}</Link>}
              description={advancedTopicsCloudComputingList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}