import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonOpen() {

let mainTitle = "Python Open"
let titleDescription = ""

let pythonOpenList = [
  { "pyopid": 1, "subtitle": "Read a File using \"with\" statement", "imgSrc": "python_read_file", "subTitleDescription": "It's recommended to use the \"with\" statement when working with files in Python. The \"with\" statement automatically takes care of closing the file after you're done with it, ensuring proper resource management. The first parameter of the function is the file name and the second parameter is the fucntion you would like to use. R stand for read, W stand for write and A stand for add." },
  { "pyopid": 2, "subtitle": "Read a File with \"readlines\"", "imgSrc": "python_readlines_file", "subTitleDescription": "Using \"readlines\" statement, all content will be shown as a list including the spacing." },
  { "pyopid": 3, "subtitle": "Read a File with \"readline\"", "imgSrc": "python_readline_file", "subTitleDescription": "Using \"readlines\" statement, the content will be showing per line from top to bottom." },
  { "pyopid": 4, "subtitle": "Read a File with \"readline\" with for loop with for loop", "imgSrc": "python_readline_file_with_for_loop", "subTitleDescription": "Or you could use for loop to read the files." },
  { "pyopid": 5, "subtitle": "Parameters with Open", "imgSrc": "python_readline_file_with_parameters", "subTitleDescription": "If you put a parameter inside the \"readline\" statement, the content will be printed per index position as you indicated in the parameter." },
  { "pyopid": 6, "subtitle": "Write a File using \"with\" statement", "imgSrc": "python_write_file", "subTitleDescription": "Remember to us \\n to break the line, if you would like to add content in the next line." },
  { "pyopid": 7, "subtitle": "Write a File with \"readline\" with for loop", "imgSrc": "python_write_file_with_for_loop", "subTitleDescription": "Again, you could use for loop to write the files." },
  { "pyopid": 8, "subtitle": "Add content in an existing File using \"with\" statement", "imgSrc": "python_write_file_add_content", "subTitleDescription": "Use a to add content into a file." },
  { "pyopid": 9, "subtitle": "Copy File using \"with\" statement", "imgSrc": "python_write_file_copy_file", "subTitleDescription": "Above is an example to copy content to another file." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonOpenList.map(pythonOpenList => (
        <div key={pythonOpenList.pyopid}>
          <Content
            subtitle={pythonOpenList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonOpenList.imgSrc + ".png"}
            imgAlt={pythonOpenList.imgSrc}
            subTitleDescription={pythonOpenList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}