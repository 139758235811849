import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsKnowHow () {

  let mainTitle = "JSX Knowhow"
  let titleDescription = "In ReactJS, we have to use Javascript XML (JSX), below will show some simple points when using Javascript XML (JSX).";

  let advancedTopicsReactJsKnowHowList = [
    { "adrjkhid": 1, "subtitle": "I: Only one parent element", "imgSrc": "one-parent", "subTitleDescription": "One JSX can have only one parent element, please see above example: There are two sets of parent element (2 div tags) whcih is invaild. To resolve this, you could add one parent element (div) to include all the elements." },
    { "adrjkhid": 2, "subtitle": "II: class>className", "imgSrc": "classname", "subTitleDescription": "Use \"className\" instead of \"class\" to assign class to element. They are the same but this could aviod any potential error in the future when using other RreactJS tool to develop SPA." },
    { "adrjkhid": 3, "subtitle": "III: Javascript comment", "imgSrc": "jsx-comment", "subTitleDescription": "In JSX, comment can only be input outside the parent element. To resolve, use {/**/}." },
    { "adrjkhid": 4, "subtitle": "IV: Function of {} in JSX", "imgSrc": "another-use", "subTitleDescription": "In JSX, {} can be used to output variable, for example:" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsKnowHowList.map(advancedTopicsReactJsKnowHowList => (
          <div key={advancedTopicsReactJsKnowHowList.adrjkhid}>
            <Content
              subtitle={advancedTopicsReactJsKnowHowList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsKnowHowList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsKnowHowList.imgSrc}
              subTitleDescription={advancedTopicsReactJsKnowHowList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}