import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitVersioning() {

  let mainTitle = "Git Versioning"
  let titleDescription = "With versioining, Git allows developer to record the history (who made what changes at what time) of the file."

  let gitGitHubGitVersioningList = [
    { "gitgvid": 1, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 1", "imgSrc": "ready-to-take-snapshot", "subTitleDescription": "I have 2 files in my project now: index.html & style.css, which are ready for taking snapshot. Remember? Before taking snapshot, I have to put those files into the staging area first." },
    { "gitgvid": 2, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 2", "imgSrc": "to-source-control", "subTitleDescription": "Please ensure you have installed Git. Open \"Source Control\" on the left hand side navigation bar in VS code, or use hot key [shift] + [ctrl] +[G]." },
    { "gitgvid": 3, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 3", "imgSrc": "add-from-UI", "subTitleDescription": "Click the [+] sign to add the files to the staging area." },
    { "gitgvid": 4, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 4", "imgSrc": "add-all-from-UI", "subTitleDescription": "You may ask, what if I have over 100 files? Do I have to add it one by one? The answer of course is NO. By pressing the [+] next to the [Changes] allows you could to add all files within the current folder into the staged changes (staging area). If you accidentally added files into the staging area, you could simply click the [-] sign to unstage the files." },
    { "gitgvid": 5, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 5", "imgSrc": "git-commit-from-UI", "subTitleDescription": "After you have add the files you would like to commit into staging area, input a message for you to record what changes have been made in this version in the yellow box indicated, and click the [tick] sign to commit the files." },
    { "gitgvid": 6, "subtitle": "Versioning Contorl with Visual Studio Codes UI tools - Step 6", "imgSrc": "", "subTitleDescription": "After you committed, the files should be disappeared from the staging area. It is simply because your present file version matches the version you've just committed so that there are no files have to commit." },
    { "gitgvid": 7, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 1", "imgSrc": "ready-to-take-snapshot", "subTitleDescription": "Same, I have 2 files in my project now ready for taking snapshot." },
    { "gitgvid": 2, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 2", "imgSrc": "to-source-control", "subTitleDescription": "Please ensure you have installed Git. Open \"Source Control\" on the left hand side navigation bar in VS code, or use hot key [shift] + [ctrl] +[G]." },
    { "gitgvid": 9, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 3", "imgSrc": "add-index-html", "subTitleDescription": "Use hot key [shift] + [ctrl] + [`], or locate \"New Terminal\" from the top navagation bar. Input: git add [file name] in the terminal console to add index.html into the staged changes (staging area). By inputting: [git add .] in the terminal console, you could add all files within the current folder into the staged changes (staging area)." },
    { "gitgvid": 10, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 4", "imgSrc": "unstage", "subTitleDescription": "If you accidentally added files into the staging area, you could simply click the [-] sign to unstage the files." },
    { "gitgvid": 11, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 5", "imgSrc": "git-commit", "subTitleDescription": "After you have add the files you would like to commit into staging area, input: [git commit -m\"[message]\"], where [message] is a message for you to input and record what changes have been made in this version." },
    { "gitgvid": 12, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 6", "imgSrc": "git-commit-finish", "subTitleDescription": "From the terminal, you could see the commit code is ce8d2f0, as inidcated in yellow, with the message you have input. The terminal also provide you the number of files, and the number of insertion (line of codes) committed." },
    { "gitgvid": 13, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 7", "imgSrc": "git-log", "subTitleDescription": "If you want to check the history of commit, input: [git log], where the terminal will show the author who did the commit with Git username and email, and the date and time of commit." },
    { "gitgvid": 14, "subtitle": "Versioning Contorl with Visual Studio Codes Command Line Interface (CLI) - Step 8", "imgSrc": "git-status", "subTitleDescription": "If you want to check the modification details before commit, input: [git status], here the terminal will show which files have been changed and which files have or have not been added to staging area. The green line shows the files that have been addded to the staging area, and the red line shows those are not." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitVersioningList.map(gitGitHubGitVersioningList => (
          <div key={gitGitHubGitVersioningList.gitgvid}>
            <Content
              subtitle={gitGitHubGitVersioningList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitVersioningList.imgSrc + ".png"}
              imgAlt={gitGitHubGitVersioningList.imgSrc}
              subTitleDescription={gitGitHubGitVersioningList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
