import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonLoops() {

  let pythonLoopsList = [
    { "pylid": 1, "name": "while loop", "urlname": "while-loop", "Description": "" },
    { "pylid": 2, "name": "for loop", "urlname": "for-loop", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Loops</h1><hr></hr>
      <div className="card-wrapper">{
        pythonLoopsList.map(pythonLoopsList => (
          <div key={pythonLoopsList.pylid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonLoopsList.urlname + ".png"}
              imgAlt={pythonLoopsList.urlname}
              title={<Link to={'/programming-language/python/loops/' + pythonLoopsList.urlname}>{pythonLoopsList.name}</Link>}
              description={pythonLoopsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
