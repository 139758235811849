import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function ContainerOrchestrationKubernetesRedHatOpenShift() {

  let containerOrchestrationKubernetesRedHatOpenShiftList = [
    { "ctorchkurhoid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a Red Hat OpenShift?"},
    { "ctorchkurhoid": 2, "name": "Build", "urlname": "build", "Description": "OpenShift Build refers to the process of creating container images from source code and dependencies. OpenShift provides a build system that automates the steps involved in building, testing, and packaging applications into container images that can be deployed on the OpenShift platform."},
    { "ctorchkurhoid": 3, "name": "Operators", "urlname": "operators", "Description": "Install and operate Kubernetes here!"},
    { "ctorchkurhoid": 4, "name": "Istio", "urlname": "istio", "Description": "Install and operate Kubernetes here!"},
  ]


  return (
    <div>
      <h1>Red Hat OpenShift</h1><hr></hr>
      <div className="card-wrapper">{
        containerOrchestrationKubernetesRedHatOpenShiftList.map(containerOrchestrationKubernetesRedHatOpenShiftList => (
          <div key={containerOrchestrationKubernetesRedHatOpenShiftList.ctorchkurhoid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRedHatOpenShiftList.urlname + ".png"}
              imgAlt={containerOrchestrationKubernetesRedHatOpenShiftList.urlname}
              title={<Link to={'/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift/' + containerOrchestrationKubernetesRedHatOpenShiftList.urlname}>{containerOrchestrationKubernetesRedHatOpenShiftList.name}</Link>}
              description={containerOrchestrationKubernetesRedHatOpenShiftList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
