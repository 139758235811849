import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitRevertCheckoutCommit() {

  let mainTitle = "Checkout Commit"
  let titleDescription = "Checkout commit is employed for investigation. It is well-suited for reviewing multiple versions without the need to edit or create additional commit files."

  let gitGitHubGitRevertCheckoutCommitList = [
    { "gitgccid": 1, "subtitle": "Step 1", "imgSrc": "first-commit", "subTitleDescription": "First of all, I have to commit a file to have a new version. " },
    { "gitgccid": 2, "subtitle": "Step 2", "imgSrc": "second-commit", "subTitleDescription": "After that, let me further edit the index.html, modify the title to [Document 2], for example, and commit again to create one more version." },
    { "gitgccid": 3, "subtitle": "Step 3", "imgSrc": "git-log-check-commit", "subTitleDescription": "As mentioned in Git Versioning, input:[git log] to show the version that I committed before, here you could see the 2 commits which I did in step 1 & 2." },
    { "gitgccid": 4, "subtitle": "Step 4", "imgSrc": "checkout-version", "subTitleDescription": "Now, I would like to check the first version, which is the [Add document title]." },
    { "gitgccid": 5, "subtitle": "Step 5", "imgSrc": "checkout-with-id", "subTitleDescription": "Copy the commit ID and input: [git checkout [ID]]." },
    { "gitgccid": 6, "subtitle": "Step 6", "imgSrc": "checkouted-with-id", "subTitleDescription": "The title [Document 2] will change back to [Document] after you enter the command. From the terminal console, the system have instructed that you can input: [git switch -] to revert back to that version you were at (with title [Document 2])." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitRevertCheckoutCommitList.map(gitGitHubGitRevertCheckoutCommitList => (
          <div key={gitGitHubGitRevertCheckoutCommitList.gitgccid}>
            <Content
              subtitle={gitGitHubGitRevertCheckoutCommitList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitRevertCheckoutCommitList.imgSrc + ".png"}
              imgAlt={gitGitHubGitRevertCheckoutCommitList.imgSrc}
              subTitleDescription={gitGitHubGitRevertCheckoutCommitList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
