import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function JavaScriptBuildInFunctions () {

  let mainTitle = "JavaScript Build-in Functions"
  let titleDescription = ""

  let javaScriptBuildInFunctionsList = [
    { "jsbiid": 1, "subtitle": "Variable & Console.log function", "imgSrc": "js-variable", "subTitleDescription": "Variable: an element that can be assigned to a value multiple times. You can always change the value (age or height) later in your project, and the \"let\" command only have to insert once at the first time you assign a variable. Currently the value of age & height is 20 & 129, JavaScript will always look at the latest assigned value, you could print the variable by usning console.log function, but the result will be 10 & 183 as this function is executed before the variable have been assigned to another value." },
    { "jsbiid": 2, "subtitle": "Const & Print function", "imgSrc": "js-constant", "subTitleDescription": "Constant: an element that can only be assigned to a value at the time you designed. You cannot change the assigned value (age or height) in your project. Remember, no matter a variable or a constant, the naming must start with an alphabet, a \"$\", or a \"_\"." },
    { "jsbiid": 3, "subtitle": "Comment function", "imgSrc": "JavaScript_comment", "subTitleDescription": "Input // for signle line comment, or /**/ for multiple lines comment." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        javaScriptBuildInFunctionsList.map(javaScriptBuildInFunctionsList => (
          <div key={javaScriptBuildInFunctionsList.jsbiid}>
            <Content
              subtitle={javaScriptBuildInFunctionsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javaScriptBuildInFunctionsList.imgSrc + ".png"}
              imgAlt={javaScriptBuildInFunctionsList.imgSrc}
              subTitleDescription={javaScriptBuildInFunctionsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}