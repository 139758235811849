import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsCI() {

  let mainTitle = "Continuous Integration"
  let titleDescription = ""

  let advancedTopicsDevOpsCIList = [
    { "addociid": 1, "subtitle": "Continuous Integration", "imgSrc": "continuous-integration", "subTitleDescription": "Continuous Integration (CI) is the process of continuously building, and testing, and integrating every developer change into the master branch after a set of tests have passed." },
    { "addociid": 2, "subtitle": "Short-lived Feature Branches", "imgSrc": "short-live-branches", "subTitleDescription": "CI suggest to have short-lived feature branches and commit daily, then the branches are merged to the master daily or as short as possible after tested to avoid merge conflict. The shorter the gap between merging & pull request, the easier to discover conflict and resolve any defects. Each pull request is an opportunity for others to review to code and make sure trst cases are >95% covered before approval. The master branch is always deployable, never merge untested branch to the master." },
    { "addociid": 3, "subtitle": "CI Tools - GitHub Actions", "imgSrc": "gihub-actions", "subTitleDescription": "Github Actions is one of the most common build in CI/CD tols in GitHub, and it follows above workflow." },
    { "addociid": 4, "subtitle": "GitHub Actions Setup", "imgSrc": "github-actions-yaml", "subTitleDescription": "First you will have to create a github>workflow folders, and create a yaml file. As shown as above script, the event was indicated in the on section; while the job is indicated in the job section,  where you could execute some commands same as you do in the terminal." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsCIList.map(advancedTopicsDevOpsCIList => (
          <div key={advancedTopicsDevOpsCIList.addociid}>
            <Content
              subtitle={advancedTopicsDevOpsCIList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsCIList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsCIList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsCIList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
