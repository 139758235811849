import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonDictionary() {

  let mainTitle = "Dictionary"
  let titleDescription = ""

  let pythonDictionaryList = [
    { "pydiid": 1, "subtitle": "Create a Dictionary", "imgSrc": "python_dictionary", "subTitleDescription": "The variable inside a list is call \"key\", and the variable assigned to the key we call it a \"value pair\"." },
    { "pydiid": 2, "subtitle": "Print a key from Dictionary", "imgSrc": "python_print_dictionary", "subTitleDescription": "You could indicate the key to print." },
    { "pydiid": 3, "subtitle": "Modify a Dictionary", "imgSrc": "python_modify_dictionary", "subTitleDescription": "You could addd a value pair or update a value in a key from above code." },
    { "pydiid": 4, "subtitle": "Remove an element in a Dictionary", "imgSrc": "python_remove_dictionary", "subTitleDescription": "Use pop function to remove a value pair." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonDictionaryList.map(pythonDictionaryList => (
          <div key={pythonDictionaryList.pydiid}>
            <Content
              subtitle={pythonDictionaryList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonDictionaryList.imgSrc + ".png"}
              imgAlt={pythonDictionaryList.imgSrc}
              subTitleDescription={pythonDictionaryList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}