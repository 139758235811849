import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoStructure () {

  let mainTitle = "Django Structure"
  let titleDescription = ""

  let pythonFrameworkDjangoStructureList = [
    { "pyfrdjstid": 1, "subtitle": "MTV pattern", "imgSrc": "django-mtv", "subTitleDescription": "The MTV pattern, standing for Model, Templates, and Views, defines how requests from clients are handled. Initially processed by the URL dispatcher, they are then routed to view functions for further processing. Models is used for replacing SQL by providing an Object-Relational Mapping (ORM) layer for database interactions." },
    { "pyfrdjstid": 2, "subtitle": "UA + MTV pattern", "imgSrc": "django-ua-mtv", "subTitleDescription": "Recall the Django overview diagram, this is how it looks with the MTV pattern. The admin.py is use to handle the operation in the admin site (backend)." },
    { "pyfrdjstid": 3, "subtitle": "Project Structure", "imgSrc": "django-project-structure", "subTitleDescription": "The django project file structure will look like this after you start a Django project in VS code. The manage.py file is critical as it handle the django command of the project; the setting.py is for setting purposes. By using manage.py, you could create a subsystem - app1." },
    { "pyfrdjstid": 4, "subtitle": "Subsystem Structure", "imgSrc": "django-subsystem-structure", "subTitleDescription": "From each subsystem, there will be a build-in migration in Django, along with a model.py, views.py, and admin.py." },
    { "pyfrdjstid": 5, "subtitle": "Database Migration", "imgSrc": "django-database-migration", "subTitleDescription": "The difference between makemigrations & migrate is that makemigrations is to detect changes in the model; while migrate is to run the detected changes. If you are familiar with Git, this is simliair to the relationship between staging area and commit." },
    { "pyfrdjstid": 6, "subtitle": "Admin", "imgSrc": "django-create-admin", "subTitleDescription": "An admin can be created using mange.py." },
    { "pyfrdjstid": 7, "subtitle": "Object Relational Mapping (ORM)", "imgSrc": "django-orm", "subTitleDescription": "In Django, the Object-Relational Mapping (ORM) feature within a model enables you to interact with a database without needing to write SQL queries explicitly. Each model corresponds to a table in the database, with objects representing individual rows within those tables." },
    { "pyfrdjstid": 8, "subtitle": "Create a Model", "imgSrc": "django-create-model", "subTitleDescription": "You could use above code to create a model. Person is the name of the table and the list_display is the column name (attribute) of the table." },
    { "pyfrdjstid": 9, "subtitle": "Create a Model Class", "imgSrc": "django-create-model-class", "subTitleDescription": "You could use above code to create a model class (attribute)." },
    { "pyfrdjstid": 10, "subtitle": "Create a Object in a Model Class", "imgSrc": "django-create-object", "subTitleDescription": "You could use above code to create a object in a model class (attribute)." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoStructureList.map(pythonFrameworkDjangoStructureList => (
          <div key={pythonFrameworkDjangoStructureList.pyfrdjstid}>
            <Content
              subtitle={pythonFrameworkDjangoStructureList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoStructureList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoStructureList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoStructureList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
