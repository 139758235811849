import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesOverview() {

  let mainTitle = "Kubernetes"
  let titleDescription = "Kubernetes, often abbreviated as K8s, is an open-source container orchestration platform that automates the deployment, scaling, and management of containerized applications. Originally developed by Google, Kubernetes has gained widespread adoption and is now maintained by the Cloud Native Computing Foundation (CNCF). It provides a powerful and flexible platform for managing containerized workloads and services."

  let containerOrchestrationKubernetesOverviewList = [
    { "ctorochkuovid": 1, "subtitle": "What is Kubernetes?", "imgSrc": "kubernetes", "subTitleDescription": "The official Kubernetes documentation describes Kubernetes as “an open-source system for automating deployment, scaling, and management of containerized applications.” Kubernetes is widely available and has positioned itself as the de facto choice for container orchestration." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesOverviewList.map(containerOrchestrationKubernetesOverviewList => (
          <div key={containerOrchestrationKubernetesOverviewList.ctorochkuovid}>
            <Content
              subtitle={containerOrchestrationKubernetesOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesOverviewList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesOverviewList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
