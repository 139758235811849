import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonExceptionHandling() {

let mainTitle = "Python Exception Handling"
let titleDescription = "Exception handling is a programming concept that deals with the identification, handling, and resolution of errors that can occur during the execution of a program. In Python, exceptions are raised when an error or unexpected behavior occurs in the program."

let pythonExceptionHandlingList = [
  { "pyexid": 1, "subtitle": "Exception Handling of one Exception", "imgSrc": "python_one_exception", "subTitleDescription": "With Exception Handling, user can check error when executing the code, in this case, variable x is not defined." },
  { "pyexid": 2, "subtitle": "Exception Handling of multiple Exception", "imgSrc": "python_multiple_exception", "subTitleDescription": "Same as \"if else\" function, you can add multiple condition by adding multiple excepts." },
  { "pyexid": 3, "subtitle": "else", "imgSrc": "python_else_exception", "subTitleDescription": "code defined from \"else\" will be printed if no error was detected;" },
  { "pyexid": 4, "subtitle": "finally", "imgSrc": "python_finally_exception", "subTitleDescription": "while the code within the \"finally\" block will be executed regardless of whether an error is detected or not."},
  { "pyexid": 5, "subtitle": "Exception Handling with Open", "imgSrc": "python_exception_with_open", "subTitleDescription": "You could combine other function, like \"open\", to facilitate your work. This is a simple function to open and input content into a file. The \"try...except\" in line 1 & 9 is to check if the file exist, and the \"try...except...finally\" in line 3, 5 & 7 is to check if content can be written into the file, and close the file no matter if there is any error or not." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonExceptionHandlingList.map(pythonExceptionHandlingList => (
        <div key={pythonExceptionHandlingList.pyexid}>
          <Content
            subtitle={pythonExceptionHandlingList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonExceptionHandlingList.imgSrc + ".png"}
            imgAlt={pythonExceptionHandlingList.imgSrc}
            subTitleDescription={pythonExceptionHandlingList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}