import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function JavaScriptDataType() {

  let javaScriptDataTypeList = [
    { "jsdid": 1, "name": "String", "urlname": "string", "Description": "" },
    { "jsdid": 2, "name": "Numbers", "urlname": "numbers", "Description": "" },
    { "jsdid": 3, "name": "Data Structure", "urlname": "data-structure", "Description": "" },
  ]

  return (
    <div>
      <h1>JavaScript Data Type</h1><hr></hr>
      <div className="card-wrapper">{
        javaScriptDataTypeList.map(javaScriptDataTypeList => (
          <div key={javaScriptDataTypeList.jsdid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javaScriptDataTypeList.urlname + ".png"}
              imgAlt={javaScriptDataTypeList.urlname}
              title={<Link to={'/programming-language/JavaScript/data-type/' + javaScriptDataTypeList.urlname}>{javaScriptDataTypeList.name}</Link>}
              description={javaScriptDataTypeList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
