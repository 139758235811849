import React from 'react';
import { Card } from "./components/Card";
import {ContentTitle} from "./components/ContentTitle"
import { Link } from 'react-router-dom';

export default function JavaScript() {

  let mainTitle = "JavaScript"
  let titleDescription = "JavaScript, often abbreviated as JS, is a programming language and core technology of the World Wide Web, alongside HTML and CSS. Similar to CSS, you just have to add the: script src=\"[js file name]\" above the closing body tag."

  let javaScriptList = [
    { "pyid": 1, "name": "Functions", "urlname": "functions", "Description": "In JavaScript, a function is a block of organized, reusable code that performs a specific task. It is a way to group related statements together to perform a specific operation. Functions help in modularizing code, making it more readable, reusable, and easier to maintain." },
    { "pyid": 2, "name": "Modules", "urlname": "modules", "Description": "In JavaScript, a module is a file containing JavaScript definitions and statements. The file name is the module name with the suffix .py appended. Modules allow you to organize JavaScript code into separate files, making your code more modular and manageable." },
    { "pyid": 3, "name": "Data Type", "urlname": "data-type", "Description": "In programming, a data type is an attribute of data that tells the compiler or interpreter how the programmer intends to use the data. Each programming language has its own set of data types, and JavaScript is no exception. " },
    { "pyid": 4, "name": "Condition", "urlname": "condition", "Description": "In JavaScript, conditions are used to control the flow of a program based on whether a certain expression evaluates to True or False. Conditional statements allow you to make decisions in your code, executing different blocks of code depending on the truth or falsity of a given condition." },
    { "pyid": 5, "name": "Loops", "urlname": "loops", "Description": "Loops in JavaScript are used to repeatedly execute a block of code until a certain condition is met. There are two main types of loops in JavaScript: for loops and while loops." },
    { "pyid": 6, "name": "ReactJS", "urlname": "reactjs", "Description": "ReactJS is a JavaScript framework. A framework is a pre-built and reusable set of tools, libraries, conventions, and best practices that provide a structured way to develop and organize code for a particular type of software application. Frameworks aim to simplify and expedite the development process by offering a foundation on which developers can build their applications." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div className="card-wrapper">{
        javaScriptList.map(javaScriptList => (
          <div key={javaScriptList.pyid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javaScriptList.urlname + ".png"}
              imgAlt={javaScriptList.urlname}
              title={<Link to={'/programming-language/javascript/' + javaScriptList.urlname}>{javaScriptList.name}</Link>}
              description={javaScriptList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
