import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonFunctions() {

  let pythonFunctionList = [
    { "pyfid": 1, "name": "Build-In", "urlname": "build-in", "Description": "" },
    { "pyfid": 2, "name": "Customize", "urlname": "customize", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Functions</h1><hr></hr>
      <div className="card-wrapper">{
        pythonFunctionList.map(pythonFunctionList => (
          <div key={pythonFunctionList.pyid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFunctionList.urlname + ".png"}
              imgAlt={pythonFunctionList.urlname}
              title={<Link to={'/programming-language/python/functions/' + pythonFunctionList.urlname}>{pythonFunctionList.name}</Link>}
              description={pythonFunctionList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
