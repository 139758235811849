import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import SettingUp from './SettingUp';
import IntegratedDevelopmentEnvironmentsVisualStudioCode from './IntegratedDevelopmentEnvironmentsVisualStudioCode';
import GitGitHub from './GitGitHub';
import ProgrammingLanguage from './ProgrammingLanguage';
import Html from './Html';
import Css from './Css';
import Javascript from './Javascript';
import Python from './Python';
import PythonFunctions from './PythonFunctions';
import AdvancedTopics from './AdvancedTopics';
import Error from './Error';
import PythonBuildInFunctions from './PythonBuildInFunctions';
import PythonCustomzieFunctions from './PythonCustomizeFunctions';
import PythonModules from './PythonModules';
import PythonDataType from './PythonDataType';
import PythonString from './PythonString';
import PythonNumbers from './PythonNumbers';
import PyhtonDataStructure from './PythonDataStructure';
import PythonList from './PythonList';
import PythonDictionary from './PythonDictionary';
import PythonTuple from './PythonTuple';
import PythonSet from './PythonSet';
import PythonCondition from './PythonCondition';
import PythonOperators from './PythonOperators';
import PythonIfElse from './PythonIfElse';
import PythonLoops from './PythonLoops';
import PythonWhileLoop from './PythonWhileLoop';
import PythonForLoop from './PythonForLoop';
import PythonExceptionHandling from './PythonExceptionHandling';
import PythonOpen from './PythonOpen';
import PythonDataAnalytics from './PythonDataAnalytics';
import PythonPandas from './PythonPandas';
import PythonNumPy from './PythonNumPy';
import Container from './Container';
import ContainerOverview from './ContainerOverview';
import ContainerDocker from './ContainerDocker';
import ContainerDockerOverview from './ContainerDockerOverview';
import ContainerDockerObject from './ContainerDockerObject';
import ContainerDockerInstallationOperation from './ContainerDockerInstallationOperation';
import ContainerDockerArchitecture from './ContainerDockerArchitecture';
import ContainerOrchestration from './ContainerOrchestration';
import ContainerOrchestrationOverview from './ContainerOrchestrationOverview';
import ContainerOrchestrationKubernetes from './ContainerOrchestrationKubernetes';
import ContainerOrchestrationKubernetesOveriview from './ContainerOrchestrationKubernetesOverview';
import ContainerOrchestrationKubernetesObject from './ContainerOrchestrationKubernetesObject';
import ContainerOrchestrationKubernetesArchitecture from './ContainerOrchestrationKubernetesArchitecture';
import ContainerOrchestrationKubernetesInstallationOperation from './ContainerOrchestrationKubernetesInstallationOperation';
import ContainerOrchestrationKubernetesAutoscaling from './ContainerOrchestrationKubernetesAutoscaling';
import ContainerOrchestrationKubernetesRollingUpdate from './ContainerOrchestrationKubernetesRollingUpdate';
import ContainerOrchestrationKubernetesRedHatOpenShift from './ContainerOrchestrationKubernetesRedHatOpenShift';
import ContainerOrchestrationKubernetesRedHatOpenShiftOverview from './ContainerOrchestrationKubernetesRedHatOpenShiftOverview';
import ContainerOrchestrationKubernetesRedHatOpenShiftBuild from './ContainerOrchestrationKubernetesRedHatOpenShiftBuild';
import ContainerOrchestrationKubernetesRedHatOpenShiftOperators from './ContainerOrchestrationKubernetesRedHatOpenShiftOperators';
import ContainerOrchestrationKubernetesRedHatOpenShiftIstio from './ContainerOrchestrationKubernetesRedHatOpenShiftIstio';
import PythonFramework from './PythonFramework';
import PythonFrameworkDjango from './PythonFrameworkDjango';
import PythonFrameworkDjangoOverview from './PythonFrameworkDjangoOverview';
import PythonFrameworkDjangoStructure from './PythonFrameworkDjangoStructure';
import PythonFrameworkDjangoQuerySet from './PythonFrameworkDjangoQuerySet';
import PythonFrameworkDjangoInstallationOperation from './PythonFrameworkDjangoInstallationOperation';
import IntegratedDevelopmentEnvironments from './IntegratedDevelopmentEnvironments';
import IntegratedDevelopmentEnvironmentsVisualStudioCodeOverview from './IntegratedDevelopmentEnvironmentsVisualStudioCodeOverview';
import IntegratedDevelopmentEnvironmentsVisualStudioCodeHotKey from './IntegratedDevelopmentEnvironmentsVisualStudioCodeHotKey';
import IntegratedDevelopmentEnvironmentsVisualStudioCodeUsefulPackages from './IntegratedDevelopmentEnvironmentsVisualStudioCodeUsefulPackages';
import PythonFrameworkDjangoUrlDispatcher from './PythonFrameworkDjangoUrlDispatcher';
import PythonFrameworkDjangoSqlite from './PythonFrameworkDjangoSqlite';
import PythonFrameworkDjangoSuperUser from './PythonFrameworkDjangoSuperUser';
import PythonFrameworkDjangoStartApp from './PythonFrameworkDjangoStartApp';
import GitGitHubGit from './GitGitHubGit';
import GitGitHubGitOverview from './GitGitHubGitOverview';
import GitGitHubGitVersioning from './GitGitHubGitVersioning';
import GitGitHubGitIgnore from './GitGitHubGitIgnore';
import GitGitHubGitCommand from './GitGitHubGitCommand';
import GitGitHubGitHub from './GitGitHubGitHub';
import GitGitHubGitHubOverview from './GitGitHubGitHubOverview';
import PythonFrameworkDjangoModelClass from './PythonFrameworkDjangoModelClass';
import GitGitHubGitHubSettingUp from './GitGitHubGitHubSettingUp';
import GitGitHubGitRevertCheckoutCommit from './GitGitHubGitRevertCheckoutCommit';
import GitGitHubGitRevertRevertCommit from './GitGitHubGitRevertRevertCommit';
import GitGitHubGitRevert from './GitGitHubGitRevert';
import GitGitHubGitRevertResetCommit from './GitGitHubGitRevertResetCommit';
import GitGitHubGitMasterBranches from './GitGitHubGitMasterBranches';
import PythonFrameworkDjangoTemplateApi from './PythonFrameworkDjangoTemplateApi';
import PythonFrameworkDjangoTemplateApiVariable from './PythonFrameworkDjangoTemplateApiVariable';
import PythonFrameworkDjangoTemplateApiTag from './PythonFrameworkDjangoTemplateApiTag';
import PythonFrameworkDjangoTemplateApiTemplate from './PythonFrameworkDjangoTemplateApiTemplate';
import AdvancedTopicsNodeJs from './AdvancedTopicsNodeJs';
import AdvancedTopicsNodeJsOverview from './AdvancedTopicsNodeJsOverview';
import AdvancedTopicsNodeJsFileSystem from './AdvancedTopicsNodeJsFileSystem';
import AdvancedTopicsNodeJsServer from './AdvancedTopicsNodeJsServer';
import AdvancedTopicsNodeJsNpm from './AdvancedTopicsNodeJsNpm';
import AdvancedTopicsNodeJsMiddleware from './AdvancedTopicsNodeJsMiddleware';
import AdvancedTopicsNodeJsExpress from './AdvancedTopicsNodeJsExpress';
import AdvancedTopicsReactJs from './AdvancedTopicsReactJs';
import AdvancedTopicsReactJsOverview from './AdvancedTopicsReactJsOverview';
import AdvancedTopicsReactJsSpaMpa from './AdvancedTopicsReactJsSpaMpa';
import AdvancedTopicsReactJsInstallationOperation from './AdvancedTopicsReactJsInstallationOperation';
import AdvancedTopicsReactJsKnowHow from './AdvancedTopicsReactJsKnowHow';
import AdvancedTopicsReactJsComponent from './AdvancedTopicsReactJsComponent';
import AdvancedTopicsReactJsCss from './AdvancedTopicsReactJsCss';
import AdvancedTopicsReactJsState from './AdvancedTopicsReactJsState';
import AdvancedTopicsReactJsRouter from './AdvancedTopicsReactJsRouter';
import AdvancedTopicsReactJsProps from './AdvancedTopicsReactJsProps';
import AdvancedTopicsReactJsUseEffect from './AdvancedTopicsReactJsUseEffect';
import JavaScriptFunctions from './JavaScriptFunctions';
import JavaScriptBuildInFunctions from './JavaScriptBuildInFunctions';
import JavaScriptCustomizeFunctions from './JavaScriptCustomizeFunctions';
import JavaScriptThisFunctions from './JavaScriptThisFunctions';
import JavaScriptDataType from './JavaScriptDataType';
import JavaScriptString from './JavaScriptString';
import AdvancedTopicsDevOps from './AdvancedTopicsDevOps';
import AdvancedTopicsDevOpsOverview from './AdvancedTopicsDevOpsOverview';
import AdvancedTopicsDevOpsSocialCoding from './AdvancedTopicsDevOpsSocialCoding';
import AdvancedTopicsDevOpsMvp from './AdvancedTopicsDevOpsMvp';
import AdvancedTopicsDevOpsDevelopment from './AdvancedTopicsDevOpsDevelopment';
import AdvancedTopicsDevOpsAutoTestingWithNose from './AdvancedTopicsDevOpsAutoTestingWithNose';
import AdvancedTopicsDevOpsCICD from './AdvancedTopicsDevOpsCICD';
import AdvancedTopicsDevOpsCI from './AdvancedTopicsDevOpsCI';
import AdvancedTopicsDevOpsCD from './AdvancedTopicsDevOpsCD';
import AdvancedTopicsCloudComputing from './AdvancedTopicsCloudComputing';
import AdvancedTopicsCloudComputingOverview from './AdvancedTopicsCloudComputingOverview';
import AdvancedTopicsCloudComputingAWS from './AdvancedTopicsCloudComputingAWS';
import AdvancedTopicsCloudComputingAWSNetworking from './AdvancedTopicsCloudComputingAWSNetworking';
import AdvancedTopicsCloudComputingAWSNetworkingNetworkingServices from './AdvancedTopicsCloudComputingAWSNetworkingNetworkingServices';
import AdvancedTopicsNetworkingOverview from './AdvancedTopicsNetworkingOverview';
import AdvancedTopicsNetworking from './AdvancedTopicsNetworking';
import AdvancedTopicsNetworkingFiveLayerModel from './AdvancedTopicsNetworkingFiveLayerModel';

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* to create hyperlink button*/}
        <Link to="/">Home</Link>

        {/* to create custom route */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/setting-up" element={<SettingUp />} />
          <Route path="/integrated-development-environments" element={<IntegratedDevelopmentEnvironments />} />
          <Route path="/integrated-development-environments/vscode" element={<IntegratedDevelopmentEnvironmentsVisualStudioCode />} />
          <Route path="/integrated-development-environments/vscode/overview" element={<IntegratedDevelopmentEnvironmentsVisualStudioCodeOverview />} />
          <Route path="/integrated-development-environments/vscode/hot-key" element={<IntegratedDevelopmentEnvironmentsVisualStudioCodeHotKey />} />
          <Route path="/integrated-development-environments/vscode/useful-packages" element={<IntegratedDevelopmentEnvironmentsVisualStudioCodeUsefulPackages />} />
          <Route path="/git&github" element={<GitGitHub />} />
          <Route path="/git&github/git" element={<GitGitHubGit />} />
          <Route path="/git&github/git/overview" element={<GitGitHubGitOverview />} />
          <Route path="/git&github/git/versioning" element={<GitGitHubGitVersioning />} />
          <Route path="/git&github/git/ignore" element={<GitGitHubGitIgnore />} />
          <Route path="/git&github/git/command" element={<GitGitHubGitCommand />} />
          <Route path="/git&github/github" element={<GitGitHubGitHub />} />
          <Route path="/git&github/github/overview" element={<GitGitHubGitHubOverview />} />
          <Route path="/git&github/github/setting-up" element={<GitGitHubGitHubSettingUp />} />
          <Route path="/git&github/git/revert/" element={<GitGitHubGitRevert />} />
          <Route path="/git&github/git/revert/checkout-commit" element={<GitGitHubGitRevertCheckoutCommit />} />
          <Route path="/git&github/git/revert/revert-commit" element={<GitGitHubGitRevertRevertCommit />} />
          <Route path="/git&github/git/revert/reset-commit" element={<GitGitHubGitRevertResetCommit />} />
          <Route path="/git&github/git/master&branches" element={<GitGitHubGitMasterBranches />} />
          <Route path="/programming-language" element={<ProgrammingLanguage />} />
          <Route path="/programming-language/html" element={<Html />} />
          <Route path="/programming-language/css" element={<Css />} />
          <Route path="/programming-language/javascript" element={<Javascript />} />
          <Route path="/programming-language/javascript/functions" element={<JavaScriptFunctions />} />
          <Route path="/programming-language/javascript/functions/build-in" element={<JavaScriptBuildInFunctions />} />
          <Route path="/programming-language/javascript/functions/customize" element={<JavaScriptCustomizeFunctions />} />
          <Route path="/programming-language/javascript/functions/this" element={<JavaScriptThisFunctions />} />
          <Route path="/programming-language/javascript/data-type" element={<JavaScriptDataType />} />
          <Route path="/programming-language/javascript/data-type/string" element={<JavaScriptString />} />
          <Route path="/programming-language/python" element={<Python />} />
          <Route path="/programming-language/python/functions" element={<PythonFunctions />} />
          <Route path="/programming-language/python/functions/build-in" element={<PythonBuildInFunctions />} />
          <Route path="/programming-language/python/functions/customize" element={<PythonCustomzieFunctions />} />
          <Route path="/programming-language/python/modules" element={<PythonModules />} />
          <Route path="/programming-language/python/data-type" element={<PythonDataType />} />
          <Route path="/programming-language/python/data-type/string" element={<PythonString />} />
          <Route path="/programming-language/python/data-type/numbers" element={<PythonNumbers />} />
          <Route path="/programming-language/python/data-type/data-structure" element={<PyhtonDataStructure />} />
          <Route path="/programming-language/python/data-type/data-structure/list" element={<PythonList />} />
          <Route path="/programming-language/python/data-type/data-structure/dictionary" element={<PythonDictionary />} />
          <Route path="/programming-language/python/data-type/data-structure/tuple" element={<PythonTuple />} />
          <Route path="/programming-language/python/data-type/data-structure/set" element={<PythonSet />} />
          <Route path="/programming-language/python/condition" element={<PythonCondition />} />
          <Route path="/programming-language/python/condition/operators" element={<PythonOperators />} />
          <Route path="/programming-language/python/condition/if-else" element={<PythonIfElse />} />
          <Route path="/programming-language/python/loops" element={<PythonLoops />} />
          <Route path="/programming-language/python/loops/while-loop" element={<PythonWhileLoop />} />
          <Route path="/programming-language/python/loops/for-loop" element={<PythonForLoop />} />
          <Route path="/programming-language/python/exception-handling" element={<PythonExceptionHandling />} />
          <Route path="/programming-language/python/open" element={<PythonOpen />} />
          <Route path="/programming-language/python/data-analytics" element={<PythonDataAnalytics />} />
          <Route path="/programming-language/python/data-analytics/pandas" element={<PythonPandas />} />
          <Route path="/programming-language/python/data-analytics/numpy" element={<PythonNumPy />} />
          <Route path="/programming-language/python/framework" element={<PythonFramework />} />
          <Route path="/programming-language/python/framework/django" element={<PythonFrameworkDjango />} />
          <Route path="/programming-language/python/framework/django/overview" element={<PythonFrameworkDjangoOverview />} />
          <Route path="/programming-language/python/framework/django/structure" element={<PythonFrameworkDjangoStructure />} />
          <Route path="/programming-language/python/framework/django/query-set" element={<PythonFrameworkDjangoQuerySet />} />
          <Route path="/programming-language/python/framework/django/installation-&-operation" element={<PythonFrameworkDjangoInstallationOperation />} />
          <Route path="/programming-language/python/framework/django/url-dispatcher" element={<PythonFrameworkDjangoUrlDispatcher />} />
          <Route path="/programming-language/python/framework/django/sqlite" element={<PythonFrameworkDjangoSqlite />} />
          <Route path="/programming-language/python/framework/django/super-user" element={<PythonFrameworkDjangoSuperUser />} />
          <Route path="/programming-language/python/framework/django/start-app" element={<PythonFrameworkDjangoStartApp />} />
          <Route path="/programming-language/python/framework/django/model-class" element={<PythonFrameworkDjangoModelClass />} />
          <Route path="/programming-language/python/framework/django/template-api" element={<PythonFrameworkDjangoTemplateApi />} />
          <Route path="/programming-language/python/framework/django/template-api/variable" element={<PythonFrameworkDjangoTemplateApiVariable />} />
          <Route path="/programming-language/python/framework/django/template-api/tag" element={<PythonFrameworkDjangoTemplateApiTag />} />
          <Route path="/programming-language/python/framework/django/template-api/template-static-files" element={<PythonFrameworkDjangoTemplateApiTemplate />} />
          <Route path="/advanced-topics" element={<AdvancedTopics />} />
          <Route path="/advanced-topics/NodeJS" element={<AdvancedTopicsNodeJs />} />
          <Route path="/advanced-topics/NodeJS/overview" element={<AdvancedTopicsNodeJsOverview />} />
          <Route path="/advanced-topics/NodeJS/file-system" element={<AdvancedTopicsNodeJsFileSystem />} />
          <Route path="/advanced-topics/NodeJS/server" element={<AdvancedTopicsNodeJsServer />} />
          <Route path="/advanced-topics/NodeJS/npm" element={<AdvancedTopicsNodeJsNpm />} />
          <Route path="/advanced-topics/NodeJS/middleware" element={<AdvancedTopicsNodeJsMiddleware />} />
          <Route path="/advanced-topics/NodeJS/express" element={<AdvancedTopicsNodeJsExpress />} />
          <Route path="/advanced-topics/ReactJS" element={<AdvancedTopicsReactJs />} />
          <Route path="/advanced-topics/ReactJS/overview" element={<AdvancedTopicsReactJsOverview />} />
          <Route path="/advanced-topics/ReactJS/spa-&-mpa" element={<AdvancedTopicsReactJsSpaMpa />} />
          <Route path="/advanced-topics/ReactJS/installation-&-operation" element={<AdvancedTopicsReactJsInstallationOperation />} />
          <Route path="/advanced-topics/ReactJS/component" element={<AdvancedTopicsReactJsComponent />} />
          <Route path="/advanced-topics/ReactJS/know-how" element={<AdvancedTopicsReactJsKnowHow />} />
          <Route path="/advanced-topics/ReactJS/css" element={<AdvancedTopicsReactJsCss />} />
          <Route path="/advanced-topics/ReactJS/state" element={<AdvancedTopicsReactJsState />} />
          <Route path="/advanced-topics/ReactJS/router" element={<AdvancedTopicsReactJsRouter/>} />
          <Route path="/advanced-topics/ReactJS/props" element={<AdvancedTopicsReactJsProps/>} />
          <Route path="/advanced-topics/ReactJS/useEffect" element={<AdvancedTopicsReactJsUseEffect/>} />
          <Route path="/advanced-topics/container" element={<Container />} />
          <Route path="/advanced-topics/container/overview" element={<ContainerOverview />} />
          <Route path="/advanced-topics/container/docker" element={<ContainerDocker />} />
          <Route path="/advanced-topics/container/docker/overview" element={<ContainerDockerOverview />} />
          <Route path="/advanced-topics/container/docker/docker-object" element={<ContainerDockerObject />} />
          <Route path="/advanced-topics/container/docker/installation-&-operation" element={<ContainerDockerInstallationOperation />} />
          <Route path="/advanced-topics/container/docker/docker-architecture" element={<ContainerDockerArchitecture />} />
          <Route path="/advanced-topics/container/container-orchestration" element={<ContainerOrchestration />} />
          <Route path="/advanced-topics/container/container-orchestration/overview" element={<ContainerOrchestrationOverview />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes" element={<ContainerOrchestrationKubernetes />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/overview" element={<ContainerOrchestrationKubernetesOveriview />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/kubernetes-object" element={<ContainerOrchestrationKubernetesObject />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/kubernetes-architecture" element={<ContainerOrchestrationKubernetesArchitecture />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/installation-&-operation" element={<ContainerOrchestrationKubernetesInstallationOperation />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/autoscaling" element={<ContainerOrchestrationKubernetesAutoscaling />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/rolling-update" element={<ContainerOrchestrationKubernetesRollingUpdate />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift" element={<ContainerOrchestrationKubernetesRedHatOpenShift />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift/overview" element={<ContainerOrchestrationKubernetesRedHatOpenShiftOverview />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift/build" element={<ContainerOrchestrationKubernetesRedHatOpenShiftBuild />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift/operators" element={<ContainerOrchestrationKubernetesRedHatOpenShiftOperators />} />
          <Route path="/advanced-topics/container/container-orchestration/kubernetes/red-hat-openshift/istio" element={<ContainerOrchestrationKubernetesRedHatOpenShiftIstio />} />
          <Route path="/advanced-topics/DevOps" element={<AdvancedTopicsDevOps />} />
          <Route path="/advanced-topics/DevOps/overview" element={<AdvancedTopicsDevOpsOverview />} />
          <Route path="/advanced-topics/DevOps/social-coding" element={<AdvancedTopicsDevOpsSocialCoding />} />
          <Route path="/advanced-topics/DevOps/minimum-viable-product" element={<AdvancedTopicsDevOpsMvp />} />
          <Route path="/advanced-topics/DevOps/development" element={<AdvancedTopicsDevOpsDevelopment/>} />
          <Route path="/advanced-topics/DevOps/auto-testing-with-nose" element={<AdvancedTopicsDevOpsAutoTestingWithNose/>} />
          <Route path="/advanced-topics/DevOps/continuous-integration-and-continuous-delivery" element={<AdvancedTopicsDevOpsCICD/>} />
          <Route path="/advanced-topics/DevOps/continuous-integration-and-continuous-delivery/continuous-integration" element={<AdvancedTopicsDevOpsCI/>} />
          <Route path="/advanced-topics/DevOps/continuous-integration-and-continuous-delivery/continuous-delivery" element={<AdvancedTopicsDevOpsCD/>} />
          <Route path="/advanced-topics/cloud-computing" element={<AdvancedTopicsCloudComputing/>} />
          <Route path="/advanced-topics/cloud-computing/overview" element={<AdvancedTopicsCloudComputingOverview/>} />
          <Route path="/advanced-topics/cloud-computing/aws" element={<AdvancedTopicsCloudComputingAWS/>} />
          <Route path="/advanced-topics/cloud-computing/aws/networking" element={<AdvancedTopicsCloudComputingAWSNetworking/>} />
          <Route path="/advanced-topics/cloud-computing/aws/networking/aws-networking" element={<AdvancedTopicsCloudComputingAWSNetworkingNetworkingServices/>} />
          <Route path="/advanced-topics/networking" element={<AdvancedTopicsNetworking/>} />
          <Route path="/advanced-topics/networking/overview" element={<AdvancedTopicsNetworkingOverview/>} />
          <Route path="/advanced-topics/networking/tcp-ip-five-layer-network-model" element={<AdvancedTopicsNetworkingFiveLayerModel/>} />
          <Route path="*" element={<Error />} />
        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;
