import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsComponent () {

  let mainTitle = "ReactJS Component"
  let titleDescription = "ReactJS operates as a component-based framework, empowering users to structure webpages by combining various components. These components could range from a top navigation bar, title page or content boxes. In the pre-ReactJS era, users had to amalgamate all elements into a single HTML file, necessitating the creation of separate HTML files for each webpage. With ReactJS, the component-based framework, allowing users to enhance efficiency by modifying a single component file utilized across multiple HTML files. Below will show the simple procedure on creating a component with an array.";

  let advancedTopicsReactJsComponentList = [
    { "adrjcoid": 1, "subtitle": "Step 1", "imgSrc": "rfc", "subTitleDescription": "First of all, let's create a component in js file. We can input: [rfc] to automatically create a react function component template." },
    { "adrjcoid": 2, "subtitle": "Step 2", "imgSrc": "rfc-sample", "subTitleDescription": "Input some text into the component, for example:" },
    { "adrjcoid": 3, "subtitle": "Step 3", "imgSrc": "appjs-import", "subTitleDescription": "Now, open App.JS, add the ProductList tag as indicated in red. The import function highlighted in yellow should be inserted automatically after you did so. If no, do it manually." },
    { "adrjcoid": 4, "subtitle": "Step 4", "imgSrc": "appjs-modified", "subTitleDescription": "Now, let's delete some unnecessary files to make it clear. Let's delete App.css, logo.svg & reportWebVitals.js in src folder, Delete logo192, logo512 & favico.ico in public folder, remove the pre-built component from App.js & index.js and modify as above:" },
    { "adrjcoid": 5, "subtitle": "Step 5", "imgSrc": "result-1", "subTitleDescription": "Run the server by input npm run start in the terminal to see the result." },
    { "adrjcoid": 6, "subtitle": "Step 6", "imgSrc": "productlist", "subTitleDescription": "Now, create an array in ProductList.js. As you see in the code highlighted in yellow: productList.map () is a function with a callback function parameter, to map the array from the variable productList into a function, with an assigned name in the () to return the object in an array. I used an arrow function in this example." },
    { "adrjcoid": 7, "subtitle": "Step 7", "imgSrc": "productlist-inline", "subTitleDescription": "Now let's added the rest of objects from the array. You may noticed I changed the p tag into div tag, and added a \"key\" on the div, as each child item should have an unique identifier (which is id in my case) for React recognize the element." },
    { "adrjcoid": 8, "subtitle": "Step 8", "imgSrc": "product-listresult", "subTitleDescription": "Now the page should look like this:" },
    { "adrjcoid": 9, "subtitle": "Step 9", "imgSrc": "add-image", "subTitleDescription": "Next, we will import some img, to do so, we can simply create a folder in \"public\" and add img inside, and input as above. From the code, we have to add process.env.PUBLIC_URL in front of the source folder, and product.image have been indicated in the array. Of course, the imgae name itself in the pic folder have to be the same as you indicated in the array. This allow React to redirect the path when we deploy the website at the 1st time. This is extremely useful when this image will be reused mulitple time when you developing a project. For img that will only be used in single component, you could input: import [img name] from '[path]' at the top, img src={img name} under return." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsComponentList.map(advancedTopicsReactJsComponentList => (
          <div key={advancedTopicsReactJsComponentList.adrjcoid}>
            <Content
              subtitle={advancedTopicsReactJsComponentList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsComponentList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsComponentList.imgSrc}
              subTitleDescription={advancedTopicsReactJsComponentList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}