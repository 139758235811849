import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function ContainerOrchestration() {

  let containerOrchestrationList = [
    { "ctorchid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a Container Orchestration?"},
    { "ctorchid": 2, "name": "Kubernetes", "urlname": "kubernetes", "Description": "Kubernetes, often abbreviated as K8s, is an open-source container orchestration platform that automates the deployment, scaling, and management of containerized applications. Originally developed by Google, Kubernetes has gained widespread adoption and is now maintained by the Cloud Native Computing Foundation (CNCF). It provides a powerful and flexible platform for managing containerized workloads and services."},
  ]


  return (
    <div>
      <h1>Container Orchestration</h1><hr></hr>
      <div className="card-wrapper">{
        containerOrchestrationList.map(containerOrchestrationList => (
          <div key={containerOrchestrationList.ctorchid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationList.urlname + ".png"}
              imgAlt={containerOrchestrationList.urlname}
              title={<Link to={'/advanced-topics/container/container-orchestration/' + containerOrchestrationList.urlname}>{containerOrchestrationList.name}</Link>}
              description={containerOrchestrationList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
