import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsRouter () {

  let mainTitle = "ReactJS Router"
  let titleDescription = "ReactJS Router allow useres to put link and host html in React and maximize the benefits of SPA!"


  let advancedTopicsReactJsRouterList = [
    { "adrjsrid": 1, "subtitle": "Install a ReactJS Router", "imgSrc": "react-router-dom", "subTitleDescription": "First, input npm i react-router-dom to install react router." },
    { "adrjsrid": 2, "subtitle": "Import React Router Dom", "imgSrc": "use-router", "subTitleDescription": "Prepare some more js file, open App.js and import the react-router-dom and js files you need as indicated in yellow. And insert the BrowserRouter tag, Routes tag, Route tag & Link tag as indicated in red. The Link tag allow user to put url in the homepage which doesn't require a tag in traditional MPA method. The BrowserRouter tag, Routes tag, Route tag user to create url follow by the home page (/), e.g. localhost:3000/ as the homepage, localhost:3000/checkout as the checkout page etc..The path=\"*\" means to direct user to the url that is not defined above, e.g. localhost:3000/fewfhghnythn" },
    { "adrjsrid": 3, "subtitle": "Create a ReactJS Route Parameter in App.js", "imgSrc": "route_para", "subTitleDescription": "The router allow user to create url with React; while Route Parameter allow user to create url base on the object (product list). First, we have to add one more route under product detail, where the :id is fit from my use case, you can name anything as you wanted."  },
    { "adrjsrid": 4, "subtitle": "Use Route Parameter", "imgSrc": "product_detail_para", "subTitleDescription": "Then, go to the Product Detail files to insert above code. It is important to note that you could input anything after product_detail/ on the search bar, the :id doesn't reflect to the id of the product list. However, in a logical way of thinking, user would like to be directed to the corrsponding page after they clicked the product right?"  },
    { "adrjsrid": 5, "subtitle": "Map parameter to key", "imgSrc": "product_id_route", "subTitleDescription": "To do so, insert above code in the product list page to add a link to the image of the product."  },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsRouterList.map(advancedTopicsReactJsRouterList => (
          <div key={advancedTopicsReactJsRouterList.adrjsrid}>
            <Content
              subtitle={advancedTopicsReactJsRouterList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsRouterList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsRouterList.imgSrc}
              subTitleDescription={advancedTopicsReactJsRouterList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}