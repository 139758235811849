import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerDockerArchitecture () {

  let mainTitle = "Docker Architecture"
  let titleDescription = ""

  let containerDockerArchitectureList = [
    { "ctdkarid": 1, "subtitle": "DockerFiles, Image & Container", "imgSrc": "docker_image_container", "subTitleDescription": " If the concept between DockerFile, Image & Container is still unclear, here's a simple analogy: Think of the Dockerfile as the pen and paper used to write commands, creating an image. The image is like a recipe, and the container is the actual food. You can share the image (recipe) on a public registry, commonly known as Docker Hub, allowing others to cook delicious food (create a container) based on your shared recipe！" },
    { "ctdkarid": 2, "subtitle": "Docker Architecture", "imgSrc": "docker-architecture", "subTitleDescription": "In Docker Architecture, when a user sends a request from the client side to the Docker host using API or Socket, the Docker Daemon on the server side (Docker Host) takes charge. This daemon is the go-to guy for handling resources in Docker. It deals with the incoming request and sends a swift response back to the client." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerDockerArchitectureList.map(containerDockerArchitectureList => (
          <div key={containerDockerArchitectureList.ctdkarid}>
            <Content
              subtitle={containerDockerArchitectureList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerDockerArchitectureList.imgSrc + ".png"}
              imgAlt={containerDockerArchitectureList.imgSrc}
              subTitleDescription={containerDockerArchitectureList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}

