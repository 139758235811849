import React from 'react';

export default function Html() {

  return (
    <div>
      <h1>HTML</h1>
    </div>
  );
}
