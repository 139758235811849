import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonIfElse() {

let mainTitle = "If Else"
let titleDescription = ""

let pythonIfElseList = [
  { "pyifid": 1, "subtitle": "Create a s simple if else function", "imgSrc": "python_if_else", "subTitleDescription": "An if else function consists of at least two element if you only have one condition: if & else. Else if, or elif, is used when 2 or more conditions are needed." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonIfElseList.map(pythonIfElseList => (
        <div key={pythonIfElseList.pyifid}>
          <Content
            subtitle={pythonIfElseList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonIfElseList.imgSrc + ".png"}
            imgAlt={pythonIfElseList.imgSrc}
            subTitleDescription={pythonIfElseList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}