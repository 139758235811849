import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsExpress() {

  let mainTitle = "Express"
  let titleDescription = (<>Express is a web framework and a package available in NPM for creating server, allow user to manage their code easier! If you are not familiar with creating server in NodeJS, you can check out <a href="/advanced-topics/nodejs/server">how to create a server in NodeJS </a> first!</>);

  let advancedTopicsNodeJsExpressList = [
    { "adnjexid": 1, "subtitle": "Install express", "imgSrc": "appjs", "subTitleDescription": "As Express is a package in NPM, first, follow NPM module to install Express on your project. After that, follow it's installation guide by creating a new file as above. Remember to add the root after the path to tell express where the root file is." },
    { "adnjexid": 2, "subtitle": "Express server", "imgSrc": "express_server", "subTitleDescription": "Now, we can add some pages as above. Remember, if you want to show error to user when they visit a non exsiting page, the code must put at the end, otherwise user will receive the error page if they visit about page as the code are executed in order. If you compare to Server in NodeJS, you could see the code is more easily to manage with Express!" },
    { "adnjexid": 3, "subtitle": "Express View Engine - Embedded JavaScript templates (EJS)", "imgSrc": "ejs", "subTitleDescription": "Besides easier code management, Express also allow user to create dynamic content in a webpage by using view engine. We will learn how to use the view engine, EJS. As EJS is a package in NPM, first, follow NPM module to install EJS on your project. Then, follow the screenshots to: 1. Create a folder and move the html files into it & Modify app.js as above" },
    { "adnjexid": 4, "subtitle": "", "imgSrc": "ejs_dynamic", "subTitleDescription": "2. Rename the html files to ejs." },
    { "adnjexid": 5, "subtitle": "", "imgSrc": "ejs_html", "subTitleDescription": "Now you can see the header is the variable we assigned in app.js." },
    { "adnjexid": 6, "subtitle": "Reuse content with EJS", "imgSrc": "header_ejs", "subTitleDescription": "JS have another powerful function to help user maintain duplicated content much more easier. For example, if the header and footer are the same for every webpages you creating, EJS allow user to create a single header and/or footer ejs file and manage centrally." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsExpressList.map(advancedTopicsNodeJsExpressList => (
          <div key={advancedTopicsNodeJsExpressList.adnjexid}>
            <Content
              subtitle={advancedTopicsNodeJsExpressList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsExpressList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsExpressList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsExpressList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
