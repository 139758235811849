import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsCss () {

  let mainTitle = "ReactJS Css"
  let titleDescription = (<>In this module, we are going to explore 4 ways to import CSS in ReactJS! <a href="http://www.sitestepper.be/en/css-properties-to-javascript-properties-reference-list.htm">1. inline css style </a> 2. import css file 3. import CSS module 4. PUBLIC_URL path</>);


  let advancedTopicsReactJsCssList = [
    { "adrjcsid": 1, "subtitle": "I: inline css style (suitable for specific style)", "imgSrc": "inline-css", "subTitleDescription": "Inline styling is a common way to add css style and is applicable to non-reactjs (normal html) as well: You just need to convert css styling to reactjs JavaScript styling." },
    { "adrjcsid": 2, "subtitle": "II: import css file (Not Recommend)", "imgSrc": "import-css-file", "subTitleDescription": "Import css file is another common way to add css style and is applicable to non-reactjs as well: The index.js in default ReacJS project already did this for you, you just have to add more style in index.css file, and add the className into the tag." },
    { "adrjcsid": 3, "subtitle": "III: import css module (Recommend, as it avoid duplicate className)", "imgSrc": "create-css-module", "subTitleDescription": "First, create a file in src and name it as xxx.module.css, and input some style into it. Then, import the css file to the js file that you would like to use. After that, you can add className into the tag where style is the import name and body is the className you would like to apply. The difference between import css file & css module is that, import css module will automatically generate difference unique className when components share the same className."  },
    { "adrjcsid": 4, "subtitle": "IV. PUBLIC_URL path (Recommend, as it run faster by using cache)", "imgSrc": "create-public-css", "subTitleDescription": "First, create a css file in public and input some style into it. Then, open the index.html in public, and add the link, where the href will be =%PUBLIC_URL%[path]. Same as the logic of adding img by this method, this allow React to redirect to the path that we deploy the website at the 1st time. This is extremely useful when this css will be reused mulitple time when you developing a project."  },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsCssList.map(advancedTopicsReactJsCssList => (
          <div key={advancedTopicsReactJsCssList.adrjcsid}>
            <Content
              subtitle={advancedTopicsReactJsCssList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsCssList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsCssList.imgSrc}
              subTitleDescription={advancedTopicsReactJsCssList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}