import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonFramework() {

  let pythonFrameworkList = [
    { "pyfrid": 1, "name": "Flask", "urlname": "flask", "Description": "" },
    { "pyfrid": 2, "name": "Django", "urlname": "django", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Framework</h1><hr></hr>
      <div className="card-wrapper">{
        pythonFrameworkList.map(pythonFrameworkList => (
          <div key={pythonFrameworkList.pyfrid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkList.urlname + ".png"}
              imgAlt={pythonFrameworkList.urlname}
              title={<Link to={'/programming-language/python/framework/' + pythonFrameworkList.urlname}>{pythonFrameworkList.name}</Link>}
              description={pythonFrameworkList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
