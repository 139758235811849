import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function VisualStudioCodeOverview() {

  let mainTitle = "Visual Studio Code Overview"
  let titleDescription = ""

  let vsCodeOverviewList = [
    { "vsovid": 1, "subtitle": "", "imgSrc": "vscode", "subTitleDescription": "Visual Studio Code is a streamlined code editor with support for development operations like task running, and version control. I get it, you're wondering, What the hell is this? Well, fear not, my friend. Let me switch to English for the grand reveal." },
    { "vsovid": 2, "subtitle": "", "imgSrc": "vs-code-example", "subTitleDescription": "Picture this: Microsoft Word, the haven for your words. Need some serious number-crunching action? Microsoft Excel. How about coding then? Visual Studio Code (VS Code) is here for you." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        vsCodeOverviewList.map(vsCodeOverviewList => (
          <div key={vsCodeOverviewList.vsovid}>
            <Content
              subtitle={vsCodeOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + vsCodeOverviewList.imgSrc + ".png"}
              imgAlt={vsCodeOverviewList.imgSrc}
              subTitleDescription={vsCodeOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}