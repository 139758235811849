import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsNpm() {

  let mainTitle = "NodeJS NPM"
  let titleDescription = (<><a href="https://www.npmjs.com/">Node Package Manager (NPM)</a> is the world's largest Software Registry. Containing multiple useful packages!</>);

  let advancedTopicsNodeJsNpmList = [
    { "adnjnpmid": 1, "subtitle": "Initialize NPM", "imgSrc": "npm_init", "subTitleDescription": "Before installing any packages, it is better to initialize the NPM in the project first. This step does not mean to install NPM in your PC as NPM is automatically installed at the time you install NodeJS. This step is to create a package.json file to record all the packages you have installed in this project, so that other will know which packages are needed to continute on this project. To initialize NPM, we input: [npm init] on the terminal, and press enter on every steps to acccept the default setting." },
    { "adnjnpmid": 2, "subtitle": "NodeMon", "imgSrc": "nodemon", "subTitleDescription": "Let's install one package as an example. Ndoemon is one of the most useful package in NPM, it does similar thing as live server. To install nodemon (or any packages from NPM), simpliy open the terminal in VS Code and input: [npm i -g nodemon] where i means install; -g means global, the package will be installed in your PC, without this -g, the package will only store within current project; nodemon is the name of the package, the name of each package will be indicated in the installation guide. With Nodemon, rather than node [file name], we input: nodemon [file name] to start the server, and udpate changes automatically without restarting the server. " },
    { "adnjnpmid": 3, "subtitle": "Reponse multiple html files in NodeJS", "imgSrc": "installed_package", "subTitleDescription": "As we have installed a package, your package.json file should look like above." },
    { "adnjnpmid": 4, "subtitle": "NPM operation", "imgSrc": "", "subTitleDescription": "As the size of the project grows, the number and size of packages also increase, normally we won't upload (we use git ignore) the node_modules file (the file that contains the actual package application) to Git, instead, we just provide the package.json file (the file recording the packages needed) and upload it to Git. When other user pull the repository to their local, they can simply input: [npm install] in the terminal, to install all the packages to their local indicated in the package.json file." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsNpmList.map(advancedTopicsNodeJsNpmList => (
          <div key={advancedTopicsNodeJsNpmList.adnjnpmid}>
            <Content
              subtitle={advancedTopicsNodeJsNpmList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsNpmList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsNpmList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsNpmList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
