import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoSqlite () {

  let mainTitle = "Migrate with SQLite"
  let titleDescription = (<>Please install <a href="https://sqlitestudio.pl/">SQLiteStudio</a> in your local PC first.</>);

  let pythonFrameworkDjangoSqliteList = [
    { "pyfrdjsqlid": 1, "subtitle": "Step 1 - Copy the db path from Django Project", "imgSrc": "django-db-path-copy", "subTitleDescription": "First, copy the db path from the project." },
    { "pyfrdjsqlid": 2, "subtitle": "Step 2 - Add Database", "imgSrc": "django-add-db", "subTitleDescription": "Open SQLiteStudio and add a database." },
    { "pyfrdjsqlid": 3, "subtitle": "Step 3 - Create Database", "imgSrc": "django-create-db", "subTitleDescription": "Paste the path in step 1 and name your database." },
    { "pyfrdjsqlid": 4, "subtitle": "Step 4 - Migrate the Database", "imgSrc": "django-migrate", "subTitleDescription": "The databse in your project is empty right now. Now, input [python .\\manage.py migrate] to migrate the apps data to the database in your project." },
    { "pyfrdjsqlid": 5, "subtitle": "Step 5 - Refresh Database", "imgSrc": "django-refresh-db", "subTitleDescription": "Refresh the database in SQLite Studio, and you will see the tables have been updated." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoSqliteList.map(pythonFrameworkDjangoSqliteList => (
          <div key={pythonFrameworkDjangoSqliteList.pyfrdjsqlid}>
            <Content
              subtitle={pythonFrameworkDjangoSqliteList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoSqliteList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoSqliteList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoSqliteList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
