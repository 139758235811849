import React from 'react';
import { Link } from 'react-router-dom';

export default function SettingUp() {

  return (
    <div>
      <h1>Hi lovely people!</h1>
      <h3>
        If you're completely new to the programming world and computer technology, please follow the steps below to install the necessary packages on your PC.<br />
        Even for those who have learned programming before, you can go through it as a refresher!
      </h3>
      <hr></hr>
      <h1>Step 1</h1>
      <h3>
        Please install Visual Studio Code and Git according to your computer's operation system (IOS, Windows, Linux) from below icons:<br />
        <Link to="https://code.visualstudio.com/"><img src={process.env.PUBLIC_URL + "/pic/vscode.png"} alt="vscode" /></Link>
        <Link to="https://code.visualstudio.com/"><img src={process.env.PUBLIC_URL + "/pic/git&github.png"} alt="git&github" /></Link><br />
        After downloaded the packages, simply follow the guides of the application to install the packages.<br />
        I understand you know nothing about these tools, but you don't have to worried by now. <br />
      </h3>
      <hr></hr>
      <h1>Step 2</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/file-creation.png"} alt="file-creation" /><br />
        Preapre a folder to store your project, and name it as any name you like. And of course, you could also simply do it in the desktop!
      </h3>
      <hr></hr>
      <h1>Step 3</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/show-more-options.png"} alt="show-more-options" />
        <img src={process.env.PUBLIC_URL + "/pic/git-bash-here.png"} alt="git-bash-here" /><br />
        Get inside the folder you just created, or the desktop. Right click and choose "Show more options", and click [Git Bash Here].
      </h3>
      <hr></hr>
      <h1>Step 4</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/mkdir.png"} alt="mkdir" /><br />
        The UI (User Interface) of Git Bash will look like this.<br />
        Input: mkdir [file name] and click enter.<br />
        (mkdir means make a directory; [filename] is the name of the directory you would like to store all the files)
      </h3>
      <hr></hr>
      <h1>Step 5</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/cd.png"} alt="cd" /><br />
        Input: cd [file name] and click enter.<br />
        cd means change directory, allows you to get inside the file created in step 4 (see above screenshot).<br />
        Remarks: You can input: cd .. to go back to previous folder.<br />
      </h3>
      <hr></hr>
      <h1>Step 6</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/git-init.png"} alt="git-init" /><br />
        Input: cd [file name] and click enter to go back to the folder created in step 4.<br />
        Input: git init<br />
        to initiates a Git repository in your local workplace<br />
      </h3>
      <hr></hr>
      <h1>Step 7</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/ls-a.png"} alt="ls-a" /><br />
        Input: ls -a and click enter <br />
        to show all the files, including the hidden files you have within the current folder.<br />
        As shown above, you have a git folder which you created in step 6 with git init!<br />
        To check in the folder itself, make sure you check the "hidden items" under "View" tab.<br />
        <img src={process.env.PUBLIC_URL + "/pic/hidden-files.png"} alt="hidden-files" /><br />
      </h3>
      <hr></hr>
      <h1>Step 8</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/code.png"} alt="code" /><br />
        Go back to the Git bash command window<br />
        input: code . and click enter <br />
        to open Visual Studio Code with the current folder.<br />
      </h3>
      <hr></hr>
      <h1>Step 9</h1>
      <h3>
        <img src={process.env.PUBLIC_URL + "/pic/terminal.png"} alt="terminal" /><br />
        Finally, locate and open "New Terminal" from the top navagation bar. <br />
        In the terminal console, <br />
        <img src={process.env.PUBLIC_URL + "/pic/git-username.png"} alt="git-username" /><br />
        input: git config --global user.name "[your name]" <br />
        <img src={process.env.PUBLIC_URL + "/pic/git-useremail.png"} alt="git-useremail" /><br />
        input: git config --global user.email "[your email]" <br />
        seperately to set up your account, this is just for recording purposes.
      </h3>
      <hr></hr>
      <h1>All set!</h1>
      <h3>
        Now you have Visual Studio Code for you to develop codes, and a Git repository in your local workplace!
        You are ready to develop!
      </h3>
      <h1>But wait!</h1>
      <h3>
        What is Visual Studio Code and Git actually?
        If I want to backup my code online, or even share and work with my colleagues, what should I do?<br />
        If you have these questions in your mind, you have an excellent mindset to be a developer!
        Find the answer in below icons!<br />
        <Link to="/integrated-development-environments/vscode"><img src={process.env.PUBLIC_URL + "/pic/vscode.png"} alt="vscode" /></Link>
        <Link to="/git&github"><img src={process.env.PUBLIC_URL + "/pic/git&github.png"} alt="git&github" /></Link>
      </h3>
      <hr></hr>
    </div>
  );
}