import React from 'react';

export default function Error() {

  return (
    <div>
      <h1>404 Error</h1>
    </div>
  );
}
