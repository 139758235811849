import React from 'react';
import { Link } from 'react-router-dom';


export default function VisualStudioCodeHotKey() {

  return (
    <div>
      <h1>Visual Studio Code (VS Code) Hot Key</h1>
      <h3>
        I'm pretty sure you have already installed Visual Studio Code (VS code) and played inside a little bit.
        If not, please visit <Link to="/setting-up">Setting Up</Link> first.
      </h3>
      <hr></hr>
      <h3>
        Check out these hotkeys in Visual Studio Code (VS Code) that I've personally deemed indispensable.
      </h3>
      <table style={{ margin: '0 auto', border: '1px solid black' }}>
        <tr>
          <th>Hot Key</th>
          <th>Function</th>
        </tr>
        <tr>
          <td>[ctrl] + [shift] +[']</td>
          <td>Open Terminal</td>
        </tr>
        <tr>
          <td>[ctrl] + [shift] +[G]</td>
          <td>Open Source Control</td>
        </tr>
        <tr>
          <td>[ctrl] + [shift] +[E]</td>
          <td>Open Explorer</td>
        </tr>
        <tr>
          <td>! + [tab]</td>
          <td>Automatically generate html format code</td>
        </tr>
        <tr>
          <td>[shift] + [alt] + [f]</td>
          <td>Automatically strcuture the format of the code</td>
        </tr>
        <tr>
          <td>[alt] + [↑] or [↓]</td>
          <td>Move the seleced code(s) upward or downward</td>
        </tr>
        <tr>
          <td>[shift] + [alt] + [↑] or [↓]</td>
          <td>Copy & Paste the seleced code(s) upward or downward</td>
        </tr>
        <tr>
          <td>[shift] + [↑] or [↓]</td>
          <td>Select code(s) upward or downward</td>
        </tr>
        <tr>
          <td>[shift] + [alt] + [a]</td>
          <td>Comment tag hot key</td>
        </tr>
      </table>
    </div>
  );
}