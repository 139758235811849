import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitHubSettingUp() {

  let mainTitle = "GitHub Setting Up"
  let titleDescription = (<>First thing first, please register an account in <a href="https://github.com/">GitHub</a>. After that, I will show you how to create a GitHub (remote repository) in two cases: 1. Create GitHub repository with existing project (Project have been working on in your local repository); 2. Create GitHub repository with a brand new project.</>);
  
  let gitGitHubGitHubSettingUpList = [
    { "gitghsuid": 1, "subtitle": "Create GitHub repository with existing project - Step 1", "imgSrc": "step1-github", "subTitleDescription": "Login your GitHub account, go to the dashboard and locate \"Create repository\". You may find two \"Create repository\", try to avoid using the yellow as that is not really fit for new user. I will use the red one to show as an example." },
    { "gitghsuid": 2, "subtitle": "Create GitHub repository with existing project - Step 2", "imgSrc": "step2-github", "subTitleDescription": "Repository name: Name of your repository, it has to be the same as the folder you are going to upload; Public/Private: Access right of this repository, GitHub has a maximum amount of private repository; Add a README file: Instruction for users on how to use your repository, we will keep it uncheck by now; Add .gitignore: Please visit Git Ignore, we will keep it \"None\" by now; Choose a license: Detailed access contorl over Public/Private repository, we will keep it \"None\" by now. Click \"Create repository\" after the setting have been done." },
    { "gitghsuid": 3, "subtitle": "Create GitHub repository with existing project - Step 3", "imgSrc": "step3-github", "subTitleDescription": "Copy the repository URL." },
    { "gitghsuid": 4, "subtitle": "Create GitHub repository with existing project - Step 4", "imgSrc": "step4-github", "subTitleDescription": "Open the terminal in your local repository in Visual Studio Code. Input: [git push [remote repository url] [branch]] to push the commited files in local repository to the remote repository you created in Step 2. Please noted that the files committed in local repository will not automatically push to the remote repository. \"Commit\" and \"Push\" are two difference processes. Please visit GitHub Overview for detailed workflow explaination. You may wonder, it is too troublesome if I have to go in to GitHub to copy the url everytime when I want to push." },
    { "gitghsuid": 5, "subtitle": "Create GitHub repository with existing project - Step 5", "imgSrc": "step5-github", "subTitleDescription": "Input: [git remote add [repository name] [remote repository url]]. The [repository name] will be replacing the remote repository url so you don't have to copy the url everytime, the common practice of the name is \"origin\"." },
    { "gitghsuid": 6, "subtitle": "Create GitHub repository with existing project - Step 6", "imgSrc": "step6-github", "subTitleDescription": "The next time when you push, you can simply input: [git push [repository name] [branch]]. Now, input: [git remote -v] and you will see the path of the [repository name], which is origin in my case." },
    { "gitghsuid": 7, "subtitle": "Create GitHub repository with new project - Step 1", "imgSrc": "", "subTitleDescription": "Follow the Step 1 to 2 above and copy the repository URL." },
    { "gitghsuid": 8, "subtitle": "Create GitHub repository with new project - Step 2", "imgSrc": "step7-github", "subTitleDescription": "Open \"Git Bash Here\" in the repository you would like to work on. Ensure the name is the same as the remote repository. Input: [git clone [remote repository url]] to clone the remote repository to you local repository. After that, open visual studio code." },
    { "gitghsuid": 9, "subtitle": "Create GitHub repository with new project - Step 3", "imgSrc": "", "subTitleDescription": "In the terminal, input: [git push origin [branch]] to push your local repository to remote repository, as the name \"origin\" have been replaced the remote repository url you copied from step 2." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitHubSettingUpList.map(gitGitHubGitHubSettingUpList => (
          <div key={gitGitHubGitHubSettingUpList.gitghsuid}>
            <Content
              subtitle={gitGitHubGitHubSettingUpList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitHubSettingUpList.imgSrc + ".png"}
              imgAlt={gitGitHubGitHubSettingUpList.imgSrc}
              subTitleDescription={gitGitHubGitHubSettingUpList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
