import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoDispatcher () {

  let mainTitle = "Django URL Dispatcher"
  let titleDescription = ""

  let pythonFrameworkDjangoDispatcherList = [
    { "pyfrdjdpid": 1, "subtitle": "url.py Part I", "imgSrc": "django-url-1", "subTitleDescription": "This file is act as the url dispatcher and direct user from the view." },
    { "pyfrdjdpid": 2, "subtitle": "url.py Part II", "imgSrc": "django-url-2", "subTitleDescription": "Here is an example on how the url dispatcher works: First, import the HttpResponse function, then, define a view function to response a html content to user, after that, add a path to direct user to the view function when they input [home] in the browser." },
    { "pyfrdjdpid": 3, "subtitle": "url parameter", "imgSrc": "django-url-parameter", "subTitleDescription": "Similar to ReactJS useParams, Django support url parameter, both string and number. Please note that in normal practice, the function is defined in the view.py of an app rather than in url.py (I do this for demonstration purpose). In that case, you will have to insert a code [from blog import view as blogView] in the url.py, where \"blog\" is the app name, and blogView is the variable name you can customize." },
    { "pyfrdjdpid": 4, "subtitle": "url re-path", "imgSrc": "django-url-parameter-repath", "subTitleDescription": "You could use re_path function to perform more complicated parameter. In this example, user can only input a 8-digit number on the browser, otherwise system will not direct the client to any page." },
    { "pyfrdjdpid": 5, "subtitle": "Config to allow admin to view upload files", "imgSrc": "django-url-media", "subTitleDescription": "To allow admin user to view the uploaded document in the admin site later on, insert above code in the url.py file." },
    { "pyfrdjdpid": 6, "subtitle": "404 Error handling", "imgSrc": "django-404", "subTitleDescription": "To create a 404 page when user input an incorrect url, prepare the 404.html files, add a hanlder in url.py, import get 404 function in views.py & change the debug and allow host setting in the setting.py" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoDispatcherList.map(pythonFrameworkDjangoDispatcherList => (
          <div key={pythonFrameworkDjangoDispatcherList.pyfrdjdpid}>
            <Content
              subtitle={pythonFrameworkDjangoDispatcherList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoDispatcherList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoDispatcherList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoDispatcherList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
