import React from 'react';

export default function GitGitHubGitCommand() {

  return (
    <div>
      <h1>Git Command List</h1>
      <h3>
        Below is a summary of some useful git command:
      </h3>
      <hr></hr>
      <table style={{ margin: '0 auto', border: '1px solid black' }}>
        <tr>
          <th>Hot Key</th>
          <th>Function</th>
        </tr>
        <tr>
          <td>mkdir</td>
          <td>Create directory</td>
        </tr>
        <tr>
          <td>cd [file name]</td>
          <td>Enter the file</td>
        </tr>
        <tr>
          <td>cd ..</td>
          <td>Go back to previous file</td>
        </tr>
        <tr>
          <td>git init</td>
          <td>Initiate a Git repository</td>
        </tr>
        <tr>
          <td>ls</td>
          <td>List out all the file, including the hidden folder</td>
        </tr>
        <tr>
          <td>code .</td>
          <td>Open Visual Studio Code</td>
        </tr>
        <tr>
          <td>git config --global user.name "[your name]"</td>
          <td>Create username in Git</td>
        </tr>
        <tr>
          <td>git config --global user.email "[your email]"</td>
          <td>Create user email in Git</td>
        </tr>
        <tr>
          <td>git add</td>
          <td>Add file to staging area</td>
        </tr>
        <tr>
          <td>git add .</td>
          <td>Add all files to staging area</td>
        </tr>
        <tr>
          <td>git commit -m"[message]"</td>
          <td>Commit files in staging area with a message</td>
        </tr>
        <tr>
          <td>git log</td>
          <td>Show the log of commit history</td>
        </tr>
        <tr>
          <td>git status</td>
          <td>Show which files have been changed and, which files have or have not been added to staging area</td>
        </tr>
        <tr>
          <td>git checkout [ID]</td>
          <td>Check version with input [ID]</td>
        </tr>
        <tr>
          <td>git switch -</td>
          <td>Switch back to previous version</td>
        </tr>
        <tr>
          <td>git revert [ID]</td>
          <td>Remove the commit files (version) with the input ID</td>
        </tr>
        <tr>
          <td>git reset [ID]</td>
          <td>Reset back to the commit files (version) with input [ID] and remove the files (version) in between</td>
        </tr>
        <tr>
          <td>git reset --hard [ID]</td>
          <td>Reset back to the commit files (version) with input [ID] and remove the current files (version) and
            those in between</td>
        </tr>
        <tr>
          <td>git branch [branch name]</td>
          <td>Create new branch</td>
        </tr>
        <tr>
          <td>git branch -a</td>
          <td>List all branches</td>
        </tr>
        <tr>
          <td>git branch [branch name]</td>
          <td>Create new branch</td>
        </tr>
        <tr>
          <td>git branch -D [branch name]</td>
          <td>Delete branch</td>
        </tr>
        <tr>
          <td>git merge [branch name]</td>
          <td>Merge branch to master</td>
        </tr>
        <tr>
          <td>git push [remote repository url]</td>
          <td>Push local repository to remote repository</td>
        </tr>
        <tr>
          <td>git remote add [repository name] [remote repository url]</td>
          <td>Replace remote repository url by a repository name</td>
        </tr>
        <tr>
          <td>git push [repository name] [branch]</td>
          <td>Push local repository to remote repository with repository name designed</td>
        </tr>
        <tr>
          <td>git clone [remote repository url]</td>
          <td>Clone the remote repository to the local repository</td>
        </tr>
      </table>
    </div>
  );
}
