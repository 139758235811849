import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsMiddleware() {

  let mainTitle = "Middleware"
  let titleDescription = ""

  let advancedTopicsNodeJsMiddlewareList = [
    { "adnjmid": 1, "subtitle": "What is Middleare?", "imgSrc": "middleware", "subTitleDescription": "Middleware is the operation between the request fro client and response from server. This concept actually already taught in the previous module in creating server, where redirecting the user from dirrerecnt request base on the path is also one of the middleware we are performing. We will explore more middleware in this module with Express framework." },
    { "adnjmid": 2, "subtitle": "Show client's hostname & path requesting", "imgSrc": "hostname_path", "subTitleDescription": "Input below code to show client's hostname & path requesting, the parameter \"next\" is to tell the system that this middleware have been completed and can proceed to the codes underneath." },
    { "adnjmid": 3, "subtitle": "Get data from client's Post (input)", "imgSrc": "form-post", "subTitleDescription": "First, create a form like above to allow user to input something from the webpage and a post function. " },
    { "adnjmid": 4, "subtitle": "User input from browser", "imgSrc": "get_data", "subTitleDescription": "Now, we can get the data from the client input." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsMiddlewareList.map(advancedTopicsNodeJsMiddlewareList => (
          <div key={advancedTopicsNodeJsMiddlewareList.adnjmid}>
            <Content
              subtitle={advancedTopicsNodeJsMiddlewareList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsMiddlewareList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsMiddlewareList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsMiddlewareList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
