import React from "react";
import "./Content.css";

export const ContentTitle = ({
    mainTitle,
    titleDescription,
}) => {
    return (
        <div>
            <h1>{mainTitle}</h1>
            <h3>{titleDescription}</h3>
            <hr></hr>
        </div>
    );
};