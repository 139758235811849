import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonBuildInFunctions () {

  let mainTitle = "Python Build-in Functions"
  let titleDescription = ""

  let pythonBuildInFunctionsList = [
    { "pybiid": 1, "subtitle": "Variable & Print function", "imgSrc": "python_variable", "subTitleDescription": "Same as Javascript, we can assign variable to a value. Please note that there are some rules on the naming of variables: 1. Only alphabet, number or underscore can be used; 2. Can’t start with number; 3. Can’t use keywords reserved by python. For some good practice, we should follow some convention like adding underscore between spaces (hong_kong) or stating the first word with lower case (hongKong)." },
    { "pybiid": 2, "subtitle": "Order logic in Python", "imgSrc": "python_upper_to_lower_logic", "subTitleDescription": "The code is always excute from top to bottom, as \"a\" is assigned to 4 in row 2 below the print function, the variable \"a\" become 4." },
    { "pybiid": 3, "subtitle": "Change Data Type function", "imgSrc": "python_change_data_type", "subTitleDescription": "You can also change the data type of a variable after defined it, and use type() function to check the data type." },
    { "pybiid": 4, "subtitle": "Comment function", "imgSrc": "python_comment", "subTitleDescription": "You can use \"#\" for single line comment." },
    { "pybiid": 5, "subtitle": "Input function", "imgSrc": "python_input_function", "subTitleDescription": "Python also have a input function allow user to input value" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonBuildInFunctionsList.map(pythonBuildInFunctionsList => (
          <div key={pythonBuildInFunctionsList.pybiid}>
            <Content
              subtitle={pythonBuildInFunctionsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonBuildInFunctionsList.imgSrc + ".png"}
              imgAlt={pythonBuildInFunctionsList.imgSrc}
              subTitleDescription={pythonBuildInFunctionsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}