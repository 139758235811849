import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function GitGitHubGitRevert() {

  let gitGitHubGitRevertList = [
    { "gitgirvd": 1, "name": "Checkout Commit", "urlname": "checkout-commit", "Description": "Checkout commit is employed for investigation. It is well-suited for reviewing multiple versions without the need to edit or create additional commit files."},
    { "gitgirvd": 2, "name": "Revert Commit", "urlname": "revert-commit", "Description": "Revert commit is well-suited for removing unwanted changes made before by committing a new version."},
    { "gitgirvd": 3, "name": "Reset Commit", "urlname": "reset-commit", "Description": "Reset commit is to reset the project back to certain version by removing the commit files in between."},
  ]


  return (
    <div>
      <h1>Git Revert</h1><hr></hr>
      <div className="card-wrapper">{
        gitGitHubGitRevertList.map(gitGitHubGitRevertList => (
          <div key={gitGitHubGitRevertList.gitgirvd}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitRevertList.urlname + ".png"}
              imgAlt={gitGitHubGitRevertList.urlname}
              title={<Link to={'/git&github/git/revert/' + gitGitHubGitRevertList.urlname}>{gitGitHubGitRevertList.name}</Link>}
              description={gitGitHubGitRevertList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
