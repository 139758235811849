import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsSpaMpa() {

  let mainTitle = "SPA vs MPA"
  let titleDescription = "Single Page Application (SPA) vs Multi Page Application (MPA)"

  let advancedTopicsReactJsSpaMpaList = [
    { "adrjsmid": 1, "subtitle": "Multi Page Application (MPA)", "imgSrc": "mpa", "subTitleDescription": "A Multi-Page Application (MPA) follows the traditional approach to web page design. When a user enters a URL and requests the server's response, the server sends HTML, CSS, and JavaScript to the client side. If the user navigates between pages, like from the homepage to the contact page, the server fulfils the request by sending all the necessary files (HTML, CSS, JavaScript) again, resulting in a full web page reload each time." },
    { "adrjsmid": 2, "subtitle": "Single Page Application (SPA)", "imgSrc": "spa", "subTitleDescription": "A Single-Page Application (SPA) initially functions similarly to an MPA during the user's first visit. However, required files will be loaded subsequently to the client side. When navigating between pages, such as from the homepage to the contact page, the request is handled by ReactJS and doesn't pass to the server. Depending on the content, like checking emails where the layout (HTML and CSS) remains consistent (static), ReactJS updates only the email (dynamic) content, leading to quicker response times and an improved user experience." },
    { "adrjsmid": 3, "subtitle": "Single Page Application (SPA) vs Multi Page Application (MPA)", "imgSrc": "spa-vs-mpa", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsSpaMpaList.map(advancedTopicsReactJsSpaMpaList => (
          <div key={advancedTopicsReactJsSpaMpaList.adrjsmid}>
            <Content
              subtitle={advancedTopicsReactJsSpaMpaList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsSpaMpaList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsSpaMpaList.imgSrc}
              subTitleDescription={advancedTopicsReactJsSpaMpaList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
