import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function GitGitHubGitHub() {

  let gitGitHubGitHubList = [
    { "gitghid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a GitHub?"},
    { "gitghid": 2, "name": "Setting up GitHub", "urlname": "setting-up", "Description": "Checkout how to set up Github!"},
  ]


  return (
    <div>
      <h1>GitHub</h1><hr></hr>
      <div className="card-wrapper">{
        gitGitHubGitHubList.map(gitGitHubGitHubList => (
          <div key={gitGitHubGitHubList.gitghid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitHubList.urlname + ".png"}
              imgAlt={gitGitHubGitHubList.urlname}
              title={<Link to={'/git&github/github/' + gitGitHubGitHubList.urlname}>{gitGitHubGitHubList.name}</Link>}
              description={gitGitHubGitHubList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
