import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonModules() {

let mainTitle = "Python Modules"
let titleDescription = "In Python, a module is a file containing Python definitions and statements. The file name is the module name with the suffix .py added. Modules allow you to organize your Python code logically by splitting it into separate files. Each module can define functions, classes, and variables that can be reused in other Python scripts or modules."

let pythonModulesList = [
  { "pymid": 1, "subtitle": "Step 1", "imgSrc": "python_module", "subTitleDescription": "Create a function in myModule.py" },
  { "pymid": 2, "subtitle": "Step 2", "imgSrc": "python_import_module", "subTitleDescription": "Import the myModule.py file in another file, index.py. Use the function or variable from module.py by input the [file name].[function name], or [file name].[variable]" },
  { "pymid": 2, "subtitle": "Another alternative", "imgSrc": "python_from_module", "subTitleDescription": "Alternatively, you could use: from [file name] import, to import the module, so that you can use the function or variable directly in the current file without adding the file name in front." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonModulesList.map(pythonModulesList => (
        <div key={pythonModulesList.pymid}>
          <Content
            subtitle={pythonModulesList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonModulesList.imgSrc + ".png"}
            imgAlt={pythonModulesList.imgSrc}
            subTitleDescription={pythonModulesList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}