import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsServer() {

  let mainTitle = "NodeJS Server"
  let titleDescription = (<>Server in NodeJS is a built in tool that allows user to host server by JavaScript. If you are familiar with this topic, you can jump to <a href="/advanced-topics/nodejs/express">Express</a> to learn using express to build server!</>);

  let advancedTopicsNodeJsServerList = [
    { "adnjsid": 1, "subtitle": "Creating server in NodeJS", "imgSrc": "nodejs-server", "subTitleDescription": "By inputting above script, a server localhost:3000 have been started by running the script, and can be stopped by input [ctrl] +[C]." },
    { "adnjsid": 2, "subtitle": "Reponse an html file", "imgSrc": "response_html", "subTitleDescription": "To return an html page, user can use the file system function to read the file in your project, with an if funtion to response the html page to the user." },
    { "adnjsid": 3, "subtitle": "Reponse multiple html files in NodeJS", "imgSrc": "response_multiple_html", "subTitleDescription": "To return multiple html pages, user can add create a variable with a switch (or if) function. I created some html pages for demonstration purpose, and remember to change the parameter in the readFile to path, or the variable you assigned above." },
    { "adnjsid": 4, "subtitle": "Status code", "imgSrc": "status_code", "subTitleDescription": "Status code is a category of responses, the most common one is 200=OK, 301=Move Permanently, 404=Not Found, 500=Internal Server Error." },
    { "adnjsid": 5, "subtitle": "Customize status code in NodeJS", "imgSrc": "customize_status_code", "subTitleDescription": "User can customise the status code by below code: [response.statusCode =], User can use [response.setHeader = ('Location',[path])] to redirect user from an permanently moved page." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsServerList.map(advancedTopicsNodeJsServerList => (
          <div key={advancedTopicsNodeJsServerList.adnjsid}>
            <Content
              subtitle={advancedTopicsNodeJsServerList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsServerList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsServerList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsServerList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
