import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsCloudComputingAWSNetworking() {

  let advancedTopicsCloudComputingAWSNetworkingList = [
    { "adawsntid": 1, "name": "Networking Services", "urlname": "aws-networking", "Description": "" },
    { "adawsntid": 2, "name": "Route53", "urlname": "route53", "Description": "" },
  ]


  return (
    <div>
      <h1>AWS Networking</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsCloudComputingAWSNetworkingList.map(advancedTopicsCloudComputingAWSNetworkingList => (
          <div key={advancedTopicsCloudComputingAWSNetworkingList.adawsntid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsCloudComputingAWSNetworkingList.urlname + ".png"}
              imgAlt={advancedTopicsCloudComputingAWSNetworkingList.urlname}
              title={<Link to={'/advanced-topics/cloud-computing/aws/networking/' + advancedTopicsCloudComputingAWSNetworkingList.urlname}>{advancedTopicsCloudComputingAWSNetworkingList.name}</Link>}
              description={advancedTopicsCloudComputingAWSNetworkingList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
