import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesArchitecture () {

  let mainTitle = "Kubernetes Architecture"
  let titleDescription = ""

  let containerOrchestrationKubernetesArchitectureList = [
    { "ctorchkuarid": 1, "subtitle": "Worker-Node: Kubelet, Kube-Proxy & Container Runtime", "imgSrc": "kubernetes-kubelet-kubeproxy-containerruntime", "subTitleDescription": "Kubernetes uses classic Master-Worker architecture. Master-Node responsible for managing Worker-Node; Worker-Node responsible for running services and applications, which is most of the objects mentioned in Kubernetes Objects. In order to communicate with resources outside a node, most of the Worker-Node consist of three components, kubelet (responsible for managing the pods with CLI, we will talk about it in another module), kube-proxy (responsible for scaling in a Node-Level) and container runtime (responsible for running the container)." },
    { "ctorchkuarid": 2, "subtitle": "Master Node", "imgSrc": "kubernetes-masternode", "subTitleDescription": "The architecture in a Master-Node (Control plane) is completely different from Worker-Node. A Master-Node consists of 4 major components: 1. Kube-apiserver acts as an API server to communicate with all Worker-Nodes by using kubectl in each Worker-Node, including create, update, or delete a Pod from a user with proper access rights; 2. Scheduler is responsible for managing pods, including placing a newly created Pod to a suitable Node based on the usage of each Worker-Node; 3. Controller Manager manages the status and health of Worker-Node (Pod & Service) by restarting or replacing them when they experience an outage; 4. ETCD is like a database, storing the status of all Worker-Nodes (Pod & Service), similar to a log document, and this info can be passed to Controller Manager for managing Worker-Nodes. If you are using some cloud provider like AWS, you will also have a fifth component, Cloud Controller Manager, allowing communication between Kubernetes and the vendor (cloud provider)." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesArchitectureList.map(containerOrchestrationKubernetesArchitectureList => (
          <div key={containerOrchestrationKubernetesArchitectureList.ctorchkuarid}>
            <Content
              subtitle={containerOrchestrationKubernetesArchitectureList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesArchitectureList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesArchitectureList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesArchitectureList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}

