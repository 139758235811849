import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonForLoop() {

let mainTitle = "For Loop"
let titleDescription = ""

let pythonForLoopList = [
  { "pyforid": 1, "subtitle": "Create a s simple for loop function", "imgSrc": "python_for_loop", "subTitleDescription": "For loop usually work with a list. From above example, the for function will extract the element in the object one by one, until nothing in the list can be extracted, and then execute the else function." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonForLoopList.map(pythonForLoopList => (
        <div key={pythonForLoopList.pyforid}>
          <Content
            subtitle={pythonForLoopList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonForLoopList.imgSrc + ".png"}
            imgAlt={pythonForLoopList.imgSrc}
            subTitleDescription={pythonForLoopList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}