import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function GitGitHub() {

  let gitList = [
    { "ghid": 1, "name": "Git", "urlname": "git", "Description": "Git is a distributed version control system used for tracking changes in source code during software development. It allows multiple developers to collaborate on projects simultaneously without overwriting each other's work. Git keeps a history of changes to files, making it possible to revert to previous versions if needed. It's widely used in the software industry and is known for its efficiency, speed, and flexibility." },
    { "ghid": 2, "name": "GitHub", "urlname": "github", "Description": "GitHub is a web-based platform built on top of Git that provides hosting for software development version control using Git. It offers a range of collaboration features such as bug tracking, feature requests, task management, and wikis for every project. GitHub also provides social networking features such as followers, repositories, and feeds for developers and organizations. It's widely used by developers and teams to host and review code, manage projects, and build software collaboratively." },
  ]


  return (
    <div>
      <h1>Git & GitHub</h1>
      <div className="card-wrapper">{
        gitList.map(gitList => (
          <div key={gitList.ghid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitList.urlname + ".png"}
              imgAlt={gitList.name}
              title={<Link to={'/git&github/' + gitList.name}>{gitList.urlname}</Link>}
              description={gitList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}