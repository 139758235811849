import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoInstallationOperation () {

  let mainTitle = "Django Installation & Operation"
  let titleDescription = ""

  let pythonFrameworkDjangoInstallationOperationList = [
    { "pyfrdjioid": 1, "subtitle": "Step 1 - Create a virtual environment", "imgSrc": "django-venv", "subTitleDescription": "First, create a virtual environment from VS code terminal by input [python -m venv [environment name]]. Input [ls] to check if the environment is created." },
    { "pyfrdjioid": 2, "subtitle": "Step 2 - Open Window PowerShell", "imgSrc": "powershell", "subTitleDescription": "Search Window PowerShell, right click on the icon and select run as administrator." },
    { "pyfrdjioid": 3, "subtitle": "Step 3 - PowerShell set up", "imgSrc": "powershell-setting", "subTitleDescription": "In the command, input [Set-ExecutionPolicy RemoteSigned] and click enter, then input [Y] to indicate to change policy." },
    { "pyfrdjioid": 4, "subtitle": "Step 4 - Activate & Enter virtual environment", "imgSrc": "django-activate-venv", "subTitleDescription": "Go back to the VS code terminal and input [.\\env\\Scripts\\Activate] to enter the virtual environment." },
    { "pyfrdjioid": 5, "subtitle": "Step 5 - Install Django", "imgSrc": "django-install", "subTitleDescription": "Input [pip install django] to install django. Now, please make sure you have install python & django extension in VS code." },
    { "pyfrdjioid": 6, "subtitle": "Step 6 - Start a Django project", "imgSrc": "django-start-project", "subTitleDescription": "Input [django-admin startproject [demoSite]] to create a Django project folder. [demoSite] is the folder name, you can name it as another name." },
    { "pyfrdjioid": 7, "subtitle": "Step 7 - Start server by using manage.py", "imgSrc": "django-start-server", "subTitleDescription": "Collapse the project folder, remember the django folder structure? The manage.py allows us to perform other command. First, use [cd] command to change the directory into the project folder (demoSite), then, input [python .\\mange.py runserver]. You can verify by clicking the server link to your browser." },
    { "pyfrdjioid": 8, "subtitle": "Step 8 - Django page", "imgSrc": "django-page", "subTitleDescription": "You should be able to see the same page as shown above." },
    { "pyfrdjioid": 9, "subtitle": "Step 9 - setting.py Part I", "imgSrc": "django-setting-1", "subTitleDescription": "Now, let's breakdown the setting.py: SECRET_KEY is the unique encryption key of a project; DEBUG = TRUE is to allow django to show debug message to developers; INSTALLED_APPS are the apps installed in your project, this list have to update manually; TEMPLATE is the template data in your project, it is default as empty in the DIRS (directory), I have modified it into a new folder call templates." },
    { "pyfrdjioid": 10, "subtitle": "Step 10 - setting.py Part II", "imgSrc": "django-setting-2", "subTitleDescription": "I will use the default sql engine so leave the database section as it is; change the time zone as you like; and input the code under Static section, here I created two folders, one for storing all static files (css, js etc), another is for storing any uploaded files." },
    { "pyfrdjioid": 11, "subtitle": "Step 11 - setting.py Part III", "imgSrc": "django-setting-3", "subTitleDescription": "Remember to import os into the setting.py file." },
    { "pyfrdjioid": 12, "subtitle": "Step 12 - setting.py Part IV", "imgSrc": "django-setting-4", "subTitleDescription": "Now, create the the folders that were created in Step 9 & 10 in the project folder." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoInstallationOperationList.map(pythonFrameworkDjangoInstallationOperationList => (
          <div key={pythonFrameworkDjangoInstallationOperationList.pyfrdjioid}>
            <Content
              subtitle={pythonFrameworkDjangoInstallationOperationList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoInstallationOperationList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoInstallationOperationList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoInstallationOperationList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
