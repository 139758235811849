import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesRedHatOpenShiftBuild() {

  let mainTitle = "OpenShift Build"
  let titleDescription = "OpenShift Build refers to the process of creating container images from source code and dependencies. OpenShift provides a build system that automates the steps involved in building, testing, and packaging applications into container images that can be deployed on the OpenShift platform."

  let containerOrchestrationKubernetesRedHatOpenShiftBuildList = [
    { "ctorochrhobid": 1, "subtitle": "What is build?", "imgSrc": "build", "subTitleDescription": "A Build is the process of transforming inputs into a resultant object. For example, transforming source code to a container image. A Build requires a build configuration file (or BuildConfig), which defines the build strategy and input sources. Commonly used Build strategies are: Source-to-Image (S2I), Docker, and Custom." },
    { "ctorochrhobid": 2, "subtitle": "Build input source", "imgSrc": "red-hat-openshift-input-source", "subTitleDescription": "" },
    { "ctorochrhobid": 3, "subtitle": "ImageStream", "imgSrc": "red-hat-openshift-image-stream", "subTitleDescription": "An ImageStream is an abstraction for referencing container images within OpenShift. An ImageStream: Continuously creates and updates container images, but does not contain actual image data. Instead, it points to images stored in internal and external registries, or to other ImageStreams. A single ImageStream can consist of many different tags such as latest, dev and test. And each tag points to a certain image in a registry. To deploy an application, you’ll refer to the image stream tag rather than hardcode the registry URL and tag. If the source image location changes, you’ll update the image stream definition, rather than individually updating all the deployments. An ImageStream also provides a trigger capability that automatically invokes builds and deployments when a new version of an image is available." },
    { "ctorochrhobid": 4, "subtitle": "Build triggers", "imgSrc": "red-hat-openshift-build-trigger", "subTitleDescription": "Rather than running builds manually, automate the process using triggers. Webhook triggers: Send a request to an API endpoint. And they also support generic webhooks and the more often used GitHub webhooks which send the trigger request to the API endpoint on any new commit or a pull request or other circumstances. Next is the Image change trigger, which: Triggers builds when a new version of an image is available. For instance, if you build your application using a Node.js base image, that image is updated when security fixes are released, and other updates occur. Finally, a configuration change trigger: Causes a new build to run when you create a new BuildConfig resource." },
    { "ctorochrhobid": 5, "subtitle": "Source-to-Image (S2I) strategy", "imgSrc": "red-hat-openshift-s2i", "subTitleDescription": "Source-to-Image (S2I) strategy tools: Builds reproducible container images, and Injects a container image with the app source to produce a ready-to-run image. The new image is built by incorporating a builder image plus the source that avoids using a Dockerfile, which enables going from Source to Image in a single step. OpenShift comes with a variety of available builder images, saving you time and development effort!" },
    { "ctorochrhobid": 6, "subtitle": "Docker build strategy", "imgSrc": "red-hat-openshift-docker-build-strategy", "subTitleDescription": "Using a Docker Build strategy requires a repository that contains a Dockerfile and the necessary artifacts. When you kick off a build, OpenShift takes the input, invokes the “docker build“ command and creates an image which is then pushed to the internal OpenShift registry. Here are four ways to implement the Docker build strategy: replace Dockerfile FROM image, use Dockerfile path, use Docker environment variables, or add Docker build arguments." },
    { "ctorochrhobid": 7, "subtitle": "Custom build strategy", "imgSrc": "red-hat-openshift-custom-build-strategy", "subTitleDescription": "In a custom build strategy, you must define and create your own builder image required for the build process. Custom builder images are regular Docker images that contain the logic needed to transform the inputs into the expected output. Both Docker and S2I strategies result in runnable images, but the custom build strategy creates additional objects like JAR files and CI/CD deployment that performs unit or integration tests. Custom builds are only available to cluster administrators because they run with high privileges." },
    { "ctorochrhobid": 6, "subtitle": "Build Automation", "imgSrc": "red-hat-openshift-build-automation", "subTitleDescription": "Cloud-native development requires greater automation throughout the container lifecycle. Automation is available using the continuous integration and continuous deliverypipeline, or CI/CD. For example, the OpenShift CI/CD process automatically merges new code changes to the repository, then builds, tests, approves, and deploys a new version to different environments." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesRedHatOpenShiftBuildList.map(containerOrchestrationKubernetesRedHatOpenShiftBuildList => (
          <div key={containerOrchestrationKubernetesRedHatOpenShiftBuildList.ctorochrhobid}>
            <Content
              subtitle={containerOrchestrationKubernetesRedHatOpenShiftBuildList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRedHatOpenShiftBuildList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesRedHatOpenShiftBuildList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesRedHatOpenShiftBuildList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
