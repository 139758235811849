import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function ContainerDocker() {

  let containerList = [
    { "ctid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a docker?" },
    { "ctid": 2, "name": "Docker Object", "urlname": "docker-object", "Description": "Docker contains objects such as the Dockerfile, images, container, network, storage volumes, and other objects, such as plugins and add-ons." },
    { "ctid": 3, "name": "Docker Architecture", "urlname": "docker-architecture", "Description": "" },
    { "ctid": 4, "name": "Installation & Operation", "urlname": "installation-&-operation", "Description": "Install and operate Docker here!" },
  ]


  return (
    <div>
      <h1>Docker</h1><hr></hr>
      <div className="card-wrapper">{
        containerList.map(containerList => (
          <div key={containerList.ctid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerList.urlname + ".png"}
              imgAlt={containerList.urlname}
              title={<Link to={'/advanced-topics/container/docker/' + containerList.urlname}>{containerList.name}</Link>}
              description={containerList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}