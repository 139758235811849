import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonNumPy() {

  let mainTitle = "NumPy"
  let titleDescription = "Numpy is a library for scientific computing. It has many useful functions. There are many other advantages like speed and memory. Numpy is also the basis for Pandas."

  let pythonNumPyList = [
    { "pynpid": 1, "subtitle": "Install NumPy", "imgSrc": "python_install_NumPy", "subTitleDescription": "Use the VS Code Terminal to install NumPy." },
    { "pynpid": 2, "subtitle": "Create an Array in NumPy", "imgSrc": "python_array_with_numpy", "subTitleDescription": "We can create an array from list or tuple by using the np.array() function." },
    { "pynpid": 3, "subtitle": "Dimension", "imgSrc": "python_dimension_with_numpy", "subTitleDescription": "A dimension refers the axes or directions along which data is organized in a NumPy array. You could use the .ndim function to check the number of dimension in an array." },
    { "pynpid": 4, "subtitle": "Addition, Subtraction & Multiplication in 1 Dimension", "imgSrc": "python_add_sub_mul_with_numpy", "subTitleDescription": "The operation will perform base on the index positon of the arrays." },
    { "pynpid": 5, "subtitle": "Addition, Subtraction & Multiplication in 2 or more Dimensions", "imgSrc": "python_add_sub_mul_2_dimen_with_numpy", "subTitleDescription": "Same as 1 dimension, the operation will perform base on the index positon of the arrays." },
    { "pynpid": 6, "subtitle": "Dot Product", "imgSrc": "python_dot_with_numpy", "subTitleDescription": "With np.dot() function, the array will first multiply the variable base on the index positon of the arrays, and sum of the product after that, which is 2*3+3*2=12 in our case" },
    { "pynpid": 7, "subtitle": "Dot Product in different Dimensions", "imgSrc": "python_dot_2_dimen_with_numpy", "subTitleDescription": "Similarly, With np.dot() function in different Dimensions, the array will first multiply the variable in 0 positon of the first row in the first array to the variable in 0 positon of the first row in the second array, and so on, and finally sum up the variable result to the first variable of the new array (1*13+1*17+1*21=110)." },
    { "pynpid": 8, "subtitle": "linespace() function", "imgSrc": "python_linspace_with_numpy", "subTitleDescription": "You could use linspace() function to create a custom array with 3 parameters, indicate the start, end and the number (spacing between) of variables." },
    { "pynpid": 9, "subtitle": "Other Basic Operators", "imgSrc": "python_basic_operator_with_numpy", "subTitleDescription": "There are some useful operators that you could deal with the numbers in an array." },
    { "pynpid": 10, "subtitle": "Shape in 2 or more Dimensions", "imgSrc": "python_shape_with_numpy", "subTitleDescription": "The .shape function returns a tuple with each index having the number of corresponding elements. The first parameter in the tuple corresponds to the number of nested lists contained in the original list or the number of rows in the rectangular representation. The second parameter corresponds to the size of each of the nested list or the number of columns in the rectangular array zero." },
    { "pynpid": 11, "subtitle": "Size in 2 or more Dimensions", "imgSrc": "python_size_with_numpy", "subTitleDescription": "You can also use the .size function to get the size of the array. We see there are 4 rows and 2 columns. Multiplying the number of columns and rows together, we get the total number of elements, in this case 8." },
    { "pynpid": 12, "subtitle": "Slicing in 2 or more Dimensions", "imgSrc": "python_slice_with_numpy", "subTitleDescription": "For slicing in 2 or mroe dimensions array, the first index represents the row and the second index represents the column, remember they always start with 0. You could also put something like [0:2,2], where 0:2 represents the number of rows and the 2 represents the column, result in a part of row/column. Again, remeber the slicing logic is the same in python, the last index - 2 in [0:2] will not be printed." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonNumPyList.map(pythonNumPyList => (
          <div key={pythonNumPyList.pynpid}>
            <Content
              subtitle={pythonNumPyList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonNumPyList.imgSrc + ".png"}
              imgAlt={pythonNumPyList.imgSrc}
              subTitleDescription={pythonNumPyList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}