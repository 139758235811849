import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonNumbers () {

  let mainTitle = "Python Numbers"
  let titleDescription = ""

  let pythonNumberList = [
    { "pynuid": 1, "subtitle": "Integer & Float", "imgSrc": "python_number_float", "subTitleDescription": "Integer refers to integer type; while float refers to decimal number." },
    { "pynuid": 2, "subtitle": "Arithmetic operator", "imgSrc": "python_arithmetic _operators", "subTitleDescription": "Arithmetic operators are used with numeric values to perform common mathematical operations, you can also use it in a print function as long as they are both int or float variable, othjerwise it will show error or become a concatenation." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonNumberList.map(pythonNumberList => (
          <div key={pythonNumberList.pynuid}>
            <Content
              subtitle={pythonNumberList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonNumberList.imgSrc + ".png"}
              imgAlt={pythonNumberList.imgSrc}
              subTitleDescription={pythonNumberList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}