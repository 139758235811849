import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsReactJs() {

  let advancedTopicsReactJsList = [
    { "adrjid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a ReactJS?" },
    { "adrjid": 2, "name": "SPA vs MPA", "urlname": "spa-&-mpa", "Description": "What exactly is SPA?" },
    { "adrjid": 3, "name": "Installation & Operation", "urlname": "installation-&-operation", "Description": "Install and operate React here!" },
    { "adrjid": 4, "name": "Javascript XML (JSX) Knowhow", "urlname": "know-how", "Description": "Check what is JSX here!" },
    { "adrjid": 5, "name": "ReactJS Component with Array", "urlname": "component", "Description": "What is a component based framework?" },
    { "adrjid": 6, "name": "CSS in ReactJS", "urlname": "css", "Description": "Check how to use css here!" },
    { "adrjid": 7, "name": "ReactJS State", "urlname": "state", "Description": "What is a State?" },
    { "adrjid": 8, "name": "ReactJS Router", "urlname": "router", "Description": "How can we route user into difference url in React?" },
    { "adrjid": 9, "name": "ReactJS Props", "urlname": "props", "Description": "What is a Props? And what is the relationship between Props and Components?" },
    { "adrjid": 10, "name": "ReactJS useEffect", "urlname": "useeffect", "Description": "Explore useEffect here!" },
  ]


  return (
    <div>
      <h1>ReactJs</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsReactJsList.map(advancedTopicsReactJsList => (
          <div key={advancedTopicsReactJsList.adrjid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsList.urlname + ".png"}
              imgAlt={advancedTopicsReactJsList.urlname}
              title={<Link to={'/advanced-topics/Reactjs/' + advancedTopicsReactJsList.urlname}>{advancedTopicsReactJsList.name}</Link>}
              description={advancedTopicsReactJsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}