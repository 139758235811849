import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNetworkingOverview() {

  let mainTitle = "Networking"
  let titleDescription = ""

  let advancedTopicsNetworkingOverviewList = [
    { "adntovid": 1, "subtitle": "Networking", "imgSrc": "networking", "subTitleDescription": "Networking in IT refers to the practice of connecting computers and other devices together to share resources, exchange data, and communicate with each other." },
    { "adntovid": 2, "subtitle": "Internet", "imgSrc": "internet", "subTitleDescription": "Clients, such as mobile phones, laptops, and video game consoles, among others, are the devices we use to access content from servers. When clients request content like websites, they don't connect directly to the Internet; instead, they connect to networks provided by Internet service providers (ISPs). ISPs manage extensive networks with physical cabling that links millions of computers together. Additionally, ISPs interconnect with other networks and providers, collectively forming the vast network known as the Internet." },
    { "adntovid": 3, "subtitle": "Connecting to internet", "imgSrc": "connecting", "subTitleDescription": "Sending data between computers on a network is akin to sending a letter to someone. Similar to how you'd include your address on the letter and send it to the recipient's address, computers also have addresses. Specifically, computers on a network are identified by their IP addresses, which consist of digits and numbers like 100.1.4.3. When accessing a website, you're essentially reaching its IP address, such as 172.217.6.46. Additionally, devices capable of network connection possess another unique identifier known as a MAC address. Unlike IP addresses, MAC addresses are typically permanent and ingrained into the device. When transmitting or receiving data through a network, both an IP and a MAC address are necessary. Consider the letter analogy once more: the IP address serves as the house address, while the MAC address functions as the recipient's name. This dual identification ensures that data is routed accurately to the intended location and recipient on the network." },
    { "adntovid": 4, "subtitle": "Protocol", "imgSrc": "protocol", "subTitleDescription": "Consider verbal communication: for effective interaction, two individuals must share a common language and be able to hear each other. In noisy environments, one might need to ask the other to repeat themselves. Similarly, computers adhere to specific rules when communicating. This set of standards, ensuring proper communication among computers, is termed a protocol." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNetworkingOverviewList.map(advancedTopicsNetworkingOverviewList => (
          <div key={advancedTopicsNetworkingOverviewList.adntovid}>
            <Content
              subtitle={advancedTopicsNetworkingOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNetworkingOverviewList.imgSrc + ".png"}
              imgAlt={advancedTopicsNetworkingOverviewList.imgSrc}
              subTitleDescription={advancedTopicsNetworkingOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
