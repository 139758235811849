import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitMasterBranches() {

  let mainTitle = "Master & Branches"
  let titleDescription = ""

  let gitGitHubGitMasterBranchesList = [
    { "gitgmbid": 1, "subtitle": "Real Life example - Part 1", "imgSrc": "general-workflow-drive", "subTitleDescription": "To illustrate, consider this scenario: Your manager initiates a document with only a table of contents, places it on a drive, which is the Master doc, and assigns you and two teammates to collaborate on the content. Each of you downloads a copy to your local PCs, working on separate parts—let's say A, B, and C. Upon downloading, new branches (A, B, C) are created. Before the changes load back to the drive, the manager will not see any changes." },
    { "gitgmbid": 2, "subtitle": "Real Life example - Part 2", "imgSrc": "general-workflow-drive-done", "subTitleDescription": "Once all branches are completed, you and your teammates upload the files back to the drive, and your manager can review and merge them back into the Master." },
    { "gitgmbid": 3, "subtitle": "Real Life example - Part 3", "imgSrc": "master&branches", "subTitleDescription": "Now, you might wonder why the team don't edit the same document initially as I mentioned Git is similar to Google Drive. Yes, they share similarities, but the crucial distinction lies in the fact that GitHub, unlike Google Drive, enables users to share repositories remotely but prevents simultaneous editing of the same repository. Now, let's delve into another workflow for a more comprehensive understanding." },
    { "gitgmbid": 4, "subtitle": "Real Life example - Part 4", "imgSrc": "working-alone", "subTitleDescription": "If you are working on your own, you can take snapshots with Git but may not require a branch, or even a remote repository (GitHub); but what if you are working with 2 teammates, or even more? When collaborating with teammates, things will get complicated, and the necessity of branches becomes apparent. In DevOps practice, it is recommended to have small branches allowing faster feedback and deliver, support experimentation and minimise waste. Consider this scenario: when tasked with sending 1000 envelopes, do you opt to fold all 1000 letters beforehand and then insert them into the envelopes, or do you fold each letter individually as you insert them? By folding them one by one, you might realize much sooner if the size of the letter doesn't fit the envelope, compared to folding all 1000 letters before checking." },
    { "gitgmbid": 5, "subtitle": "Quick Recap", "imgSrc": "master-and-branch", "subTitleDescription": "Master, is the enduring route housing the ultimate rendition of a project, the convergence point for all branches. The name of Master won't change and will always be the final or production version; Branch, conversely, serves as a provisional offshoot from the master's trajectory, destined to merge with it and be eliminated upon accomplishing its designated purpose. Unlike Master, Branch can assign names to it, i.e. branch 1, branch 2, or even xx-function, yy-function etc. Now, let me show you how to create a branch in Git!" },
    { "gitgmbid": 6, "subtitle": "Step 1", "imgSrc": "check-master", "subTitleDescription": "If you haven't create any branches, you will be in master by default, you could always check which path you are at on the bottom left corner. Now, commit your files in master path. (Forget how to do? Please visit Git Versioning.)" },
    { "gitgmbid": 7, "subtitle": "Step 2", "imgSrc": "create-branch", "subTitleDescription": "To create a new branch, input [git branch [branch name]], try to name your branch with something meaningful." },
    { "gitgmbid": 8, "subtitle": "Step 3", "imgSrc": "list-branch", "subTitleDescription": "Input [git branch -a] to list out how many branch you have currently. You should have on master and one branch you just created, the one in green with a [*] is the path you currently at." },
    { "gitgmbid": 9, "subtitle": "Step 4", "imgSrc": "switch-branch", "subTitleDescription": "Input: [git switch [branch name]], to switch the path from master to the branch created." },
    { "gitgmbid": 10, "subtitle": "Step 5", "imgSrc": "switch-branch-from-UI", "subTitleDescription": "Or you could simply do it from the UI by clicking the path at the bottom left corner" },
    { "gitgmbid": 11, "subtitle": "Step 6 (If the new branch doesn't work and have to detele)", "imgSrc": "delete-branch", "subTitleDescription": "Switch back to master, and input: [git branch -D [branch name]] to delete the entire branch." },
    { "gitgmbid": 12, "subtitle": "Step 6 (If the new branch work and have to merge to master)", "imgSrc": "merged", "subTitleDescription": "Switch back to master, and input: [git merge [branch name]] to merge the branch to master. You could delete the branches after they merged to the master, if you would like to keep your repository clean." },
    { "gitgmbid": 13, "subtitle": "Step 6.1 (Handle conflict happen during merging)", "imgSrc": "branch-conflict", "subTitleDescription": "Sometimes merging files is not as smooth as above. When working with others, conflict in the code is common and cause merging issue. Below is an example on a conflict merging and how to resolve. On a new branch, modify the title and commit." },
    { "gitgmbid": 14, "subtitle": "Step 6.2 (Handle conflict happen during merging)", "imgSrc": "master-conflict", "subTitleDescription": "Switch back to master a and modify the title to other name." },
    { "gitgmbid": 15, "subtitle": "Step 6.3 (Handle conflict happen during merging)", "imgSrc": "conflict-appear", "subTitleDescription": "Input: [git merge [branch name]], a conflict appear, where the green line-Current Change is the change in master, and the blue line-incmoing change is the change from the branch." },
    { "gitgmbid": 16, "subtitle": "Step 6.4 (Handle conflict happen during merging)", "imgSrc": "resolve-conflict", "subTitleDescription": "To resolve the issue, there are four option buttons: Accept Current Change: System will apply the change from master (DEF); Accept Incoming Change: System will apply the change from branch (ABC); Accept Both Changes: System will apply changes from master as well as the branch (created two titles with two lines); Compare Changes: Compare changes from the UI in detail, see below:" },
    { "gitgmbid": 17, "subtitle": "Compare Conflict", "imgSrc": "compare-changes", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitMasterBranchesList.map(gitGitHubGitMasterBranchesList => (
          <div key={gitGitHubGitMasterBranchesList.gitgmbid}>
            <Content
              subtitle={gitGitHubGitMasterBranchesList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitMasterBranchesList.imgSrc + ".png"}
              imgAlt={gitGitHubGitMasterBranchesList.imgSrc}
              subTitleDescription={gitGitHubGitMasterBranchesList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
