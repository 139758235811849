import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function JavaScriptFunctions() {

  let javascriptFunctionList = [
    { "jsfid": 1, "name": "Build-In", "urlname": "build-in", "Description": "Checkout some pre-built function here!" },
    { "jsfid": 2, "name": "Customize", "urlname": "customize", "Description": "Checkout how to customize a function here!" },
    { "jsfid": 3, "name": "This", "urlname": "this", "Description": "Checkout what is \"this\" function here!" },
  ]

  return (
    <div>
      <h1>JavaScript Functions</h1><hr></hr>
      <div className="card-wrapper">{
        javascriptFunctionList.map(javascriptFunctionList => (
          <div key={javascriptFunctionList.jsfid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javascriptFunctionList.urlname + ".png"}
              imgAlt={javascriptFunctionList.urlname}
              title={<Link to={'/programming-language/javascript/functions/' + javascriptFunctionList.urlname}>{javascriptFunctionList.name}</Link>}
              description={javascriptFunctionList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
