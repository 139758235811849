import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonFrameworkDjangoTemplateApi() {

  let pythonFrameworkDjangoTemplateApiList = [
    { "pyfrdjtaid": 1, "name": "Variable", "urlname": "variable", "Description": ""},
    { "pyfrdjtaid": 2, "name": "Tag", "urlname": "tag", "Description": ""},
    { "pyfrdjtaid": 3, "name": "Template & Static Files", "urlname": "template-static-files", "Description": ""},
  ]


  return (
    <div>
      <h1>Template API</h1><hr></hr>
      <div className="card-wrapper">{
        pythonFrameworkDjangoTemplateApiList.map(pythonFrameworkDjangoTemplateApiList => (
          <div key={pythonFrameworkDjangoTemplateApiList.pyfrdjtaid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoTemplateApiList.urlname + ".png"}
              imgAlt={pythonFrameworkDjangoTemplateApiList.urlname}
              title={<Link to={'/programming-language/python/framework/django/template-api/' + pythonFrameworkDjangoTemplateApiList.urlname}>{pythonFrameworkDjangoTemplateApiList.name}</Link>}
              description={pythonFrameworkDjangoTemplateApiList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
