import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsInstallationOperation () {

  let mainTitle = "ReactJS Installation & Operation"
  let titleDescription = (<>First of all, please visit <a href="/integrated-development-environments/vscode/useful-packages">Useful packages</a> to install ReactES7 and NodeJS and open VS Code.</>);

  let advancedTopicsReactJsInstallationOperationList = [
    { "adrjioid": 1, "subtitle": "Step 1", "imgSrc": "step1-create-react", "subTitleDescription": "Open the terminal and use cd command to change to the directory you wish to start you project at." },
    { "adrjioid": 2, "subtitle": "Step 2", "imgSrc": "step2-create-react", "subTitleDescription": "Input [npx create-react-app [project name]] where react-project is my [project name]." },
    { "adrjioid": 3, "subtitle": "Step 3", "imgSrc": "step3-create-react", "subTitleDescription": "Now, you should be able to see the files installed in the directory you indicated in step 2. Now lets open these folders in VS Code and break it down!" },
    { "adrjioid": 4, "subtitle": "Step 4", "imgSrc": "step4-create-react", "subTitleDescription": "node_modules: Some build in packages installed by default, you can learn this in the nodeJS module; public: Some files that allow public access, e.g. html, images in your html etc.." },
    { "adrjioid": 5, "subtitle": "Step 5", "imgSrc": "step5-create-react", "subTitleDescription": "index.html: There should be a \"id=root\" in the file, this is the id that the component will point to." },
    { "adrjioid": 6, "subtitle": "Step 6", "imgSrc": "step6-create-react", "subTitleDescription": "App.js: A pre-built component, you can use this as the template to start develop; index.js: A pre-built foundation storing App.js and point to index.html; App.test.js: For testing purpose, just leave it there; reportWebVitals.js: For testing project running efficiency, just leave it there; package-lock.json & package.json: Store the information of packages, you can learn this in the nodeJS module." },
    { "adrjioid": 7, "subtitle": "Step 7", "imgSrc": "step7-create-react", "subTitleDescription": "Input [npm run start], in the terminal to run a built-in server with a url, this website is the pre-built project from React." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsInstallationOperationList.map(advancedTopicsReactJsInstallationOperationList => (
          <div key={advancedTopicsReactJsInstallationOperationList.adrjioid}>
            <Content
              subtitle={advancedTopicsReactJsInstallationOperationList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsInstallationOperationList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsInstallationOperationList.imgSrc}
              subTitleDescription={advancedTopicsReactJsInstallationOperationList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}