import React from 'react';
import { Card } from "./components/Card";
import { ContentTitle } from "./components/ContentTitle";
import { Link } from 'react-router-dom';

export default function Python() {

  let mainTitle = "Python"
  let titleDescription = (<>Please install <a href="https://www.python.org/">Python</a> in your local PC first. You could use VS code to start developing, and use command: python [file name] to execute the result.</>);


  let pythonList = [
    { "pyid": 1, "name": "Functions", "urlname": "functions", "Description": "In Python, a function is a block of organized, reusable code that performs a specific task. It is a way to group related statements together to perform a specific operation. Functions help in modularizing code, making it more readable, reusable, and easier to maintain." },
    { "pyid": 2, "name": "Modules", "urlname": "modules", "Description": "In Python, a module is a file containing Python definitions and statements. The file name is the module name with the suffix .py appended. Modules allow you to organize Python code into separate files, making your code more modular and manageable." },
    { "pyid": 3, "name": "Data Type", "urlname": "data-type", "Description": "In programming, a data type is an attribute of data that tells the compiler or interpreter how the programmer intends to use the data. Each programming language has its own set of data types, and Python is no exception. " },
    { "pyid": 4, "name": "Condition", "urlname": "condition", "Description": "In Python, conditions are used to control the flow of a program based on whether a certain expression evaluates to True or False. Conditional statements allow you to make decisions in your code, executing different blocks of code depending on the truth or falsity of a given condition." },
    { "pyid": 5, "name": "Loops", "urlname": "loops", "Description": "Loops in Python are used to repeatedly execute a block of code until a certain condition is met. There are two main types of loops in Python: for loops and while loops." },
    { "pyid": 6, "name": "Exception Handling", "urlname": "exception-handling", "Description": "Exception handling in Python allows you to gracefully handle errors and unexpected situations that might occur during the execution of your program. It helps prevent your program from crashing and provides a way to handle exceptional cases." },
    { "pyid": 7, "name": "Object & Class", "urlname": "object&class", "Description": "In Python, a class is a blueprint that defines the structure and behavior of objects. It encapsulates attributes (characteristics) and methods (functions) that instances of the class, called objects, will possess. Objects are specific instances created from a class, representing individual entities in the program. Through classes and objects, Python supports object-oriented programming principles, providing a way to organize and model code based on real-world entities and their interactions." },
    { "pyid": 8, "name": "Open", "urlname": "open", "Description": "The open() function in Python is used for opening and manipulating files. It is a built-in function that takes two arguments: the file name or path and the mode in which you want to open the file." },
    { "pyid": 9, "name": "Data Analytics", "urlname": "data-analytics", "Description": "Data analytics is the process of examining, cleaning, transforming, and modeling data to discover useful information, draw conclusions, and support decision-making. " },
    { "pyid": 10, "name": "Python Framework", "urlname": "framework", "Description": "Framework is a pre-built and reusable set of tools, libraries, conventions, and best practices that provide a structured way to develop and organize code for a particular type of software application. Frameworks aim to simplify and expedite the development process by offering a foundation on which developers can build their applications." },
  ]


  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div className="card-wrapper">{
        pythonList.map(pythonList => (
          <div key={pythonList.pyid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonList.urlname + ".png"}
              imgAlt={pythonList.urlname}
              title={<Link to={'/programming-language/python/' + pythonList.urlname}>{pythonList.name}</Link>}
              description={pythonList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
