import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesObject() {

  let mainTitle = "Kubernetes Objects"
  let titleDescription = "Kubernetes objects are like the building blocks of the Kubernetes universe. These are configurations or definitions that tell Kubernetes how applications, services, and other resources should behave. Think of them as the blueprints that guide Kubernetes in creating, scaling, and managing different components in your cluster. From pods and services to deployments and config maps, these objects are the language Kubernetes speaks to make your containerized applications dance in perfect harmony."

  let containerOrchestrationKubernetesObjectsList = [
    { "ctorchkuobid": 1, "subtitle": "Kubernetes objects", "imgSrc": "kubernetes-object", "subTitleDescription": "Kubernetes objects are persistent (means something will last even if there is a server failure or network attack) entities with a bundle of data that has an identity and a state. They also consist of two main fields - object spec and status. The object spec is provided by the user which dictates an object’s desired state. Status is provided by Kubernetes. This describes the current state of the object. Kubernetes works towards matching the current state to the desired state. To work with these objects, use the Kubernetes API directly with the client libraries, and the kubectl command-line interface, or both." },  
    { "ctorchkuobid": 2, "subtitle": "Labels and selectors", "imgSrc": "kubernetes-labels-and-selectors", "subTitleDescription": "Label selectors are the core grouping method in Kubernetes. They allow you to identify a set of objects. Namespaces provide a mechanism for isolating groups of resources within a single cluster. This is useful when teams share a cluster for cost-saving purposes or for maintaining multiple projects in isolation." },  
    { "ctorchkuobid": 3, "subtitle": "Namespaces", "imgSrc": "kubernetes-namespaces", "subTitleDescription": "Namespaces provide a mechanism for isolating groups of resources within a single cluster. This is useful when teams share a cluster for cost-saving purposes or for maintaining multiple projects in isolation. Namespaces are ideal when the number of cluster users is large. Examples of namespaces are kube-system, intended for system users and the default namespace used to hold users’ applications. There are different patterns of working with namespaces. There may be only one namespace for a user who works with one team which only has one project that is deployed into a cluster. Alternatively, there may be many teams or projects, or a lot of users with different needs, where additional namespaces may be created. Namespaces provide a scope for the names of objects. Each object must have a unique name for that resource type within that namespace." },  
    { "ctorchkuobid": 4, "subtitle": "Nodes & Pods", "imgSrc": "kubernetes-node-pod", "subTitleDescription": "A node in Kubernetes is akin to a server or virtual machine (VM), capable of hosting one or multiple pods. Pods, the smallest units in Kubernetes created by Job, house either a single (preferred) or multiple containers, allowing them to efficiently share computing resources within a single node. Each pod comes equipped with its own internal IP address (Cluster IP), ensuring communication within the pod. It's important to be aware that pods might face disruptions for various reasons, prompting Kubernetes to replace them with a new pod, complete with a fresh IP address." },  
    { "ctorchkuobid": 5, "subtitle": "Service (svc) & Node:port", "imgSrc": "kubernetes-service", "subTitleDescription": "Service (svc) addressing the headache caused by the ever-changing IP addresses of Pods. Placing a service between pods acts as a traffic manager (load-balancer), ensuring communication smoothly redirects to other robust pods in case of any disruption. Moreover, services can play the role of a bridge, facilitating communication beyond the node. Known as node:port, this allows seamless interaction between the inner and outer realms of a node, using the node's IP address as the conduit." },  
    { "ctorchkuobid": 7, "subtitle": "Ingress", "imgSrc": "kubernetes-ingress", "subTitleDescription": "When it comes to the big stage of a production environment, we prefer dancing with domains instead of juggling IP addresses. Enter Ingress, the maestro of external communication, offering a customizable symphony of access rules to various services and pods within the node. Ingress also throws in some fancy features like load balancing and SSL certificates, adding a touch of glamour to the production performance. It's like the VIP pass for your audience to smoothly navigate the Kubernetes spectacle." },  
    { "ctorchkuobid": 8, "subtitle": "ConfigMap & Secret", "imgSrc": "kubernetes-configmap-secret", "subTitleDescription": "When apps need to talk to databases in the Kubernetes world, we usually stash the database details like IP, username, and password in the app's config files. But when the database decides to change its address, it's messy and downtime-prone. Now, meet ConfigMap. It's like a chill storage space where you can keep those database details separate from the app. No more manual config file edits on nodes, where sensitive info will be stored in another object-secret." },  
    { "ctorchkuobid": 9, "subtitle": "Volume", "imgSrc": "kubernetes-volume", "subTitleDescription": "When pods are destroyed, so does the data inside. Volumes, let you stash your stuff on the local hard disk or some other drive, ensuring your data stays intact and doesn't vanish when the pod takes a bow." },  
    { "ctorchkuobid": 9, "subtitle": "Deployment & ReplicaSet", "imgSrc": "kubernetes-deployment", "subTitleDescription": "When crucial pods experience outage or a node demands an update, downtime becomes inevitable. A clever move is to create backups by copying a node into 2-3 ReplicaSets. With the magic of the deployment object, you can seamlessly switch to another robust node, ensuring a smooth transition without missing a beat. " },  
    { "ctorchkuobid": 10, "subtitle": "StatefulSet", "imgSrc": "kubernetes-statefulset", "subTitleDescription": "For applications that carry a status, especially critical ones like databases, maintaining consistency between the production and backup databases is paramount. In such scenarios, the go-to maestro is the StatefulSet, not the Deployment. StatefulSet takes the stage for applications like databases, caches, and message queues, ensuring a meticulous approach where each application maintains its consistency." },  
    { "ctorchkuobid": 11, "subtitle": "DaemonSet", "imgSrc": "kubernetes-daemonset", "subTitleDescription": "Pods are garbage collected when removed from a cluster. If you delete a DaemonSet, all Pods are removed. DaemonSets are ideally used for storage, logs, and monitoring nodes." },  
    { "ctorchkuobid": 12, "subtitle": "Service binding", "imgSrc": "kubernetes-service-binding", "subTitleDescription": "Service binding is the process needed to consume external Services or backing Services, including REST APIs, databases, and event buses in our applications. Service binding manages configuration and credentials for back-end Services while protecting sensitive data. In addition, Service binding makes Service credentials available to you automatically as a Secret. Service binding consumes the external Service by binding the application to a deployment. Then, the application code uses the credentials from the binding and calls the corresponding Service. Here you can see an architectural diagram that illustrates the binding of a Kubernetes Cluster to an external Service. Next, let's learn the steps required to bind the Service to your application." },  
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesObjectsList.map(containerOrchestrationKubernetesObjectsList => (
          <div key={containerOrchestrationKubernetesObjectsList.ctorchkuobid}>
            <Content
              subtitle={containerOrchestrationKubernetesObjectsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesObjectsList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesObjectsList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesObjectsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
