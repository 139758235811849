import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsCloudComputingAWSNetworkingNetworkingServices() {

  let mainTitle = "AWS networking services"
  let titleDescription = ""

  let advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList = [
    { "adawsntsid": 1, "subtitle": "Virtual Private network (VPN)", "imgSrc": "aws-vpn", "subTitleDescription": "With VPN, you still use the public network to access information over the internet, but the access is safeguarded. Is like you hire some bodyguards but you still use the same path or road any other user is using. In AWS, we use Customer gateway (On-premises)<>Virtual private gateway (AWS) in a VPN." },
    { "adawsntsid": 2, "subtitle": "Direct Connect", "imgSrc": "aws-direct-connect", "subTitleDescription": "With Direct Connect, you use a private or secret network to access information over the internet. Is like you walk in a private path that no other user can use. In AWS, we use Private virtual interface (On-premises)<>Direct connect gateway (AWS), and it takes 4-6 weeks to set up." },
    { "adawsntsid": 3, "subtitle": "Internet Gateway (IGW)", "imgSrc": "aws-internet-gateway", "subTitleDescription": "An Internet Gateway (IGW) is a horizontally scaled, redundant, and highly available VPC component that allows communication between instances in your VPC and the internet. It serves as a connection point to allow traffic to flow between your VPC and the internet." },
    { "adawsntsid": 4, "subtitle": "Direct Connect", "imgSrc": "aws-internet-gateway", "subTitleDescription": "" },
    { "adawsntsid": 5, "subtitle": "Direct Connect", "imgSrc": "aws-internet-gateway", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.map(advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList => (
          <div key={advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.adawsntsid}>
            <Content
              subtitle={advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.imgSrc + ".png"}
              imgAlt={advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.imgSrc}
              subTitleDescription={advancedTopicsCloudComputingAWSNetworkingNetworkingServicesList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
