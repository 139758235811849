import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function VisualStudioCode() {

  let vsCodeList = [
    { "vsid": 1, "name": "Overview", "urlname": "overview", "Description": "What is Visual Studio Code?"},
    { "vsid": 2, "name": "Useful Packages (Extensions)", "urlname": "useful-packages", "Description": "Check out these packages in Visual Studio Code (VS code) I found it very useful in the VS Code extension."},
    { "vsid": 3, "name": "Hot Key", "urlname": "hot-key", "Description": "Check out these hotkeys in Visual Studio Code (VS Code) that I've personally deemed indispensable."},
  ]


  return (
    <div>
      <h1>Visual Studio Code (VS code)</h1><hr></hr>
      <div className="card-wrapper">{
        vsCodeList.map(vsCodeList => (
          <div key={vsCodeList.ideid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + vsCodeList.urlname + ".png"}
              imgAlt={vsCodeList.urlname}
              title={<Link to={'/integrated-development-environments/vscode/' + vsCodeList.urlname}>{vsCodeList.name}</Link>}
              description={vsCodeList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}