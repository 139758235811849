import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoModelClass () {

  let mainTitle = "Django Model Class"
  let titleDescription = "There are 3 ways to create a model class in Django, from the admin site UI; from the powershell (coding); or create directly in the database UI. As the purpose of Django is to eliminate the opretation in database, I will introduce the powershell method to create a model class (table), and the admin site UI method to add info (rows)."

  let pythonFrameworkDjangoModelClassList = [
    { "pyfrdjmcid": 1, "subtitle": "Step 1 - Create a Model Class", "imgSrc": "django-create-model-class-vscode", "subTitleDescription": "Locate the model.py file in your app, remember, a single model class refer to one single table in the database. Here is an example to create a model class in Django, create a table without the need of SQL. However, to set attribute in the model class may require some database knowledge as you need to know the type of attribute you are setting in the database, for example: char, varchar, slug (char without space), image (Same as Text in database, require a pillow install), boolean, dateTime etc...You could also import the Meta class to set the ordering." },
    { "pyfrdjmcid": 2, "subtitle": "Step 2 - Migrate the Model Class", "imgSrc": "django-migrate-model-class", "subTitleDescription": "By inputting [python .\\manage.py makemigrations], a new file will be created under the migrations folder. But this doesn't migrate the model class to the database. Make migration is only used to capture the changes of the model, and generate the file (code) to capture the change." },
    { "pyfrdjmcid": 3, "subtitle": "Step 3 - Migrate the Model Class to database", "imgSrc": "django-migrate-model-class-todb", "subTitleDescription": "By inputting [python .\\manage.py migrate], the model class will be migrated to the database." },
    { "pyfrdjmcid": 4, "subtitle": "Step 4 - Verify in database (SQLiteStudio)", "imgSrc": "django-migrate-model-class-sqlite", "subTitleDescription": "Go to SQLiteStudio to check the newly created model class. The id is a default primary key auto created by database." },
    { "pyfrdjmcid": 5, "subtitle": "Step 5 - Login to admin site", "imgSrc": "django-admin-login", "subTitleDescription": "Login into admin site. Make sure the sever is running. If you forget how to do it, please visit Django Instllation & Operation." },
    { "pyfrdjmcid": 6, "subtitle": "Step 6 - Import the Post model class to amdin.py", "imgSrc": "django-modify-adminpy", "subTitleDescription": "Import the model class (Post) created in Step 1 into admin.py." },
    { "pyfrdjmcid": 7, "subtitle": "Step 7 - Refresh admin site", "imgSrc": "django-refresh-adminpy", "subTitleDescription": "Refresh your admin site and you will see the model class is showing." },
    { "pyfrdjmcid": 8, "subtitle": "Step 8 - Input info in the model", "imgSrc": "django-add-adminpy", "subTitleDescription": "Click into the Post model, and click [ADD POST] on the top right handed corner, then you will see the attribute you created in Step 1. Remember the body field have to be in html format." },
    { "pyfrdjmcid": 9, "subtitle": "Step 9 - Sample of model", "imgSrc": "django-sample-adminpy", "subTitleDescription": "I have preapre some sample posts here, but it is a bit hard to do sorting right? Now, go back to admin.py file to do some modifications." },
    { "pyfrdjmcid": 10, "subtitle": "Step 10 - Register model", "imgSrc": "django-register-adminpy", "subTitleDescription": "The screenshot showing above is another way to register model in admin site (replacing the code Step 6). You can customize the attributes on the columns defined in Step 1, add a filter base on the attributes, add search criteria on the search box, and add an ordering logic to overwrite to ordering set in Step 1 Meta." },
    { "pyfrdjmcid": 11, "subtitle": "Step 11 - Example of Step 10", "imgSrc": "django-register-adminpy-example", "subTitleDescription": "Here is the example of Step 10 modification." },
    { "pyfrdjmcid": 12, "subtitle": "Step 12 - Check uploaded document", "imgSrc": "django-upload-adminpy", "subTitleDescription": "Remember? If you have followed the module in Django Instllation & Operation module, I have defined a folder to stoe the uploaded files. The image uploaded in Step 8 have been stored here. If the documents uploaded by the user have the same file name, Django will assign an unique key follow by the file name." },
    { "pyfrdjmcid": 13, "subtitle": "Step 13 - Add another Model Class with foreign key relationship", "imgSrc": "django-newmodel-adminpy", "subTitleDescription": "Same as Sep 1, create another model class, where the new attribute \"type\" & \"user\" are the foregin keys of the two model classes (if you don't know what is a foregin key, please visit the database module). To indicate this in Django, add an attribute as \"type\" & \"user\" in the model class in Step 1 as shown above, referring one post can only have one type, but one type can have many posts. Then, same as Step 2-4, migrate the new changes, and remember to import the new module class in admin.py." },
    { "pyfrdjmcid": 14, "subtitle": "Step 14 - Query the data in Django", "imgSrc": "django-queryset-adminpy", "subTitleDescription": "I have created two types in the Type model class, which is call \"Food\" & \"Drink\", and added to my posts. Now, input [python.\\manage.pu shell], and input from blog.models import Post, Type to import the models. Assign a variable type to get the objects in Type model class where the id is 1, in my case which is Food. Now, you could input [type.type_posts.all()] to query all the posts that are in \"Food\" type, which is sample-3 & sample-1 in my case. See the QuerySet module to learn more about the QuerySet, and input [exit()] to exit the QuerySet mode." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoModelClassList.map(pythonFrameworkDjangoModelClassList => (
          <div key={pythonFrameworkDjangoModelClassList.pyfrdjmcid}>
            <Content
              subtitle={pythonFrameworkDjangoModelClassList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoModelClassList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoModelClassList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoModelClassList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
