import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesAutoscaling() {

  let mainTitle = "Kubernetes Autoscaling"
  let titleDescription = "ReplicaSets provide a good start for scaling, but you don’t always want 10 instances of your resource running. You should be able to scale as needed. Kubernetes autoscaling helps optimize resource usage and costs by automatically scaling a cluster in line with demand. Kubernetes enables autoscaling at two different layers: the cluster or node level and the pod level. Three types of autoscalers are available in Kubernetes: Horizontal Pod Autoscaler (or HPA), Vertical Pod Autoscaler (or VPA), and Cluster Autoscaler (or CA)."

  let containerOrchestrationKubernetesAutoscalingList = [
    { "ctorchkuasid": 1, "subtitle": "Horizontal Pod Autoscaler (HPA)", "imgSrc": "kubernetes-hpa", "subTitleDescription": "In Kubernetes, an HPA automatically updates a workload resource (like a deployment) by horizontally scaling the workload to match the demand. Horizontal scaling, or “scaling out,” automatically increases or decreases the number of running pods as application usage changes. An HPA uses a cluster operator that sets targets for metrics like CPU or memory utilization and the maximum and minimum desired number of replicas." },  
    { "ctorchkuasid": 2, "subtitle": "Vertical Pod Autoscaler (VPA)", "imgSrc": "kubernetes-vpa", "subTitleDescription": "Vertical scaling, or “scaling up,” refers to adding more resources to an existing machine. A VPA lets you scale a service vertically within a cluster. The cluster operator sets targets for metrics like CPU or memory utilization, similar to an HPA. The cluster then reconciles the size of the service’s pod or pods based on their current usage and the desired target." },  
    { "ctorchkuasid": 3, "subtitle": "Cluster Autoscaler (CA)", "imgSrc": "kubernetes-ca", "subTitleDescription": "A CA autoscales the cluster itself, increasing and decreasing the number of available nodes that pods can run on. Pods are autoscaled using HPA or VPA, but when the nodes themselves are overloaded with pods, you can use a CA to autoscale the nodes so that the pods can rebalance themselves across the cluster." },  
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesAutoscalingList.map(containerOrchestrationKubernetesAutoscalingList => (
          <div key={containerOrchestrationKubernetesAutoscalingList.ctorchkuasid}>
            <Content
              subtitle={containerOrchestrationKubernetesAutoscalingList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesAutoscalingList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesAutoscalingList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesAutoscalingList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
