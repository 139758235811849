import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonString () {

  let mainTitle = "Python String"
  let titleDescription = ""

  let pythonStringList = [
    { "pysid": 1, "subtitle": "String & Concatenation", "imgSrc": "python_string", "subTitleDescription": "String is a data type in python, it simply means text. You could use concatenation in python to concatenate two string variables. As shown above, using the second methond (with the f) can make your concatentaion more readable it is getting longer." },
    { "pysid": 2, "subtitle": "Excape Character (Print Special Character)", "imgSrc": "python_print special_character", "subTitleDescription": "As string variable is defined by using 2\"\" or 2'', if we want to print the \"\" as well, we need to add a pair of \\ between them, we call it excape character." },
    { "pysid": 3, "subtitle": "Length Function", "imgSrc": "python_length", "subTitleDescription": "Length function allows you to check the length of a string variable, the number indicating the number of index (or space), including the space betwwen two text." },
    { "pysid": 4, "subtitle": "Break Function", "imgSrc": "python_break", "subTitleDescription": "Break function is used to start a new line for variable, you could use either \"\\n\" or 3\"\" to break a variable." },
    { "pysid": 5, "subtitle": "Slice Function", "imgSrc": "python_slice", "subTitleDescription": "Silce is to print a certain index of a string variable, or even a list or dictionary. The position (index) of each letter start with 0. Please note that the final index in a range will not be printed." },
    { "pysid": 6, "subtitle": "Modification", "imgSrc": "python_modify", "subTitleDescription": "Above shows more modifcations you could do with a string, including upper/lower case and updating a string." },
    { "pysid": 7, "subtitle": "Formatting String", "imgSrc": "python_format_string", "subTitleDescription": "We can combine strings and numbers by using the format() method." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonStringList.map(pythonStringList => (
          <div key={pythonStringList.pysid}>
            <Content
              subtitle={pythonStringList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonStringList.imgSrc + ".png"}
              imgAlt={pythonStringList.imgSrc}
              subTitleDescription={pythonStringList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}