import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsOverview() {

  let mainTitle = "Cloud Computing"
  let titleDescription = "Imagine you're managing a coffee shop. Before even serving your first cup of joe, you're saddled with fixed costs like rent, utilities, and wages. Now, what if I told you there's a way to only pay for what directly contributes to your income? Picture this: when there are no customers, you aren't burdened with those fixed expenses like rent and labor. Sounds appealing, doesn't it? Well, that's precisely what cloud computing offers."

  let advancedTopicsDevOpsOverviewList = [
    { "adccovid": 1, "subtitle": "", "imgSrc": "cloud-computing", "subTitleDescription": "Cloud computing refers to the delivery of computing services—such as servers, storage, databases, networking, software, and analytics—over the internet (the cloud) to offer faster innovation, flexible resources, and economies of scale. In simpler terms, cloud computing allows users to access and use computing resources on-demand without the need for direct management of physical infrastructure." },
    { "adccovid": 2, "subtitle": "Cloud computing vs Traditional computing", "imgSrc": "csvsts", "subTitleDescription": "You pay a modest fixed fee along with variable costs based on your actual usage. No need to worry about investing in physical servers beyond your needs. With cloud computing, you benefit from low variable costs, rapid scalability, and robust accessibility to resources across different regions. Plus, you tap into significant economies of scale. Thanks to globalization, you also gain the perks of persistence, resilience, and high service availability. And for those moments when demand fluctuates, autoscaling ensures high elasticity, adapting seamlessly to your needs." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsOverviewList.map(advancedTopicsDevOpsOverviewList => (
          <div key={advancedTopicsDevOpsOverviewList.addoovid}>
            <Content
              subtitle={advancedTopicsDevOpsOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsOverviewList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsOverviewList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
