import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsDevelopment() {

  let mainTitle = "DevOps Development Type"
  let titleDescription = ""

  let advancedTopicsDevOpsDevelopmentList = [
    { "addodvid": 1, "subtitle": "Testing Levels & Environments", "imgSrc": "testing-levels", "subTitleDescription": "The Unit Tests is mainly for testing a single functionality; Integration Test is to test the fuctionality between a 2 or more units; The System Test is for testing the environment and Acceptance Test will be tested by end-users." },
    { "addodvid": 2, "subtitle": "Test Driven Development (TDD)", "imgSrc": "tdd", "subTitleDescription": "Test-Driven Development (TDD) means that your test case drives the design and development of your code. You write the tests for the code you wish you had, then you write the code to make them pass. Test-driven development (TDD) saves time by quickly identifying issues when adding or modifying features, boosting confidence and speed in coding. With TDD, refactoring becomes more efficient as tests ensure desired behavior, preventing future code breaks. Automated tests are crucial for DevOps pipelines to prevent pushing bugs to production prematurely." },
    { "addodvid": 3, "subtitle": "Behaviour Driven Development (BDD)", "imgSrc": "bdd", "subTitleDescription": "You can think of BDD is a user story (As a...I need...so that...).Behavior driven development (BDD), focuses on the behavior of the system as observed from the outside in. This is different from test driven development, which focuses on the minutia of how the system works inside. BDD is great for integration testing to see if all of the components are behaving together. Put another way, BDD is ensuring that you are building the right thing. Do you have the right set of capabilities and these behaviors? TDD is ensuring that you are building the thing right. Does each feature perform the task that it was intended to? A BDD can also be written in a socall - Gherkin syntax (Given that...And...When...Then...)." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsDevelopmentList.map(advancedTopicsDevOpsDevelopmentList => (
          <div key={advancedTopicsDevOpsDevelopmentList.addodvid}>
            <Content
              subtitle={advancedTopicsDevOpsDevelopmentList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsDevelopmentList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsDevelopmentList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsDevelopmentList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
