import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsMvp() {

  let mainTitle = "Minimum Viable Product"
  let titleDescription = ""

  let advancedTopicsDevOpsMvpList = [
    { "addomvpid": 1, "subtitle": "", "imgSrc": "minimum-viable-product", "subTitleDescription": "A Minimum Viable Product (MVP) is a version of a product with enough features to satisfy early customers and provide feedback for future development. The key idea behind an MVP is to validate assumptions and hypotheses about a product's market demand and viability with minimal effort and resources. It typically has basic features that address the core needs of the target audience, allowing the product to be launched quickly and refined based on real-world feedback. MVP development focuses on delivering value to users while minimizing development time and costs. It is ok to be failed, but the key is to learn from it." },
    { "addomvpid": 2, "subtitle": "", "imgSrc": "mvp", "subTitleDescription": "In the second example from above, although the client is not happy abpout the first product, the team know red is the color the client want. In the first instance, the customer got exactly what they asked for months prior because the development team was just following a plan. But in the second instance, the customer got exactly what they desired because they were working iteratively and interactively with the development team. In the end, you develop something that's a little bit different but it's closer to what the customer really wants. Giving the customer what they really want is the main purpose of delivering an MVP." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsMvpList.map(advancedTopicsDevOpsMvpList => (
          <div key={advancedTopicsDevOpsMvpList.addomvpid}>
            <Content
              subtitle={advancedTopicsDevOpsMvpList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsMvpList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsMvpList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsMvpList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
