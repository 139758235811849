import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationOverview() {

  let mainTitle = "Container Orchestration Overview"
  let titleDescription = "Everyone’s container journey starts with one container. However, things don’t stay this way for long. Over time, new applications are written, and projects are deployed globally to increase availability. That one initial container inevitably becomes several containers. And Initially, that growth is easy to handle. But soon it is overwhelming. Consider connecting, managing, and scaling hundreds or thousands of containers into a large application like a database or web app. This can easily get out of control. To create, scale, and manage large numbers of containers, container orchestration is needed."

  let containerOrchestrationOverviewList = [
    { "ctorochvid": 1, "subtitle": "Container Orchestration", "imgSrc": "container-orchestration", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationOverviewList.map(containerOrchestrationOverviewList => (
          <div key={containerOrchestrationOverviewList.ctorochvid}>
            <Content
              subtitle={containerOrchestrationOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationOverviewList.imgSrc + ".png"}
              imgAlt={containerOrchestrationOverviewList.imgSrc}
              subTitleDescription={containerOrchestrationOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
