import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsState () {

  let mainTitle = "ReactJS State"
  let titleDescription = "ReactJS State is one of the React Hook function. State refer to the information within an object/component, and ReactJS is reactive to the update/changes of the State, and trigger a re-render (update) of the component, and that why the framework calls React!"


  let advancedTopicsReactJsStateList = [
    { "adrjssid": 1, "subtitle": "Create a simple State", "imgSrc": "sample-state", "subTitleDescription": "Above is an example to show how state work: This is a function to create a button name \"change variable\", where the logic is to change the name \"fruit\" into \"react\" after clicking the button. But this is not going to work, if you use developer tools to check the value, eventhough the product have been changed to react, from the UI itself, the product is still showing fruit because the variable \"product\" is only a variable, but not a state." },
    { "adrjssid": 2, "subtitle": "", "imgSrc": "import-state", "subTitleDescription": "To do so, we have to import useState function from react, and modify the code to above." },
    { "adrjssid": 3, "subtitle": "Create a conditional rendering with State", "imgSrc": "conditional-rendering", "subTitleDescription": "Above is an example to show how it works: The code highlighted in yellow is the logic of the button, and the red is the condition."  },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsStateList.map(advancedTopicsReactJsStateList => (
          <div key={advancedTopicsReactJsStateList.adrjssid}>
            <Content
              subtitle={advancedTopicsReactJsStateList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsStateList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsStateList.imgSrc}
              subTitleDescription={advancedTopicsReactJsStateList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}