import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesRedHatOpenShiftOverview() {

  let mainTitle = "OpenShift Istio"
  let titleDescription = ""

  let containerOrchestrationKubernetesRedHatOpenShiftIstioList = [
    { "ctorochrhoiid": 1, "subtitle": "What is OpenShift Service Mesh?", "imgSrc": "red-hat-openshift-service-mesh", "subTitleDescription": "A service mesh is a dedicated layer for making service-to-service communication secure and reliable. Among other things, service meshes provide traffic management to control the flow of traffic between services, security to encrypt traffic between services, and observability of service behavior to troubleshoot and optimize applications." },
    { "ctorochrhoiid": 2, "subtitle": "What is OpenShift Istio?", "imgSrc": "red-hat-openshift-istio", "subTitleDescription": "Istio is a platform-independent service mesh often used on Kubernetes. In keeping with the definition of a service mesh, Istio exhibits these four concepts. First, there’s connection. Connection enables Istio to intelligently control the traffic between services in canary deployments, A/B tests, and other deployment models. Next is security. Istio secures services through authentication, authorization, and encryption. Istio also provides enforceability and provides control by enforcing policies across an entire fleet. And finally, Istio supports observability." },
    { "ctorochrhoiid": 3, "subtitle": "Istio features", "imgSrc": "red-hat-openshift-istio-features", "subTitleDescription": "Istio features Transport Security Layer encrypted communications between services in a cluster, combined with the appropriate authentication and authorization. Istio load balances traffic for different protocols, including HTTP, TCP, gRPC, and WebSocket traffic. Istio supports granular configuration of traffic flow, known as routing rules, and supports control with continuous retries, fault injection methods, and automatic failovers. Along with policies and API support for access controls, rate limits, and quotas. Istio also provides automatic monitoring, logging, and tracking of both inbound and outbound traffic. Istio is extensible and can handle a diverse range of deployment needs." },
    { "ctorochrhoiid": 4, "subtitle": "Istio Traffic Management", "imgSrc": "red-hat-openshift-istio-traffic", "subTitleDescription": "Istio performs traffic shifting by gradually migrating the traffic from one version of a microservice to other versions. So, say a team working on the ordering microservice has a new update to that microservice. The team begins by sending 5 percent of traffic to that second version. And over time, the team can increase this to 50 percent and eventually to 100 percent. Similarly, Istio request routing allows you to perform A/B tests and direct a particular version of a microservice to a subset of users while sending the original version to the remaining users. The process ensures that a new version increases user engagement or performance." },
    { "ctorochrhoiid": 5, "subtitle": "Istio Security", "imgSrc": "red-hat-openshift-istio-security", "subTitleDescription": "Istio also provides a variety of security measures for your microservices, including encryption. Istio defends from man-in-the-middle attacks by encrypting traffic between microservices. In addition, Istio makes it easy to implement policies for service access control so that services can only talk to the other required services. In this example, the UI service would be unable to directly communicate with the Inventory service, even if it tried." },
    { "ctorochrhoiid": 6, "subtitle": "Istio Observability", "imgSrc": "red-hat-openshift-istio-observability", "subTitleDescription": "Among other metrics, Istio provides service communication metrics. These metrics cover the four basic service monitoring needs: latency, traffic, errors, and saturation. For example, Istio provides metrics on request counts so that you can see how much traffic your requests are receiving and request duration, shown here in seconds so that you can find bottlenecks and ensure that there are prompt responses." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesRedHatOpenShiftIstioList.map(containerOrchestrationKubernetesRedHatOpenShiftIstioList => (
          <div key={containerOrchestrationKubernetesRedHatOpenShiftIstioList.ctorochrhoiid}>
            <Content
              subtitle={containerOrchestrationKubernetesRedHatOpenShiftIstioList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRedHatOpenShiftIstioList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesRedHatOpenShiftIstioList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesRedHatOpenShiftIstioList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
