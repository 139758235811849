import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonList () {

  let mainTitle = "Python List"
  let titleDescription = ""

  let pythonListList = [
    { "pyliliid": 1, "subtitle": "Create a List", "imgSrc": "python_list", "subTitleDescription": "A list is a group of variables with a [] sign containing them, and the variable inside a list is call element." },
    { "pyliliid": 2, "subtitle": "Slice a List from an index position", "imgSrc": "python_print_list", "subTitleDescription": "Slicing works for string variable, so does list. For -1 index, means the system will print the last variable within a list." },
    { "pyliliid": 3, "subtitle": "Unpack a List", "imgSrc": "python-unpack-list", "subTitleDescription": "We can also unpack a list by assigning variable to each element." },
    { "pyliliid": 4, "subtitle": "Modify a List", "imgSrc": "python_modify_list", "subTitleDescription": "To update an element in a list, we could modify an element by using the index position, add an element in the last position by using append, or add an element to certain position by using insert." },
    { "pyliliid": 5, "subtitle": "Remove an element in a List", "imgSrc": "python_remove_list", "subTitleDescription": "To remove an element in a list, we could remove an element by using the index position, remove the last element by using pop(), or remove an element in certain position by assigning an index in the pop." },
    { "pyliliid": 6, "subtitle": "Clear a List", "imgSrc": "python_clear_list", "subTitleDescription": "Use clear to empty a list." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonListList.map(pythonListList => (
          <div key={pythonListList.pyliliid}>
            <Content
              subtitle={pythonListList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonListList.imgSrc + ".png"}
              imgAlt={pythonListList.imgSrc}
              subTitleDescription={pythonListList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}