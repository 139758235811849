import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function JavaScriptThisFunctions() {


  let mainTitle = "JavaScript \"This\" Functions"
  let titleDescription = "In this module we will explore how to retrieve and update property (key) from an object, and how to create a method (function) in an object in JavaScript."

  let javaScriptThisFunctionsList = [
    { "jstid": 1, "subtitle": "Read properties from an object", "imgSrc": "read-object", "subTitleDescription": "There are two ways to do it: console.log([object name].[property name]) or console.log([object name][['property name']]) while this case is suitable when the property within an object have been assigned to another variable." },
    { "jstid": 2, "subtitle": "Update properties in an object", "imgSrc": "update-property", "subTitleDescription": "Simply input: [object name].[property name] = [update value]" },
    { "jstid": 3, "subtitle": "Create method (function) in an object", "imgSrc": "function-in-object", "subTitleDescription": "Method is the same as function, but we call it method when it is inside an object. From above code, you could see I created 3 methods within the object, and try to exceute it. The reason why this have to be input  \"this\" is because this can tell system that this property (blog) is pointing to the object \"user\". Without this , system will not know which property in an object you are pointing at. Please note that this can not be used in a functional expression (or an arrow function)." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        javaScriptThisFunctionsList.map(javaScriptThisFunctionsList => (
          <div key={javaScriptThisFunctionsList.jstid}>
            <Content
              subtitle={javaScriptThisFunctionsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javaScriptThisFunctionsList.imgSrc + ".png"}
              imgAlt={javaScriptThisFunctionsList.imgSrc}
              subTitleDescription={javaScriptThisFunctionsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
