import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function ProgrammingLanguage() {

  let programmingLanguageList = [
    { "plid": 1, "name": "HTML", "urlname": "html", "Description": "HTML (Hypertext Markup Language) is the standard markup language for creating and structuring web pages. It uses tags to define elements such as headings, paragraphs, links, and images, enabling browsers to interpret and display content on the internet." },
    { "plid": 2, "name": "CSS", "urlname": "css", "Description": "CSS (Cascading Style Sheets) is a styling language used to control the presentation and layout of web pages. It defines how HTML elements should appear, providing design and formatting for a visually appealing and consistent look across websites." },
    { "plid": 3, "name": "JavaScript", "urlname": "javascript", "Description": "JavaScript is a scripting language commonly used for web development. It enables interactive and dynamic content on websites, allowing for user interactions, animations, and real-time updates. It's a key technology for building modern web applications." },
    { "plid": 4, "name": "Python", "urlname": "python", "Description": "Python is a user-friendly programming language often used for various tasks, from making websites to analyzing data. It's easy to read and learn, making it popular for beginners and professionals in different fields like science and technology." },
    { "plid": 5, "name": "SQL", "urlname": "sql", "Description": "SQL, or Structured Query Language, is a programming language specifically designed for managing and manipulating relational databases. It's used to interact with databases, perform various operations such as querying data, updating records, inserting new data, and more. SQL provides a standardized way to communicate with databases, making it an essential tool for developers and database administrators." },
    { "plid": 6, "name": "PHP", "urlname": "php", "Description": "PHP is a server-side scripting language that is widely used for web development. It stands for Hypertext Preprocessor (a recursive acronym). PHP is embedded within HTML code and is executed on the server, generating dynamic web pages. It can interact with databases, handle form data, create cookies, and perform various other tasks to make web development more interactive and dynamic." },
  ]


  return (
    <div>
      <h1>Programming Language</h1>
      <div className="card-wrapper">{
        programmingLanguageList.map(programmingLanguageList => (
          <div key={programmingLanguageList.plid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + programmingLanguageList.urlname + ".png"}
              imgAlt={programmingLanguageList.name}
              title={<Link to={'/programming-language/' + programmingLanguageList.urlname}>{programmingLanguageList.name}</Link>}
              description={programmingLanguageList.Description}
            />
          </div>
        ))
      }
      </div>

    </div>
  );
}