import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerDockerOverview() {

  let mainTitle = "Docker Overview"
  let titleDescription = "Docker is a platform that enables developers to automate the deployment of applications inside lightweight, portable containers. Containers are standalone, executable packages that include everything needed to run a piece of software, including the code, runtime, libraries, and system tools"

  let containerDockerOverviewList = [
    { "ctdkovid": 1, "subtitle": "DockerFiles", "imgSrc": "docker_files", "subTitleDescription": " A Dockerfile is a text file that contains instructions needed to create an image. You can create a Dockerfile using any editor from the console or terminal. Let’s go over several of the essential instructions that Docker provides. A Dockerfile must always begin with a FROM instruction that defines a base image. Often the base image is from a public repository, like an operating system or a specific language like Go or Node.js. The RUN instruction executes commands. The CMD instruction defines a default command for execution. A Dockerfile should have only one CMD instruction. If the Dockerfile has several CMD instructions, only the last CMD instruction will take effect. Next, let’s learn about Docker images." },
    { "ctdkovid": 2, "subtitle": "Docker Image", "imgSrc": "docker_image", "subTitleDescription": "A Docker Image is a read-only template with instructions for creating a Docker container. The Dockerfile provides instructions to build the image. Each Docker instruction creates a new layer in the image. When you change the Dockerfile and rebuild the image, the Docker engine only rebuilds the changed layers. Images can share these layers, which saves a lot of disk space as well as network bandwidth when sending and receiving images. When you instantiate this image, you get a running container. At this point, a writeable container layer is placed on top of the read-only layers. The writeable layer is needed because containers are not immutable as images." },
    { "ctdkovid": 3, "subtitle": "Docker Image naming", "imgSrc": "docker_image_naming", "subTitleDescription": "Now let’s learn how images are named. An image name has a unique format that consists of three parts: the hostname, the repository, and the tag. The hostname identifies the image registry. A repository is a group of related container images. The tag provides information about a specific version or variant of an image. Consider the image name docker.io/ubuntu:18.04. The hostname docker.io refers to the Docker Hub registry. When using the Docker CLI, you can exclude the docker.io hostname. The repository name ubuntu indicates an Ubuntu image. Finally, the tag, shown here as 18.04, represents the installed Ubuntu version." },
    { "ctdkovid": 4, "subtitle": "Docker Container", "imgSrc": "docker_container", "subTitleDescription": " A Docker container is a runnable instance of an image. You can use the Docker API or CLI to create, start, stop, or delete an image. You can connect to multiple networks, attach storage to the container, or create a new image based on its current state. Docker keeps containers well isolated from each other and their host machine." },
    { "ctdkovid": 5, "subtitle": "Docker Network, Storage & Plugins", "imgSrc": "docker_network_storage_plugins", "subTitleDescription": "When using Docker, networks help isolate container communications. By default, data doesn’t persist when the container no longer exists. Docker uses volumes and bind mounts to persist data even after a container stops. Plugins, such as storage plugins, provide the ability to connect to external storage platforms." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerDockerOverviewList.map(containerDockerOverviewList => (
          <div key={containerDockerOverviewList.ctdkovid}>
            <Content
              subtitle={containerDockerOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerDockerOverviewList.imgSrc + ".png"}
              imgAlt={containerDockerOverviewList.imgSrc}
              subTitleDescription={containerDockerOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}