import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonTuple() {

  let mainTitle = "Tuple"
  let titleDescription = "Tuples are immutable, meaning that once a tuple is created, it's element cannot be changed, added, or removed. This contrasts with lists, which are mutable."

  let pythonTupleList = [
    { "pytuid": 1, "subtitle": "Create a Tuple", "imgSrc": "python_tuple", "subTitleDescription": "Tuple is indicated by adding ()." },
    { "pytuid": 2, "subtitle": "Tuple & Variable", "imgSrc": "python_tuple_comma", "subTitleDescription": "If you only have one element in a tuple, you must add a comma, otherwise system will detect that element as a normal variable." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonTupleList.map(pythonTupleList => (
          <div key={pythonTupleList.pytuid}>
            <Content
              subtitle={pythonTupleList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonTupleList.imgSrc + ".png"}
              imgAlt={pythonTupleList.imgSrc}
              subTitleDescription={pythonTupleList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}