import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoTemplateApiTemplate () {

  let mainTitle = "Template & Static Files"
  let titleDescription = ""

  let pythonFrameworkDjangoTemplateApiTemplateList = [
    { "pyfrdjtatemid": 1, "subtitle": "Base Template, Child Template & Blocks", "imgSrc": "django-tag", "subTitleDescription": "As mentioned in the Tag module, Django is similar to React (component & props), and we call them blocks in Django, allow user to use the base template for static content, and child template for rendering the dynamic content." },
    { "pyfrdjtatemid": 2, "subtitle": "Step 1", "imgSrc": "django-static-files", "subTitleDescription": "First of all, preapre a set of html, css, & js files. Move the static files/folders (css, js, image) into the static files folder created before in the apps. The base.html is the skeleton (parent) template." },
    { "pyfrdjtatemid": 3, "subtitle": "Step 2", "imgSrc": "django-block", "subTitleDescription": "Now, create some blocks for dynamic content. I use title & content as an example. Every block tag comes with an end block tag, and the parameters inside the block tag is the name of the block, while the content between the tag is the default content if the parameter is not defined." },
    { "pyfrdjtatemid": 4, "subtitle": "Step 3", "imgSrc": "django-base-static", "subTitleDescription": "Use above tags for static files (css, js) in the base.html. The code is {% static [path of the static file]%}" },
    { "pyfrdjtatemid": 5, "subtitle": "Step 4", "imgSrc": "django-child-template", "subTitleDescription": "Now, create another html under the template folder to act as the child template for the blocks (Blog Title & Content) created above by using the extend tag." },
    { "pyfrdjtatemid": 6, "subtitle": "Step 5", "imgSrc": "django-child-static", "subTitleDescription": "Do the same as in Step 3 on the static files (img)." },
    { "pyfrdjtatemid": 7, "subtitle": "Step 6", "imgSrc": "django-url-view", "subTitleDescription": "In normal pratices, the function within the url dispatcher is defined in the view function in the app. Import the view.py from the app in the url.py." },
    { "pyfrdjtatemid": 8, "subtitle": "Step 7", "imgSrc": "django-view-model", "subTitleDescription": "Remember the MTV pattern of Django? Now, we would like to load the data from the model class (database) we created before (Posts, Type) to be the dynamci content rather than update the content in the code." },
    { "pyfrdjtatemid": 9, "subtitle": "Step 8", "imgSrc": "django-child-for", "subTitleDescription": "Now, replace the static code with the attirbute in the model class to make it dynamic. If you have multiple contents, you could also use the for-loop function to render all content in the list of your model class." },
    { "pyfrdjtatemid": 10, "subtitle": "Step 9", "imgSrc": "django-post", "subTitleDescription": "Now, create one more html file (post.html) in the template folder, same as above from Step 2-6, make some blocks into the post.html. I also replaced all static content into dynamic content - the model class attributes created before." },
    { "pyfrdjtatemid": 11, "subtitle": "Step 10", "imgSrc": "django-post-url", "subTitleDescription": "Now, modify the url.py and view.py accordingly. I also change the view of home to a filter with showing published post only, for demonstrating some more use cases." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoTemplateApiTemplateList.map(pythonFrameworkDjangoTemplateApiTemplateList => (
          <div key={pythonFrameworkDjangoTemplateApiTemplateList.pyfrdjtatemid}>
            <Content
              subtitle={pythonFrameworkDjangoTemplateApiTemplateList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoTemplateApiTemplateList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoTemplateApiTemplateList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoTemplateApiTemplateList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
