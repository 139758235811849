import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitRevertRevertCommit() {

  let mainTitle = "Revert Commit"
  let titleDescription = "Revert commit is well-suited for removing unwanted changes made before by committing a new version."

  let gitGitHubGitRevertRevertCommitList = [
    { "gitgrcid": 1, "subtitle": "Step 1", "imgSrc": "created-3-files", "subTitleDescription": "First of all, let me quickly commit 3 versions, with 1 html, 1 css and 1 js file." },
    { "gitgrcid": 2, "subtitle": "Step 2", "imgSrc": "show-2-id", "subTitleDescription": "Now, I discovered that the css file is no longer needed, input:[git log] to show commit ID of created css, and copy the ID." },
    { "gitgrcid": 3, "subtitle": "Step 3", "imgSrc": "git-revert", "subTitleDescription": "Input: git revert [ID]." },
    { "gitgrcid": 4, "subtitle": "Step 4", "imgSrc": "check-revert", "subTitleDescription": "After enterted the command, although the css file have removed as shown below, the terminal console have asked us to provide a commit message before commit." },
    { "gitgrcid": 5, "subtitle": "Step 5", "imgSrc": "confirm-revert", "subTitleDescription": "Navigate to the [Scource Contorl] tab, an automatic generated message have been provided, click commit to confirm revert." },
    { "gitgrcid": 6, "subtitle": "Step 6", "imgSrc": "recheck-revert", "subTitleDescription": "You can input: git log to check the commit history, a new commit have been generated, and the version of created css still exists to prevent data loss." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitRevertRevertCommitList.map(gitGitHubGitRevertRevertCommitList => (
          <div key={gitGitHubGitRevertRevertCommitList.gitgrcid}>
            <Content
              subtitle={gitGitHubGitRevertRevertCommitList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitRevertRevertCommitList.imgSrc + ".png"}
              imgAlt={gitGitHubGitRevertRevertCommitList.imgSrc}
              subTitleDescription={gitGitHubGitRevertRevertCommitList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
