import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoQuerySet () {

  let mainTitle = "Django QuerySet"
  let titleDescription = ""

  let pythonFrameworkDjangoQuerySetList = [
    { "pyfrdjqsid": 1, "subtitle": "Query all objects & index", "imgSrc": "django-query-set-get-all", "subTitleDescription": "" },
    { "pyfrdjqsid": 2, "subtitle": "For loop to get all", "imgSrc": "django-query-set-for-loop", "subTitleDescription": "" },
    { "pyfrdjqsid": 3, "subtitle": "Order by", "imgSrc": "django-query-set-order-by", "subTitleDescription": "" },
    { "pyfrdjqsid": 4, "subtitle": "Get particular object", "imgSrc": "django-query-set-get-particular", "subTitleDescription": "Get function can return only one record" },
    { "pyfrdjqsid": 5, "subtitle": "Update particular object", "imgSrc": "django-query-set-update-particular", "subTitleDescription": "" },
    { "pyfrdjqsid": 6, "subtitle": "Filter", "imgSrc": "django-query-set-filter", "subTitleDescription": "" },
    { "pyfrdjqsid": 7, "subtitle": "Date filtering", "imgSrc": "django-query-set-date-filter", "subTitleDescription": "" },
    { "pyfrdjqsid": 7, "subtitle": "Chaining", "imgSrc": "django-query-set-chaining", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoQuerySetList.map(pythonFrameworkDjangoQuerySetList => (
          <div key={pythonFrameworkDjangoQuerySetList.pyfrdjqsid}>
            <Content
              subtitle={pythonFrameworkDjangoQuerySetList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoQuerySetList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoQuerySetList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoQuerySetList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
