import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsFileSystem() {

  let mainTitle = "NodeJS File System"
  let titleDescription = ""

  let advancedTopicsNodeJsFileSystemList = [
    { "adnjfsid": 1, "subtitle": "Create file & folder with .writeFile & .mkdir", "imgSrc": "create-file", "subTitleDescription": "By inputting above script, a new file 'demo.text' & new folder 'pic' is created by running the script." },
    { "adnjfsid": 2, "subtitle": "Read file with .readFile", "imgSrc": "read-file", "subTitleDescription": "By inputting above script, the content of 'demo.text' will be shown in the terminal." },
    { "adnjfsid": 3, "subtitle": "Delete file with .existsSync & .unlink", "imgSrc": "delete-file", "subTitleDescription": "Running the provided script will first verify the existence of the file 'demo.text' before proceeding with its deletion." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsFileSystemList.map(advancedTopicsNodeJsFileSystemList => (
          <div key={advancedTopicsNodeJsFileSystemList.adnjfsid}>
            <Content
              subtitle={advancedTopicsNodeJsFileSystemList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsFileSystemList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsFileSystemList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsFileSystemList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
