import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitHubOverview() {

  let mainTitle = "GitHub Overview"
  let titleDescription = ""

  let gitGitHubGitHubOverviewList = [
    { "gitghovid": 1, "subtitle": "Workflow in Git", "imgSrc": "github-workflow-in-git", "subTitleDescription": "Ok, I hope this doesn't scare you off. Let's break it down, by now, you should understand how Git works, if not, please visit Git Versioning. The diagram above highlighted the workflow in git, by using Visual Studio Code to add files into Index (or staging area), and commit (or take snapshot) into your local repository." },
    { "gitghovid": 2, "subtitle": "Workflow in GitHub - Part I", "imgSrc": "github-workflow", "subTitleDescription": "The highlighted area is where GitHub comes into play. Once files are committed to the local repository, they can be pushed to GitHub (Remote repository, like a drive). When your manager wants to review your implemented function, they can pull the files to their local repository, edit them in their workspace, like Visual Studio Codes, or any other. If a new teammate joins, they can use the clone function to clone files from the remote repository to their workspace, avoiding starting from scratch." },
    { "gitghovid": 3, "subtitle": "Workflow in GitHub - Part II", "imgSrc": "github-fork", "subTitleDescription": "Trust me, this is the last diagram I have (in this page). Let me introduce the last two function in GitHub to you, fork & pull request: Upstream: A template, or the latest version of the project which is live; Origin: Another name of the remote repository; Fork: To clone a copy to the origin, allows users to use it without starting from scratch; Pull request: Request the GitHub administrator to merge changes from the origin into upstream; Local: Local repository and workspace." },
    { "gitghovid": 4, "subtitle": "DevOps practice", "imgSrc": "github-devops", "subTitleDescription": "If you know DevOps & microservices, it is recommended to create a separate Git repository for every component (microservices) built. And do a pull request to someone (not yourself) when you want to do a merge to create a chance of code review on every merge." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitHubOverviewList.map(gitGitHubGitHubOverviewList => (
          <div key={gitGitHubGitHubOverviewList.gitghovid}>
            <Content
              subtitle={gitGitHubGitHubOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitHubOverviewList.imgSrc + ".png"}
              imgAlt={gitGitHubGitHubOverviewList.imgSrc}
              subTitleDescription={gitGitHubGitHubOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
