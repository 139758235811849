import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function IntegratedDevelopmentEnvironments() {

  let integratedDevelopmentEnvironmentsList = [
    { "ideid": 1, "name": "Visual Studio Code", "urlname": "vscode", "Description": "VS Code is a lightweight, open-source code editor developed by Microsoft, known for its versatility, efficiency, and extensive extension support for various programming languages." },
    { "ideid": 2, "name": "PyCharm", "urlname": "pyCharm", "Description": "PyCharm is a popular Integrated Development Environment (IDE) specifically designed for Python development. It is developed by JetBrains, the same company behind other popular IDEs such as IntelliJ IDEA, PhpStorm, and WebStorm." },
  ]


  return (
    <div>
      <h1>Integrated Development Environments</h1><hr></hr>
      <div className="card-wrapper">{
        integratedDevelopmentEnvironmentsList.map(integratedDevelopmentEnvironmentsList => (
          <div key={integratedDevelopmentEnvironmentsList.ideid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + integratedDevelopmentEnvironmentsList.urlname + ".png"}
              imgAlt={integratedDevelopmentEnvironmentsList.urlname}
              title={<Link to={'/integrated-development-environments/' + integratedDevelopmentEnvironmentsList.urlname}>{integratedDevelopmentEnvironmentsList.name}</Link>}
              description={integratedDevelopmentEnvironmentsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}