import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesRollingUpdate() {

  let mainTitle = "Kubernetes Rolling Update"
  let titleDescription = "Rolling updates are automated updates that occur on a scheduled basis. They roll out automated and controlled app changes across pods, Work with pod templates like deployments, and allow for rollback as needed."

  let containerOrchestrationKubernetesRollingUpdateList = [
    { "ctorchkuruid": 1, "subtitle": "All-at-once rollout/rollback", "imgSrc": "kubernetes-all-at-once-rollout", "subTitleDescription": "In an all-at-once rollout/rollback, all v1 objects must be removed before v2 objects can become active. Notice the time lag between deployment and pod updates." },  
    { "ctorchkuruid": 2, "subtitle": "One-at-a time rollout", "imgSrc": "kubernetes-one-at-time-rollout", "subTitleDescription": "In a one-at-a-time rollout/rollback, the update is staggered so user access is not interrupted. Pods will be replaced one by one and other ReplicaSet will still running during other pods rollout/rollback." },  
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesRollingUpdateList.map(containerOrchestrationKubernetesRollingUpdateList => (
          <div key={containerOrchestrationKubernetesRollingUpdateList.ctorchkuruid}>
            <Content
              subtitle={containerOrchestrationKubernetesRollingUpdateList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRollingUpdateList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesRollingUpdateList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesRollingUpdateList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
