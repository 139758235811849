import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOverview() {

  let mainTitle = "Container Overview"
  let titleDescription = "A container is a lightweight, standalone, and executable software package that includes everything needed to run a piece of software, including the code, runtime, libraries, and system tools."

  let containerOverviewList = [
    { "ctovid": 1, "subtitle": "What is container?", "imgSrc": "container_overview", "subTitleDescription": "Same as the shipping industry, logistics staff select container transport such as ships, trains, and trucks, based on the size and needs. Digital container technology is similar. Containers solve the problem of making software portable so that applications can run on multiple platforms. A container, powered by the containerization engine, is a standard unit of software that encapsulates the application code, runtime, system tools, system libraries, and settings necessary for programmers to build, ship, and run applications efficiently." },
    { "ctovid": 2, "subtitle": "Characteristics", "imgSrc": "container_characteristics", "subTitleDescription": "Virtual Machines (VMs) and containers share similarities despite their distinct purposes. Each VMs include their operating systems, leading to slow startup times and increased space usage. In contrast, containers run on the host machine without the need for an individual OS in each container, resulting in faster startup times, storage savings, and allow multiple containers operate in a single machine." },
    { "ctovid": 3, "subtitle": "Portability", "imgSrc": "container_portability", "subTitleDescription": "Containers are also programming language and IDE independent—whether you are running Python, Node, Java, or other languages." },
    { "ctovid": 4, "subtitle": "Benefit of container", "imgSrc": "benefit", "subTitleDescription": "Containers allow for swift movement of applications across various environments (e.g., laptop to testing, staging to production, physical to virtual, private or public cloud). They virtualize the operating system, ensuring lightweight, fast, isolated, portable, and secure execution, often with reduced memory requirements. Containers, hosting multiple instances on a single machine, facilitate rapid code deployment, irrespective of the platform (cloud, desktop, or on-premises) and operating system (Windows, Linux, or Mac OS). They are language and IDE independent, supporting diverse programming languages. The benefits of containers include automated application creation, reduced deployment time and costs, improved resource utilization, and seamless portability across environments, accommodating next-gen applications like microservices." },
    { "ctovid": 5, "subtitle": "Challenge", "imgSrc": "challenge", "subTitleDescription": "Server security can become an issue if its operating system is affected. Developers can become overwhelmed when managing thousands of containers. Converting monolithic legacy applications can be a complex process, and Developers can experience difficulty right-sizing containers for specific scenarios." },
    { "ctovid": 6, "subtitle": "Vendors", "imgSrc": "container_vendors", "subTitleDescription": "Docker is a robust platform and the most popular container platform today. Podman is a daemon-less container engine that is more secure than Docker. Developers often prefer LXC for data-intensive applications and operations. And, Vagrant offers the highest levels of isolation on the running physical machine." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOverviewList.map(containerOverviewList => (
          <div key={containerOverviewList.ctovid}>
            <Content
              subtitle={containerOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOverviewList.imgSrc + ".png"}
              imgAlt={containerOverviewList.imgSrc}
              subTitleDescription={containerOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}