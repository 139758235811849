import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitRevertResetCommit() {

  let mainTitle = "Reset Commit"
  let titleDescription = "Reset commit is to reset the project back to certain version by removing the commit files in between."

  let gitGitHubGitRevertResetCommitList = [
    { "gitgrecid": 1, "subtitle": "Step 1", "imgSrc": "", "subTitleDescription": "Again, first of all, let me quickly commit 4 versions, with 2 html, 1 css and 1 js files." },
    { "gitgrecid": 2, "subtitle": "Step 2", "imgSrc": "find-reset-id", "subTitleDescription": "Now, I discovered that starting from 2nd versions, all changes after that (css & js & 2nd html) is no longer needed, input: [git log] to show commit ID of the version that you would like to go back to, which is the first version in our case, and copy the ID." },
    { "gitgrecid": 3, "subtitle": "Step 3", "imgSrc": "prepare-reset", "subTitleDescription": "Input: git reset [ID]." },
    { "gitgrecid": 4, "subtitle": "Step 4", "imgSrc": "recheck-reset", "subTitleDescription": "After enterted the command, you can input: git log to see those versions and commit files do not exist anymore. You can still see the most recent saved files are still on the folder, it is because Git will retain the most recent saved files to prevent data loss." },
    { "gitgrecid": 5, "subtitle": "Step 5", "imgSrc": "hard-reset", "subTitleDescription": "To confirm revert back to that version, which is the 1st version in our case, input: [git reset --hard [ID]], and you can could see now the project have been reset back to the 1st version, without any data from 2nd to 4th version history." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitRevertResetCommitList.map(gitGitHubGitRevertResetCommitList => (
          <div key={gitGitHubGitRevertResetCommitList.gitgrecid}>
            <Content
              subtitle={gitGitHubGitRevertResetCommitList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitRevertResetCommitList.imgSrc + ".png"}
              imgAlt={gitGitHubGitRevertResetCommitList.imgSrc}
              subTitleDescription={gitGitHubGitRevertResetCommitList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
