import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function ContainerOrchestrationKubernetes() {

  let containerOrchestrationKubernetesList = [
    { "ctorchkuid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a Kubernetes?"},
    { "ctorchkuid": 2, "name": "Kubernetes Object", "urlname": "kubernetes-object", "Description": "Kubernetes objects are like the building blocks of the Kubernetes universe. These are configurations or definitions that tell Kubernetes how applications, services, and other resources should behave. Think of them as the blueprints that guide Kubernetes in creating, scaling, and managing different components in your cluster. From pods and services to deployments and config maps, these objects are the language Kubernetes speaks to make your containerized applications dance in perfect harmony."},
    { "ctorchkuid": 3, "name": "Kubernetes Architecture", "urlname": "kubernetes-architecture", "Description": "The Kubernetes architecture is the blueprint that orchestrates the ballet of containerized applications."},
    { "ctorchkuid": 4, "name": "Installation & Operation", "urlname": "installation-&-operation", "Description": "Install and operate Kubernetes here!"},
    { "ctorchkuid": 5, "name": "Autoscaling", "urlname": "autoscaling", "Description": "Kubernetes autoscaling helps optimize resource usage and costs by automatically scaling a cluster in line with demand."},
    { "ctorchkuid": 6, "name": "Rolling Update", "urlname": "rolling-update", "Description": "Rolling updates are automated updates that occur on a scheduled basis. They roll out automated and controlled app changes across pods, Work with pod templates like deployments, and allow for rollback as needed. "},
    { "ctorchkuid": 7, "name": "Red Hat Openshift", "urlname": "red-hat-openshift", "Description": "OpenShift® (developed and supported by Red Hat®) is an enterprise-ready Kubernetes container platform built for the hybrid cloud strategy. It provides a consistent application platform to manage hybrid, multicloud, and edge deployments."},
  ]


  return (
    <div>
      <h1>Kubernetes</h1><hr></hr>
      <div className="card-wrapper">{
        containerOrchestrationKubernetesList.map(containerOrchestrationKubernetesList => (
          <div key={containerOrchestrationKubernetesList.ctorchkuid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesList.urlname + ".png"}
              imgAlt={containerOrchestrationKubernetesList.urlname}
              title={<Link to={'/advanced-topics/container/container-orchestration/kubernetes/' + containerOrchestrationKubernetesList.urlname}>{containerOrchestrationKubernetesList.name}</Link>}
              description={containerOrchestrationKubernetesList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
