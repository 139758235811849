import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsOverview() {

  let mainTitle = "DevOps"
  let titleDescription = "In the past, we use waterfall approach to deploying. But this approach is difficult to rollback, costly when changes are needed, and each team (requirement, coding, testing) are seperated and have no idea what are the others are doing. With DevOps, these problem can be eliminated."

  let advancedTopicsDevOpsOverviewList = [
    { "addoovid": 1, "subtitle": "Application Evolution", "imgSrc": "devops-application-evolution", "subTitleDescription": "" },
    { "addoovid": 2, "subtitle": "Agility", "imgSrc": "devops-agility", "subTitleDescription": "DevOps is a culture, enable company enter market fast, test market reaction by using above three pillars of agility: DevOps for agility, microservices for small deployment, and containers for ephemeral runtime. Same as Agile, DevOps should organize their workers in a small, dedicated, ccross-functional team." },
    { "addoovid": 3, "subtitle": "DevOps is a Culture", "imgSrc": "devops-dimensions", "subTitleDescription": "DevOps isn't just a job title or team; it's a culture shift. Emphasizing culture is key—it requires a mindset change, promoting social coding and new work methods like small batch processes and test-driven development. Reorganization is often overlooked but crucial, as it directly impacts project outcomes. Additionally, revising measurement practices is essential, as what you measure shapes results." },
    { "addoovid": 4, "subtitle": "Mean time to Recovery - MTTR", "imgSrc": "failure", "subTitleDescription": "DevOps don't focus only on avoid failure (Mean time to Failure - MTTF), but focus more on recovery (Mean time to Recovery - MTTR) as failure is the only constant. Including retry pattern (retry connection due to network lantency), circuit breaker pattern, bulkhead pattern (like a auto-scaling or load balancer), or a chaos enginnering, also known as monkey testing, to kill the services and investigate." },
    { "addoovid": 5, "subtitle": "Actionable Metrics", "imgSrc": "actionable-metrics", "subTitleDescription": "We employ Actionable Metrics to gauge DevOps performance. For instance, consider if we only receive data indicating 10,000 daily hits (considered vanity metrics) to our website. Is this information valuable? Not quite, as it fails to guide our actions. However, with Actionable Metrics such as A/B testing, we can discern which version (A or B) yields more views or profit, providing actionable insights for decision-making. Here are some actionable metrics example: Mean lead time - the time between an idea to production; Release frequency; Change Failure rate; & MTTR mentioned above." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsOverviewList.map(advancedTopicsDevOpsOverviewList => (
          <div key={advancedTopicsDevOpsOverviewList.addoovid}>
            <Content
              subtitle={advancedTopicsDevOpsOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsOverviewList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsOverviewList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
