import React from "react";
import "./Content.css";

export const Content = ({
    subtitle,
    imgSrc,
    imgAlt,
    subTitleDescription,
}) => {
    return (
        <div>
            <h2>{subtitle}</h2>
            <h3>
                <img src={imgSrc} alt={imgAlt} /><br />
                {subTitleDescription}<br />
            </h3>
            <hr></hr>
        </div>
    );
};