import React from 'react';

export default function Css() {

  return (
    <div>
      <h1>CSS</h1>
    </div>
  );
}
