import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitOverview() {

  let mainTitle = "Git Overview"
  let titleDescription = ""

  let gitGitHubGitOverviewList = [
    { "gitgovid": 1, "subtitle": "", "imgSrc": "multiple-version-essay", "subTitleDescription": "Remember those college essay struggles? Submitting the first draft was a rarity, right? So, does this page familiar to you? Git will be your coding safety net. It's a distributed version control system, essentially a guardian angel for developers. Git tracks changes in your computer files, ensuring you have a history to fall back on. It's the go-to for coordinating collaborative code development during the software crafting process." },
    { "gitgovid": 2, "subtitle": "Mechanism of the Versioning Contorl in Git", "imgSrc": "general-workflow", "subTitleDescription": "Have you ever worked on something and require manager's review before submitting? Above is a simple example: 1. You [save as] your work and submit to your boss. 2. At the same time, you continute to work on your own document. 3. After certain period of time, your boss send the file back to you. 4. You merge the files and it become the final version." },
    { "gitgovid": 3, "subtitle": "Git workflow", "imgSrc": "git-workflow", "subTitleDescription": "Git does simliar things. In Git, we call that [save as] action as commit / taking a snapshot into committed files (a local repository). Let's explore into above diagram, user have to [add] the files into a staging area before commit / taking snapshots. This can be done by using the User Interface (UI) tools in Visual Studio Codes together with Command LineInterface (CLI) in Visual Studio Code's terminal!" },
    { "gitgovid": 4, "subtitle": "How often should I \"take a snapshot\"?", "imgSrc": "snapshot", "subTitleDescription": "Every hour? Every day? Or Every changes? The ideal frequency for taking snapshots varies based on your specific use cases. Avoid excessive snapshotting, such as every minute or for every line change, as it complicates version tracking. Conversely, too infrequent snapshots may render your project irretrievable when necessary." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitOverviewList.map(gitGitHubGitOverviewList => (
          <div key={gitGitHubGitOverviewList.gitgovid}>
            <Content
              subtitle={gitGitHubGitOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitOverviewList.imgSrc + ".png"}
              imgAlt={gitGitHubGitOverviewList.imgSrc}
              subTitleDescription={gitGitHubGitOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
