import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsDevOpsCICD() {

  let advancedTopicsDevOpsCICDList = [
    { "addociid": 1, "name": "Continuous Integration", "urlname": "continuous-integration", "Description": "" },
    { "addociid": 2, "name": "Continuous Delivery", "urlname": "continuous-delivery", "Description": "" },
  ]


  return (
    <div>
      <h1>Continuous Integration and Continuous Delivery</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsDevOpsCICDList.map(advancedTopicsDevOpsCICDList => (
          <div key={advancedTopicsDevOpsCICDList.addociid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsCICDList.urlname + ".png"}
              imgAlt={advancedTopicsDevOpsCICDList.urlname}
              title={<Link to={'/advanced-topics/DevOps/continuous-integration-and-continuous-delivery/' + advancedTopicsDevOpsCICDList.urlname}>{advancedTopicsDevOpsCICDList.name}</Link>}
              description={advancedTopicsDevOpsCICDList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}