import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsNodeJs() {

  let advancedTopicsNodeJsList = [
    { "adnjid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a NodeJS?" },
    { "adnjid": 2, "name": "File System", "urlname": "file-system", "Description": "The FileSystem in NodeJS is a built in tool that allows user to Create/Read/Remove files by JavaScript." },
    { "adnjid": 3, "name": "Server", "urlname": "server", "Description": "Server in NodeJS is a built in tool that allows user to host server by JavaScript." },
    { "adnjid": 4, "name": "NPM", "urlname": "npm", "Description": "Node Package Manager (NPM) is the world's largest Software Registry. Containing multiple useful packages!" },
    { "adnjid": 4, "name": "Express", "urlname": "express", "Description": "Express is a web framework and a package available in NPM for creating server, allow user to manage their code easier!" },
    { "adnjid": 4, "name": "Middleware", "urlname": "middleware", "Description": "Middleware is the operation betwwen the request fro client and response from server." },
  ]


  return (
    <div>
      <h1>NodeJs</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsNodeJsList.map(advancedTopicsNodeJsList => (
          <div key={advancedTopicsNodeJsList.adnjid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsList.urlname + ".png"}
              imgAlt={advancedTopicsNodeJsList.urlname}
              title={<Link to={'/advanced-topics/nodejs/' + advancedTopicsNodeJsList.urlname}>{advancedTopicsNodeJsList.name}</Link>}
              description={advancedTopicsNodeJsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}