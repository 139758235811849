import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerDockerInstallationOperation () {

  let mainTitle = "Docker Installation & Operation"
  let titleDescription = "Docker is a platform that enables developers to automate the deployment of applications inside lightweight, portable containers. Containers are standalone, executable packages that include everything needed to run a piece of software, including the code, runtime, libraries, and system tools."

  let containerDockerInstallationOperationList = [
    { "ctdkioid": 1, "subtitle": "Step 1 - Install Docker", "imgSrc": "docker", "subTitleDescription": "Please visit Docker official site to install Docker." },
    { "ctdkioid": 2, "subtitle": "Step 2 - Install Docker in VS code", "imgSrc": "docker-vs-code", "subTitleDescription": "Install Docker tools in VS code." },
    { "ctdkioid": 3, "subtitle": "Step 3 - Create a Docker file", "imgSrc": "docker-create-file", "subTitleDescription": "Create a file and name it as [Dockerfile], please note that this name is unchangeable and it is a official standard." },
    { "ctdkioid": 4, "subtitle": "Step 4 - Input the code to config the DockerFile", "imgSrc": "docker-dockerfile", "subTitleDescription": "Configure the Dockerfile as shown above. Ensure you install (FROM) the correct package, e.g. python for python coding, node (NodeJs) for JavScript." },
    { "ctdkioid": 5, "subtitle": "Step 5 - Input the docker build command on the terminal", "imgSrc": "docker-build-command", "subTitleDescription": "[docker build -t] is the docker command for building docker image; [print-python] is the image name & the tag after [:] indicate the version name which is non-mandatory; [.] indicates the current directory. Before execute the command, please make sure you started (run) the docker engine after installation in step 1." },
    { "ctdkioid": 6, "subtitle": "Step 6 - List the image you have", "imgSrc": "docker-image-ls", "subTitleDescription": "Input [docker image ls], all the image in your local will be shown with details." },
    { "ctdkioid": 7, "subtitle": "Step 7 - List the image in the Docker UI", "imgSrc": "docker-image-ls-UI", "subTitleDescription": "You could also check the image from the docker UI." },
    { "ctdkioid": 8, "subtitle": "Step 8 - Run the container", "imgSrc": "docker-run-container", "subTitleDescription": "You could run the container now." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerDockerInstallationOperationList.map(containerDockerInstallationOperationList => (
          <div key={containerDockerInstallationOperationList.ctdkioid}>
            <Content
              subtitle={containerDockerInstallationOperationList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerDockerInstallationOperationList.imgSrc + ".png"}
              imgAlt={containerDockerInstallationOperationList.imgSrc}
              subTitleDescription={containerDockerInstallationOperationList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}