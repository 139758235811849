import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonSet() {

let mainTitle = "Set"
let titleDescription = "A set is an unordered collection of unique elements."

let pythonSetList = [
  { "pysetid": 1, "subtitle": "Create a Set", "imgSrc": "python_set", "subTitleDescription": "The element within a set is unique, duplicates will only be printed once as shown above. You could also change a list to a set by using the changing data type fucntion." },
  { "pysetid": 2, "subtitle": "Modify a Set", "imgSrc": "python_modify_set", "subTitleDescription": "Above are some functions for you to add element into a set." },
  { "pysetid": 2, "subtitle": "Check a Set", "imgSrc": "python_check_set", "subTitleDescription": "Above are some function for you to operate one or more set." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonSetList.map(pythonSetList => (
        <div key={pythonSetList.pysetid}>
          <Content
            subtitle={pythonSetList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonSetList.imgSrc + ".png"}
            imgAlt={pythonSetList.imgSrc}
            subTitleDescription={pythonSetList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}