import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsDevOps() {

  let advancedTopicsNodeJsList = [
    { "addoid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a DevOps?" },
    { "addoid": 2, "name": "Social Coding", "urlname": "social-coding", "Description": "What is a social coding?" },
    { "addoid": 3, "name": "Minimum Viable Product", "urlname": "minimum-viable-product", "Description": "What is a MVP?" },
    { "addoid": 4, "name": "Test or Behavior Driven", "urlname": "development", "Description": "What is a Test or Behavior Driven Development?" },
    { "addoid": 5, "name": "Auto-Testing with Nose", "urlname": "auto-testing-with-nose", "Description": "Check out how to do a auto-tesing with Nose!" },
    { "addoid": 6, "name": "CI/CD", "urlname": "continuous-integration-and-continuous-delivery", "Description": "What is a CI/CD?" },
  ]


  return (
    <div>
      <h1>DevOps</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsNodeJsList.map(advancedTopicsNodeJsList => (
          <div key={advancedTopicsNodeJsList.addoid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsList.urlname + ".png"}
              imgAlt={advancedTopicsNodeJsList.urlname}
              title={<Link to={'/advanced-topics/devops/' + advancedTopicsNodeJsList.urlname}>{advancedTopicsNodeJsList.name}</Link>}
              description={advancedTopicsNodeJsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}