import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonDataAnalytics() {

  let pythonDataAnalyticsList = [
    { "pydaid": 1, "name": "Pandas", "urlname": "pandas", "Description": "Pandas is a popular open-source data manipulation and analysis library for Python. It provides data structures for efficiently handling large datasets and tools for data cleaning, exploration, and analysis." },
    { "pydaid": 2, "name": "NumPy", "urlname": "numpy", "Description": "NumPy, short for Numerical Python, is a powerful open-source library for numerical computing in Python. It provides support for large, multi-dimensional arrays and matrices, along with a collection of mathematical functions to operate on these arrays efficiently." },
  ]

  return (
    <div>
      <h1>Python Data Analytics</h1><hr></hr>
      <div className="card-wrapper">{
        pythonDataAnalyticsList.map(pythonDataAnalyticsList => (
          <div key={pythonDataAnalyticsList.pydaid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonDataAnalyticsList.urlname + ".png"}
              imgAlt={pythonDataAnalyticsList.urlname}
              title={<Link to={'/programming-language/python/data-analytics/' + pythonDataAnalyticsList.urlname}>{pythonDataAnalyticsList.name}</Link>}
              description={pythonDataAnalyticsList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
