import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoOverview () {

  let mainTitle = "Django Overview"
  let titleDescription = ""

  let pythonFrameworkDjangoOverviewList = [
    { "pyfrdjovid": 1, "subtitle": "What is Django?", "imgSrc": "django-overview", "subTitleDescription": "Django is  different from Flask as Django enable both frontend and backend functionality with a database in between." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoOverviewList.map(pythonFrameworkDjangoOverviewList => (
          <div key={pythonFrameworkDjangoOverviewList.pyfrdjovid}>
            <Content
              subtitle={pythonFrameworkDjangoOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoOverviewList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoOverviewList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
