import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonCustomzieFunctions() {

let mainTitle = "Python Customize Functions"
let titleDescription = ""

let pythonCustomizeFunctionsList = [
  { "pycuid": 1, "subtitle": "Normal Customize Function", "imgSrc": "python_custom_function", "subTitleDescription": "Use def to create a function." },
  { "pycuid": 2, "subtitle": "Customize Function with parameter", "imgSrc": "python_custom_function_with_parameter", "subTitleDescription": "Same as JavaScript, use parameter to make your function more flexible." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonCustomizeFunctionsList.map(pythonCustomizeFunctionsList => (
        <div key={pythonCustomizeFunctionsList.pycuid}>
          <Content
            subtitle={pythonCustomizeFunctionsList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonCustomizeFunctionsList.imgSrc + ".png"}
            imgAlt={pythonCustomizeFunctionsList.imgSrc}
            subTitleDescription={pythonCustomizeFunctionsList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}

