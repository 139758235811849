import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoTemplateApiVariable () {

  let mainTitle = "Variable"
  let titleDescription = ""

  let pythonFrameworkDjangoTemplateApiVariableList = [
    { "pyfrdjtavid": 1, "subtitle": "", "imgSrc": "django-variable", "subTitleDescription": "" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoTemplateApiVariableList.map(pythonFrameworkDjangoTemplateApiVariableList => (
          <div key={pythonFrameworkDjangoTemplateApiVariableList.pyfrdjtavid}>
            <Content
              subtitle={pythonFrameworkDjangoTemplateApiVariableList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoTemplateApiVariableList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoTemplateApiVariableList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoTemplateApiVariableList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
