import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function GitGitHubGit() {

  let gitGitHubGitList = [
    { "gitgid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a Git?"},
    { "gitgid": 2, "name": "Git Versioning", "urlname": "versioning", "Description": "With versioining, Git allows developer to record the history (who made what changes at what time) of the file."},
    { "gitgid": 3, "name": "Git Ignore", "urlname": "ignore", "Description": "At times, we might generate files for logging purposes or create files that contain private or confidential information, which we prefer not to disclose or share with the public. Git ignore (.gitignore) is coming into play to rescue."},
    { "gitgid": 4, "name": "Git Revert", "urlname": "revert", "Description": "From now, you should now how to commit files withh Git. Now you may ask, what should I do if I want to revert back the changes in previous version? Now introduce ways to you: Checkout commit: The safetest way to check previous version. Revert commit: A safe way to revert and replace versions. Reset commit: The safetest way to check previous version."},
    { "gitgid": 5, "name": "Master & Branches", "urlname": "master&branches", "Description": "In Git, the \"master\" branch is the default main branch for stable code. Branches are separate lines of development where changes can be made independently. Developers work on features or fixes in branches before merging them into the master branch. Branches enable parallel development and organized collaboration."},
    { "gitgid": 6, "name": "Git Command List", "urlname": "command", "Description": "Checkout some useful git command!"},
  ]


  return (
    <div>
      <h1>Git</h1><hr></hr>
      <div className="card-wrapper">{
        gitGitHubGitList.map(gitGitHubGitList => (
          <div key={gitGitHubGitList.gitgid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitList.urlname + ".png"}
              imgAlt={gitGitHubGitList.urlname}
              title={<Link to={'/git&github/git/' + gitGitHubGitList.urlname}>{gitGitHubGitList.name}</Link>}
              description={gitGitHubGitList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
