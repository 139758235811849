import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonOperators() {

let mainTitle = "Operators & Boolean"
let titleDescription = "In Python, a Boolean is a data type that represents truth values. It has two possible values: True and False. Booleans are commonly used in programming for making decisions and controlling the flow of code through conditional statements."

let pythonOperatorsList = [
  { "pyopid": 1, "subtitle": "Comparison Operators", "imgSrc": "python_comparison_operators", "subTitleDescription": "Comparison operators are used to compare two values." },
  { "pyopid": 2, "subtitle": "Logical Operators", "imgSrc": "pyhton_logical_operators", "subTitleDescription": "Logical operators are used to combine conditional statements." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonOperatorsList.map(pythonOperatorsList => (
        <div key={pythonOperatorsList.pyopid}>
          <Content
            subtitle={pythonOperatorsList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonOperatorsList.imgSrc + ".png"}
            imgAlt={pythonOperatorsList.imgSrc}
            subTitleDescription={pythonOperatorsList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}