import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonWhileLoop() {

let mainTitle = "While Loop"
let titleDescription = ""

let pythonWhileLoopList = [
  { "pywlid": 1, "subtitle": "Create a s simple while loop function", "imgSrc": "python_while_loop", "subTitleDescription": "While loop can be used when you want to perform multiple duplicated tasks. Above is an example to print \"Hi\" until variable num >=10. Each time the \"Hi\" was printed, system will add the variable num by 1 and check the condition again and exceute the loop if the result is true, until it become false. Which means, if you didn't add num by 1 after prinitng \"Hi\", this loop will never end." },
]

return (
  <div>
    <ContentTitle
      mainTitle={mainTitle}
      titleDescription={titleDescription}
    />
    <div>{
      pythonWhileLoopList.map(pythonWhileLoopList => (
        <div key={pythonWhileLoopList.pywlid}>
          <Content
            subtitle={pythonWhileLoopList.subtitle}
            imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonWhileLoopList.imgSrc + ".png"}
            imgAlt={pythonWhileLoopList.imgSrc}
            subTitleDescription={pythonWhileLoopList.subTitleDescription}
          />
        </div>
      ))
    }
    </div>
  </div>
);
}