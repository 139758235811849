import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsSocialCoding() {

  let mainTitle = "Social Coding"
  let titleDescription = ""

  let advancedTopicsDevOpsSocialCodingList = [
    { "addosoid": 1, "subtitle": "Social Coding", "imgSrc": "social-coding", "subTitleDescription": "Social coding means to share your code within the company so that other can use or build base on your code to reduce time and increase knowledge sharing, rather than hidding your code to take the peronal credit. As the repository have owner indicated, the owner will still get the credit while with other developers keep improving it." },
    { "addosoid": 2, "subtitle": "Source code management (SCM)", "imgSrc": "scm", "subTitleDescription": "Source code management (SCM), also known as version control, like Git & GitHub, is the process of tracking changes to source code throughout its development lifecycle. This facilitates the ability to revert to previous versions in case of errors and enables collaboration among multiple developers working on different aspects of the code, merging their changes into a unified source. SCM tools, alternatively referred to as version control systems (VCSs), are utilized by programmers to facilitate this management process. These systems can be either centralized or distributed. In a centralized SCM, the code repository and version history are stored centrally, with developers checking out portions of the code to work on and committing changes back to the central repository. Builds are typically conducted at the central repository since it houses the entirety of the codebase. Conversely, distributed SCMs provide each developer with a local clone of the entire repository, allowing for local builds as each developer possesses the complete code locally.    " },
    { "addosoid": 3, "subtitle": "Pair programming", "imgSrc": "pair-programming", "subTitleDescription": "Pair priogramming means two programmers work on a single workstation, one act as the driver (typing) and the another one act as the navugator (reviewing), and swap role every 20 minutes. Although it seems using twice resource for a single job, it increase the code quality, defects can be found earlier and allowing skills transfer." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsSocialCodingList.map(advancedTopicsDevOpsSocialCodingList => (
          <div key={advancedTopicsDevOpsSocialCodingList.addosoid}>
            <Content
              subtitle={advancedTopicsDevOpsSocialCodingList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsSocialCodingList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsSocialCodingList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsSocialCodingList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
