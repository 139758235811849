import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsReactJsUseEffect () {

  let mainTitle = "ReactJS useEffect"
  let titleDescription = "useEffect can help us to deteremine when the data should be render (re-render)."


  let advancedTopicsReactJsUseEffectList = [
    { "adrjsueid": 1, "subtitle": "useEffect without 2nd parameter", "imgSrc": "useEffect_no_para", "subTitleDescription": "First, we have to import useEffect from react. the first parameter of useEffect is a callback function. If we didn't put any parameter in a useEffect, the component will trigger useEffect callback function everytime the current component render (re-render), e.g every reload of the webpage, state change in a component." },
    { "adrjsueid": 1, "subtitle": "useEffect with a 2nd parameter(dependency array)", "imgSrc": "dependency_array", "subTitleDescription": "If a 2nd parameter was input, it must be an array, we call it dependency array. If the array is empty, the component will trigger useEffect callback function only at the first time the component render. If a variable was input on the dependency array, the component will trigger useEffect callback function at the first time the component render, and render if the state of this variable render." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsReactJsUseEffectList.map(advancedTopicsReactJsUseEffectList => (
          <div key={advancedTopicsReactJsUseEffectList.adrjsueid}>
            <Content
              subtitle={advancedTopicsReactJsUseEffectList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsReactJsUseEffectList.imgSrc + ".png"}
              imgAlt={advancedTopicsReactJsUseEffectList.imgSrc}
              subTitleDescription={advancedTopicsReactJsUseEffectList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}