import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoStartApp () {

  let mainTitle = "Start an App"
  let titleDescription = "";

  let pythonFrameworkDjangoStartAppList = [
    { "pyfrdjsaid": 1, "subtitle": "Step 1 - Start an App", "imgSrc": "django-start-app", "subTitleDescription": "Input [python .\\manage.py startapp [app name]], and input the app name as you like." },
    { "pyfrdjsaid": 2, "subtitle": "Step 2 - Add app to setting.py", "imgSrc": "django-add-app-setting", "subTitleDescription": "Remember to add the app you just created into the setting.py." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoStartAppList.map(pythonFrameworkDjangoStartAppList => (
          <div key={pythonFrameworkDjangoStartAppList.pyfrdjsaid}>
            <Content
              subtitle={pythonFrameworkDjangoStartAppList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoStartAppList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoStartAppList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoStartAppList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
