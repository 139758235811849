import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PyhtonDataStructure() {

  let pythonDataStructureList = [
    { "pydsid": 1, "name": "List", "urlname": "list", "Description": "" },
    { "pydsid": 2, "name": "Dictionary", "urlname": "dictionary", "Description": "" },
    { "pydsid": 3, "name": "Tuple", "urlname": "tuple", "Description": "" },
    { "pydsid": 4, "name": "Set", "urlname": "set", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Functions</h1><hr></hr>
      <div className="card-wrapper">{
        pythonDataStructureList.map(pythonDataStructureList => (
          <div key={pythonDataStructureList.pydsid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonDataStructureList.urlname + ".png"}
              imgAlt={pythonDataStructureList.urlname}
              title={<Link to={'/programming-language/python/data-type/data-structure/' + pythonDataStructureList.urlname}>{pythonDataStructureList.name}</Link>}
              description={pythonDataStructureList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
