import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsAutoTestingWithNose() {

  let mainTitle = "Auto Testing with Nose"
  let titleDescription = "Automated testing is essential in DevOps for its speed, efficiency, and consistency. By automating tests, developers can rapidly execute them, ensuring early bug detection and facilitating continuous integration and deployment. Automation also enables consistent testing procedures and scalability, crucial for handling the growing complexity of software systems. Additionally, automated tests provide immediate feedback to developers, promoting a culture of quality and enabling faster iteration cycles. Overall, automated testing plays a pivotal role in DevOps by enhancing the software development process, improving reliability, and accelerating time-to-market."

  let advancedTopicsDevOpsAutoTestingWithNoseList = [
    { "addonoseid": 1, "subtitle": "Step 1", "imgSrc": "triangle-area", "subTitleDescription": "First, I have created a simple python function of calculating the area of a triangle." },
    { "addonoseid": 2, "subtitle": "Step 2", "imgSrc": "triangle-area-test", "subTitleDescription": "Then, we have to prepare a test case or test script to test if the function is executed as expected. Before writing the script, import the unittest function and the triangle_area file created in Step 1, and create a class for the script with customized name, with a parameter of the unittest function - TestCase. Now, we can start wrtiting the script. For example, I create the script on checking whether users have input an integer or float number only, if a user inputs a string, negative number or boolean, an error will be shown." },
    { "addonoseid": 3, "subtitle": "Step 3", "imgSrc": "triangle-area-assert", "subTitleDescription": "To breakdown the condition, let me use above line as example. This script is to test when users input a negative number on either the base or height, or both. The type: assertRaises will be used in these kind of Value Error. Assert also have other checking, like the assertEqual as above shown." },
    { "addonoseid": 4, "subtitle": "Step 4", "imgSrc": "nose-and-pinocchio", "subTitleDescription": "After writing the script, install nose, pinocchio & coverage by using pip install command on the terminal." },
    { "addonoseid": 5, "subtitle": "Step 5", "imgSrc": "triangle-area-result", "subTitleDescription": "Now, run the command: nosetests --with-spec --spec-color --with-coverage, on the terminal, then you will get the test result. Green indicates pass and red indicates error, with a test coverage %." },
    { "addonoseid": 6, "subtitle": "Step 6", "imgSrc": "triangle-area-config-file", "subTitleDescription": "Now, you may ask, where can I store this command in a file so that I don't have to type all those parameters in the terminal everytime. Now, create a config file as above. After that, you just have to input: nosetests, in the terminal to execute the result same as Step 5." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsAutoTestingWithNoseList.map(advancedTopicsDevOpsAutoTestingWithNoseList => (
          <div key={advancedTopicsDevOpsAutoTestingWithNoseList.addonoseid}>
            <Content
              subtitle={advancedTopicsDevOpsAutoTestingWithNoseList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsAutoTestingWithNoseList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsAutoTestingWithNoseList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsAutoTestingWithNoseList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
