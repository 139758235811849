import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function VisualStudioCodeUsefulPackages() {

  let mainTitle = "Visual Studio Code Useful Packages (Extensions)"
  let titleDescription = "Below are some packages in Visual Studio Code (VS code) I found it very useful in the VS Code extension."

  let vsCodeUsefulPackagesList = [
    { "vsupid": 1, "subtitle": "Live Server", "imgSrc": "live-server", "subTitleDescription": "Live server allows user to view and check the webpage without refreshing the page. Simply press & hold [alt], then click [l] and [O] to start the server." },
    { "vsupid": 2, "subtitle": "React ES7", "imgSrc": "reactes7", "subTitleDescription": "ReactJS & React ES7 is a tool designed for Javascript in frontend UI design, mainly serve the purpose of Single Page Application (SPA)." },
    { "vsupid": 3, "subtitle": "Docker", "imgSrc": "vs-code-docker", "subTitleDescription": "This docker tool is useful when you are running docker withh vs code!" },
    { "vsupid": 4, "subtitle": "Python", "imgSrc": "python-extension", "subTitleDescription": "This python tool is useful when you are using python withh vs code!" },
    { "vsupid": 5, "subtitle": "Django", "imgSrc": "django-extension", "subTitleDescription": "This django tool is useful when you are using django withh vs code!" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        vsCodeUsefulPackagesList.map(vsCodeUsefulPackagesList => (
          <div key={vsCodeUsefulPackagesList.vsupid}>
            <Content
              subtitle={vsCodeUsefulPackagesList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + vsCodeUsefulPackagesList.imgSrc + ".png"}
              imgAlt={vsCodeUsefulPackagesList.imgSrc}
              subTitleDescription={vsCodeUsefulPackagesList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
