import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNetworkingFiveLayerModel() {

  let mainTitle = "The TCP/IP Five-Layer Network Model"
  let titleDescription = ""

  let advancedTopicsNetworkingFiveLayerModelList = [
    { "adntflmid": 1, "subtitle": "", "imgSrc": "tcp-ip-five-layer-network-model", "subTitleDescription": "" },
    { "adntflmid": 2, "subtitle": "", "imgSrc": "tcp-ip-five-layer-network-model-example", "subTitleDescription": "You can think of layers like different aspects of a package being delivered. The physical layer is the delivery truck and the roads. The data link layer is how the delivery trucks get from one intersection to the next over and over. The network layer identifies which roads need to be taken to get from address A to address B. The transport layer ensures that delivery driver knows how to knock on your door to tell you your package has arrived. And the application layer is the contents of the package itself." },
    { "adntflmid": 3, "subtitle": "Physcial Layer", "imgSrc": "lan", "subTitleDescription": "The physical layer encompasses the tangible components linking computers, encompassing specifications for networking cables, connectors, and how signals traverse these connections." },
    { "adntflmid": 4, "subtitle": "Data Link (Network Interface) Layer", "imgSrc": "", "subTitleDescription": "At this layer we introduce our first protocols. The data link layer is responsible for defining a common way of interpreting these signals so network devices can communicate. Lots of protocols exist at the data link layer but the most common is known as ethernet. Beyond specifying physical layer attributes, the ethernet standards also define a protocol responsible for getting data to nodes on the same network or link. Your laptop will always have the same MAC address no matter where you use it." },
    { "adntflmid": 5, "subtitle": "Network Layer", "imgSrc": "router", "subTitleDescription": "This layer facilitates communication between different networks via devices called routers. When networks are interconnected through routers, they form an internetwork, with the internet being the most prominent example. Responsible for ensuring data delivery across network collections, the network layer aids in transmitting data between various locations. The predominant protocol employed at this layer is IP (Internet Protocol), which serves as the backbone of the internet and numerous small networks worldwide. Network software typically segregates into client and server categories: client applications initiate data requests, while server software responds to these requests across the network." },
    { "adntflmid": 6, "subtitle": "IP address", "imgSrc": "connecting", "subTitleDescription": "It's important to call out that IP addresses belong to the networks, not to the devices attached to those networks. A different IP address assigned to it at an Internet cafe than it would when you're at home. The LAN at the Internet cafe or the LAN at your house would each be individually responsible for handing out an IP address to your laptop if you power it on there. Dynamic Host Configuration Protocol is responsible for assigning IP addresses, known as a dynamic IP address. The opposite of this is known as a static IP address, which must be configured on a node manually. In most cases, static IP addresses are reserved for servers and network devices while dynamic IP addresses are reserved for clients." },
    { "adntflmid": 7, "subtitle": "Transport Layer", "imgSrc": "tcp", "subTitleDescription": "The big difference between the IP & TCP is that TCP provides mechanisms to ensure that data is reliably delivered while UDP does not." },
    { "adntflmid": 8, "subtitle": "Application Layer", "imgSrc": "", "subTitleDescription": "Finally, the fifth layer, the application layer, encompasses various protocols tailored for specific applications. Protocols facilitating web browsing or email communication are among the common ones found at this layer." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNetworkingFiveLayerModelList.map(advancedTopicsNetworkingOverviewList => (
          <div key={advancedTopicsNetworkingOverviewList.adntovid}>
            <Content
              subtitle={advancedTopicsNetworkingOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNetworkingOverviewList.imgSrc + ".png"}
              imgAlt={advancedTopicsNetworkingOverviewList.imgSrc}
              subTitleDescription={advancedTopicsNetworkingOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
