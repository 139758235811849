import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonPandas() {

  let mainTitle = "Pandas"
  let titleDescription = "Similar to Open function, Panda allows us to read data like a SQL, but unlike print function, Panda allows us to read data in a more structured way, and analyse data from files like csv, xlsx or JSON."

  let pythonPandasList = [
    { "pypid": 1, "subtitle": "Install Pandas", "imgSrc": "python_install_pandas", "subTitleDescription": "Use the VS Code Terminal to install pandas." },
    { "pypid": 2, "subtitle": "Install Pyarrow", "imgSrc": "python_install_pyarrow", "subTitleDescription": "and pyarrow as well." },
    { "pypid": 3, "subtitle": "DataFrame in Pandas with Dictionary", "imgSrc": "python_dataframe_with_pandas", "subTitleDescription": "With DataFrame, the key will become the column heading of the dictionary, and the row will be the \"index\" postion of the key and value pair" },
    { "pypid": 4, "subtitle": "loc in DataFrame", "imgSrc": "python_dataframe_loc_with_pandas_series", "subTitleDescription": "You could use loc() function by inputting the index position to print a specific row." },
    { "pypid": 5, "subtitle": "loc with two parameters", "imgSrc": "python_dataframe_2loc_with_pandas_series", "subTitleDescription": "You could assign a 2nd parameter in the loc() function to print multiple rows." },
    { "pypid": 6, "subtitle": "Series in Pandas", "imgSrc": "python_series_with_pandas", "subTitleDescription": "While for Series, it is more suitable for one-dimensional data (e.g. list), where the key will become the row." },
    { "pypid": 7, "subtitle": "Assigning index with Pandas", "imgSrc": "python_indexing_list_with_pandas_series", "subTitleDescription": "This is an example of printing a list with Series. You could assign variables reaplcing the \"index\" rows (0, 1 ,2). This function also works in DataFrame." },
    { "pypid": 8, "subtitle": "Print with indicated keys", "imgSrc": "python_series_index_with_pandas_series", "subTitleDescription": "You could indicate the key you would like to print with the index function, but this is only working for Series." },
    { "pypid": 9, "subtitle": "Print with indicated keys in DataFrame", "imgSrc": "python_dataframe_index_with_pandas_series", "subTitleDescription": "If you use this above index funtion in a DataFrame, the system will change the index number only." },
    { "pypid": 10, "subtitle": "Slice a list with Pandas", "imgSrc": "python_slice_list_with_pandas_series", "subTitleDescription": "Slicing also work with Pandas." },
    { "pypid": 11, "subtitle": "Analysing 3rd party files with Pandas ", "imgSrc": "python_read_files_with_pandas", "subTitleDescription": "Now you know the basic operations in Pandas. In most of the cases, the data we analyse is from 3rd parties, like csv, excel, or Json. To read files, you have to use the pr.read.csv or excel etc function to get those data." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonPandasList.map(pythonPandasList => (
          <div key={pythonPandasList.pyid}>
            <Content
              subtitle={pythonPandasList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonPandasList.imgSrc + ".png"}
              imgAlt={pythonPandasList.imgSrc}
              subTitleDescription={pythonPandasList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}