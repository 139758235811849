import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function HomePage() {

  let homePageList = [
    { "hpid": 1, "name": "Setting Up", "urlname": "setting-up", "Description": "Prior to exploration, kindly step in here to install the necessary packages."},
    { "hpid": 2, "name": "Integrated Development Environments ", "urlname": "integrated-development-environments", "Description": "An Integrated Development Environment (IDE) is a software application that provides comprehensive tools and features to computer programmers for developing software."},
    { "hpid": 3, "name": "Git & GitHub", "urlname": "git&github", "Description": "Git is a distributed version control system for tracking changes in source code, while GitHub is a web-based platform that hosts Git repositories, facilitating collaboration and code sharing."},
    { "hpid": 4, "name": "Programming Language", "urlname": "programming-language", "Description": "A programming language is a set of rules and conventions used to instruct a computer, enabling developers to create software and applications with specific functionalities."},
    { "hpid": 5, "name": "Advanced Topics", "urlname": "advanced-topics", "Description": "Up for a challenge? Join us here!", "Image": "python.png" },
  ]

  return (
    <div>
      <h1>Easy Programming</h1>
      <h3>
        The beginning of learning a programming are filled with frustrations. But fear not! <br /><br />
        Coming from a business background without any IT-related diploma, I completely
        understand the frustrations you might be facing.<br /><br />
        Whether you have a background in programming or not, this page is tailored for
        everyone.<br /><br />
        I've kept things simple, using simple & plain "English" to cater to those, like myself,
        who
        come from a non-IT background.
      </h3>
      <hr></hr>
      <div className="card-wrapper">{
        homePageList.map(homePageList => (
          <div key={homePageList.hpid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + homePageList.urlname + ".png"}
              imgAlt={homePageList.urlname}
              title={<Link to={'/' + homePageList.urlname}>{homePageList.name}</Link>}
              description={homePageList.Description}
            />
          </div>
        ))
      }
      </div>

    </div>
  );
}