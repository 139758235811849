import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle"

export default function GitGitHubGitIgnore() {

  let mainTitle = "Git Ignore"
  let titleDescription = "At times, we might generate files for logging purposes or create files that contain private or confidential information, which we prefer not to disclose or share with the public. Git ignore (.gitignore) is coming into play to rescue."

  let gitGitHubGitIgnoreList = [
    { "gitgigid": 1, "subtitle": "Step 1", "imgSrc": "git-ignore", "subTitleDescription": "Open Visual Studio Code and create a file, name it as [.gitignore]." },
    { "gitgigid": 2, "subtitle": "Step 2", "imgSrc": "git-ignore-input", "subTitleDescription": "As an example, I created a file [1.log] to prepare for ignore. Input the file name that you wish to ignore in [.gitignore], which is [1.log] in this case, and save." },
    { "gitgigid": 3, "subtitle": "Step 3", "imgSrc": "git-ignore-source-contorl", "subTitleDescription": "After saving, from the [Source Contorl], you could see the changes include the [.gitignore] only, because the file [1.log] have been ignored." },
    { "gitgigid": 4, "subtitle": "Step 4", "imgSrc": "multiple-log-ignore", "subTitleDescription": "If you have mulitple files that would like to ignore, for example mutliple log files. Input:*.log, where [*] means all, and .log is the file nature. For example, You could input *.html, if you would like to ignore all html files." },
    { "gitgigid": 5, "subtitle": "Step 5", "imgSrc": "ignore-folder", "subTitleDescription": "If you want to ignore a folder instead of file, input:/[folder name], where [/] means folder path, and [folder name] is the folder name you would like to ignore. For example, /private." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        gitGitHubGitIgnoreList.map(gitGitHubGitIgnoreList => (
          <div key={gitGitHubGitIgnoreList.gitgigid}>
            <Content
              subtitle={gitGitHubGitIgnoreList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + gitGitHubGitIgnoreList.imgSrc + ".png"}
              imgAlt={gitGitHubGitIgnoreList.imgSrc}
              subTitleDescription={gitGitHubGitIgnoreList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
