import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function PythonFrameworkDjangoSuperUser () {

  let mainTitle = "Django Superuser"
  let titleDescription = "";

  let pythonFrameworkDjangoSuperuserList = [
    { "pyfrdjsuid": 1, "subtitle": "Step 1 - Create a superuser", "imgSrc": "django-create-superuser", "subTitleDescription": "Input [python .\\manage.py createsuperuser], and input the user name, email and password." },
    { "pyfrdjsuid": 2, "subtitle": "Step 2 - Login admin site", "imgSrc": "django-admin-login", "subTitleDescription": "Now, make sure the server is running and input admin on the browser, you will see a login page as shwon above. Input the superuser info you have just created." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        pythonFrameworkDjangoSuperuserList.map(pythonFrameworkDjangoSuperuserList => (
          <div key={pythonFrameworkDjangoSuperuserList.pyfrdjsuid}>
            <Content
              subtitle={pythonFrameworkDjangoSuperuserList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonFrameworkDjangoSuperuserList.imgSrc + ".png"}
              imgAlt={pythonFrameworkDjangoSuperuserList.imgSrc}
              subTitleDescription={pythonFrameworkDjangoSuperuserList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
