import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function containerOrchestrationKubernetesRedHatOpenShiftOperators() {

  let mainTitle = "OpenShift Operators"
  let titleDescription = ""

  let containerOrchestrationKubernetesRedHatOpenShiftOperatorsList = [
    { "ctorochrhooid": 1, "subtitle": "What is OpenShift Operators?", "imgSrc": "red-hat-openshift-operators", "subTitleDescription": "Operators automate cluster tasks and act as a custom controller to extend the Kubernetes API. Operators run in a Pod and interact with the API server, package, deploy, and manage Kubernetes applications, and automate app creation, configuration, and management via continuous real-time decisions." },
    { "ctorochrhooid": 2, "subtitle": "Human vs Software Operators", "imgSrc": "red-hat-openshift-human-software-operators", "subTitleDescription": "There are human operators and software operators. Human operators understand the systems they control. They know how to deploy services and how to recognize and fix problems. Software operators try to capture the knowledge of human operators and automate the same processes." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.map(containerOrchestrationKubernetesRedHatOpenShiftOperatorsList => (
          <div key={containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.ctorochrhooid}>
            <Content
              subtitle={containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesRedHatOpenShiftOperatorsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
