import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerDockerOverview() {

  let mainTitle = "Docker Overview"
  let titleDescription = "Docker is a platform that enables developers to automate the deployment of applications inside lightweight, portable containers. Containers are standalone, executable packages that include everything needed to run a piece of software, including the code, runtime, libraries, and system tools"

  let containerDockerOverviewList = [
    { "ctdkovid": 1, "subtitle": "What is docker?", "imgSrc": "docker", "subTitleDescription": "Available since 2013, the official Docker definition, paraphrased, states that Docker is an open platform for developing, shipping, and running applications as containers." },
    { "ctdkovid": 2, "subtitle": "Characteristics", "imgSrc": "docker_characteristics", "subTitleDescription": "Simple architecture, massive scalability, and portability on multiple platforms, environments, and locations. Docker isolates applications from infrastructure, including the hardware, the operating system, and the container runtime. Docker is written in the Go programming language Docker uses Linux kernel features to deliver its functionality. Docker also uses namespaces to provide an isolated workspace called the container. And Docker creates a set of namespaces for every container and each aspect runs in a separate namespace with access limited to that namespace. " },
    { "ctdkovid": 3, "subtitle": "Benefits of docker", "imgSrc": "docker_benefit", "subTitleDescription": "Docker ensures stable and quick application deployments through consistent and isolated environments. With deployments taking seconds, its small, reusable images accelerate development. Automation minimizes errors, simplifying maintenance. Supporting Agile and CI/CD DevOps practices, Docker enables easy versioning for testing, rollbacks, and redeployments. It facilitates application segmentation for quick refresh, cleanup, and repair, fostering efficient collaboration among developers. Docker's platform-independent images enhance portability." },
    { "ctdkovid": 4, "subtitle": "Limitation", "imgSrc": "docker_challenge", "subTitleDescription": "Docker is not a good fit for applications with these qualities: Require high performance or security, are based on Monolith architecture, use rich GUI features, or perform standard desktop or limited functions." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerDockerOverviewList.map(containerDockerOverviewList => (
          <div key={containerDockerOverviewList.ctdkovid}>
            <Content
              subtitle={containerDockerOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerDockerOverviewList.imgSrc + ".png"}
              imgAlt={containerDockerOverviewList.imgSrc}
              subTitleDescription={containerDockerOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}