import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function Container() {

  let containerList = [
    { "ctid": 1, "name": "Overview", "urlname": "overview", "Description": "What is a container?" },
    { "ctid": 2, "name": "Docker", "urlname": "docker", "Description": "Docker is a platform that enables developers to automate the deployment of applications inside lightweight, portable containers. Containers are standalone, executable packages that include everything needed to run a piece of software, including the code, runtime, libraries, and system tools" },
    { "ctid": 3, "name": "Container Orchestration", "urlname": "container-orchestration", "Description": "Container orchestration is the automated management, deployment, scaling, and coordination of containerized applications. When dealing with a large number of containers distributed across multiple hosts or nodes, container orchestration tools help streamline and optimize the management of these containers to ensure they work together seamlessly. Container orchestration provides a framework for automating various tasks related to deploying, scaling, and maintaining containerized applications." },
  ]


  return (
    <div>
      <h1>Container</h1><hr></hr>
      <div className="card-wrapper">{
        containerList.map(containerList => (
          <div key={containerList.ctid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerList.urlname + ".png"}
              imgAlt={containerList.urlname}
              title={<Link to={'/advanced-topics/container/' + containerList.urlname}>{containerList.name}</Link>}
              description={containerList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
