import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonCondition() {

  let pythonConditionList = [
    { "pyfid": 1, "name": "Operators", "urlname": "operators", "Description": "" },
    { "pyfid": 2, "name": "If else", "urlname": "if-else", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Condition</h1><hr></hr>
      <div className="card-wrapper">{
        pythonConditionList.map(pythonConditionList => (
          <div key={pythonConditionList.pyid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonConditionList.urlname + ".png"}
              imgAlt={pythonConditionList.urlname}
              title={<Link to={'/programming-language/python/condition/' + pythonConditionList.urlname}>{pythonConditionList.name}</Link>}
              description={pythonConditionList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
