import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function PythonDataType() {

  let pythonDataTypeList = [
    { "pydid": 1, "name": "String", "urlname": "string", "Description": "" },
    { "pydid": 2, "name": "Numbers", "urlname": "numbers", "Description": "" },
    { "pydid": 3, "name": "Data Structure", "urlname": "data-structure", "Description": "" },
  ]

  return (
    <div>
      <h1>Python Data Type</h1><hr></hr>
      <div className="card-wrapper">{
        pythonDataTypeList.map(pythonDataTypeList => (
          <div key={pythonDataTypeList.pydid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + pythonDataTypeList.urlname + ".png"}
              imgAlt={pythonDataTypeList.urlname}
              title={<Link to={'/programming-language/python/data-type/' + pythonDataTypeList.urlname}>{pythonDataTypeList.name}</Link>}
              description={pythonDataTypeList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
