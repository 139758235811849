import React from 'react';
import { Card } from "./components/Card";
import { Link } from 'react-router-dom';

export default function AdvancedTopicsCloudComputingAWS() {

  let advancedTopicsCloudComputingAWSList = [
    { "adawsid": 1, "name": "Networking", "urlname": "networking", "Description": "" },
  ]


  return (
    <div>
      <h1>AWS</h1><hr></hr>
      <div className="card-wrapper">{
        advancedTopicsCloudComputingAWSList.map(advancedTopicsCloudComputingAWSList => (
          <div key={advancedTopicsCloudComputingAWSList.adawsid}>
            <Card
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsCloudComputingAWSList.urlname + ".png"}
              imgAlt={advancedTopicsCloudComputingAWSList.urlname}
              title={<Link to={'/advanced-topics/cloud-computing/aws/' + advancedTopicsCloudComputingAWSList.urlname}>{advancedTopicsCloudComputingAWSList.name}</Link>}
              description={advancedTopicsCloudComputingAWSList.Description}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}