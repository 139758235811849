import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function JavaScriptCustomizeFunctions() {


  let mainTitle = "JavaScript Customize Functions"
  let titleDescription = "There are two ways to create a custom function in JavaScript: Function Declaration: Hoisted; Function Expression: Not hoisted. So what is hoisted actually?"

  let javaScriptCustomizeFunctionsList = [
    { "jscuid": 1, "subtitle": "Function Declaration & Function Expression", "imgSrc": "2-function", "subTitleDescription": "Both functions above acheive the same result, to print the text 'hello' & 'hi'. But Function Declaration (hoisted) allow user to use the function before defining the function, as you could see above, the printHello() can insert before the printHello() function was defined, while Function Expression only allow user to use the function after they defined irt." },
    { "jscuid": 2, "subtitle": "Arrow function with Function Expression", "imgSrc": "function-expression", "subTitleDescription": "If you are using Function Expression, you can simplify your code with arrow function. Simply replacing function() by: ()=>" },
    { "jscuid": 3, "subtitle": "Parameter & Global / Local variable", "imgSrc": "parameter", "subTitleDescription": "Parameter is an element inside a function, acting as a local variable. This allow user to have a higher flexibility on using function without hard coding the variable. From above example, 'message' & 'message2' are the local variables of the function. Local variable means the variable 'message' & 'message2' cannot be console.log() or assign to another value outside the function, while global variable are those variables defined outside a function." },
    { "jscuid": 4, "subtitle": "Default Parameter", "imgSrc": "default-parameter", "subTitleDescription": "Above is an example of default parameter, user can assign a default value to the parameter, if nothing was input in the rectangleArea, it will be calculated as 3*5. If a value was input, let say: rectabgleArea(4) the value input will override the default parameter in order (The area will be 4*5)." },
    { "jscuid": 5, "subtitle": "Return Function", "imgSrc": "local-variable", "subTitleDescription": "Return is a function in JavaScript to resolve the local variable limitation. From above example, the local variable \"area\" cannot be used outside the function for further process, to reolve this, user can use \"return\" in the function. By doing so, the local variable can be used for further operation, e.g. assign another variable - output, to the function." },
    { "jscuid": 6, "subtitle": "Callback Function", "imgSrc": "callback-function", "subTitleDescription": "A callback function means the parameter itself is also a function within the main function. Callback function is useful when user want to a function to be exceuted under certain condition. For example, you want to print a text after 4 seconds the system run, this is call Asyncronous JavaScript. " },

  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        javaScriptCustomizeFunctionsList.map(javaScriptCustomizeFunctionsList => (
          <div key={javaScriptCustomizeFunctionsList.jscuid}>
            <Content
              subtitle={javaScriptCustomizeFunctionsList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + javaScriptCustomizeFunctionsList.imgSrc + ".png"}
              imgAlt={javaScriptCustomizeFunctionsList.imgSrc}
              subTitleDescription={javaScriptCustomizeFunctionsList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
