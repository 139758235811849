import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsDevOpsCD() {

  let mainTitle = "Continuous Delivery"
  let titleDescription = ""

  let advancedTopicsDevOpsCDList = [
    { "addocdid": 1, "subtitle": "Continuous Delivery", "imgSrc": "continuous-delivery", "subTitleDescription": "Continuous Delivery means deployment (master) to production is always possible, which require Continuous Integration to ensure all code was tested before merging to the master" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsDevOpsCDList.map(advancedTopicsDevOpsCDList => (
          <div key={advancedTopicsDevOpsCDList.addocdid}>
            <Content
              subtitle={advancedTopicsDevOpsCDList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsDevOpsCDList.imgSrc + ".png"}
              imgAlt={advancedTopicsDevOpsCDList.imgSrc}
              subTitleDescription={advancedTopicsDevOpsCDList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
