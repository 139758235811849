import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function AdvancedTopicsNodeJsOverview() {

  let mainTitle = "NodeJS"
  let titleDescription = (<>Please install <a href="https://nodejs.org/en">NodeJS</a> in your local PC first.</>);

  let advancedTopicsNodeJsOverviewList = [
    { "adnjovid": 1, "subtitle": "What is NodeJS?", "imgSrc": "nodejs", "subTitleDescription": "NodeJS allows user to use JavaScript to perform backend tasks (Create/Read/Remove files, build server etc)." },
    { "adnjovid": 2, "subtitle": "Setting up NodeJS", "imgSrc": "node-run", "subTitleDescription": "After you have installed NodeJS, open a js file and randomly create some javascript in a js file in VS code. Next, open the terminal and input node [file name], simple!" },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        advancedTopicsNodeJsOverviewList.map(advancedTopicsNodeJsOverviewList => (
          <div key={advancedTopicsNodeJsOverviewList.adnjovid}>
            <Content
              subtitle={advancedTopicsNodeJsOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + advancedTopicsNodeJsOverviewList.imgSrc + ".png"}
              imgAlt={advancedTopicsNodeJsOverviewList.imgSrc}
              subTitleDescription={advancedTopicsNodeJsOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
