import React from 'react';
import { Content } from "./components/Content";
import { ContentTitle } from "./components/ContentTitle";

export default function ContainerOrchestrationKubernetesRedHatOpenShiftOverview() {

  let mainTitle = "RedHat OpenShift"
  let titleDescription = "Red Hat OpenShift is a container orchestration platform that helps organizations develop, deploy, and manage containerized applications. It's built on top of Kubernetes, an open-source container orchestration platform, but adds additional features and tools to simplify the application development and deployment process."

  let containerOrchestrationKubernetesRedHatOpenShiftOverviewList = [
    { "ctorochrhoovid": 1, "subtitle": "What is RedHat OpenShift?", "imgSrc": "red-hat-openshift", "subTitleDescription": "Openshift provides a consistent application platform to manage hybrid, multicloud, and edge deployments. It is built on the technological foundation of Linux®, containers, and automation. It provides full-stack automated operations and self-service provisioning for developers to efficiently move ideas from development to production. And besides container orchestration, it provides additional tooling around the complete lifecycle of applications, from build, to CI/CD, to monitoring and logs." },
    { "ctorochrhoovid": 2, "subtitle": "Features of RedHat OpenShift", "imgSrc": "red-hat-openshift-features", "subTitleDescription": "Above are some features of OpenShift." },
    { "ctorochrhoovid": 3, "subtitle": "Kubernetes & OpenShift", "imgSrc": "red-hat-openshift-kubernetes", "subTitleDescription": "Both Kubernetes and OpenShift are container orchestration platforms. Kubernetes is a critical component of OpenShift. OpenShift is used as an extension of Kubernetes to provide a more robust and comprehensive platform for containerized applications." },
    { "ctorochrhoovid": 4, "subtitle": "Difference between Kubernetes &  OpenShift", "imgSrc": "red-hat-openshift-difference", "subTitleDescription": "Above are some differences between Kubernetes & OpenShift." },
    { "ctorochrhoovid": 5, "subtitle": "OpenShift Architecture", "imgSrc": "red-hat-openshift-architecture", "subTitleDescription": "OpenShift runs on top of a Kubernetes cluster, with object data stored in the etcd key-value store. It has a microservices-based architecture. Its services are: REST APIs, which expose the core objects. And controllers, which read the REST APIs, apply changes to the other objects, and report status or write back to the object. They also maintain the cluster desired state. Docker provides abstraction for packaging and creating Linux-based, lightweight container images. Kubernetes provides cluster management and orchestrates containers on multiple hosts. OpenShift features add: Management of source code, builds, and deployments for developers. Managing and promoting images at scale as they flow through your system. Application management at scale. Team and user tracking management of a large developer organization. And networking infrastructure that supports the cluster." },
    { "ctorochrhoovid": 6, "subtitle": "Kubectl or OpenShift?", "imgSrc": "red-hat-openshift-kubernetes-kubectl", "subTitleDescription": "Since OpenShift runs on top of a Kubernetes cluster, a copy of kubectl is also included with oc. The oc and kubectl binary offer the same capabilities, but oc is further extended to natively support OpenShift features, such as: DeploymentConfigs, BuildConfigs, Routes, ImageStreams and ImageStreamTags. These are not available in standard Kubernetes. oc also offers an in-built log-in command for authentication. And additional commands like ‘new-app’ are also supported by oc, which makes it easier to get new applications started using existing source code or prebuilt images." },
  ]

  return (
    <div>
      <ContentTitle
        mainTitle={mainTitle}
        titleDescription={titleDescription}
      />
      <div>{
        containerOrchestrationKubernetesRedHatOpenShiftOverviewList.map(containerOrchestrationKubernetesRedHatOpenShiftOverviewList => (
          <div key={containerOrchestrationKubernetesRedHatOpenShiftOverviewList.ctorochrhoovid}>
            <Content
              subtitle={containerOrchestrationKubernetesRedHatOpenShiftOverviewList.subtitle}
              imgSrc={process.env.PUBLIC_URL + "/pic/" + containerOrchestrationKubernetesRedHatOpenShiftOverviewList.imgSrc + ".png"}
              imgAlt={containerOrchestrationKubernetesRedHatOpenShiftOverviewList.imgSrc}
              subTitleDescription={containerOrchestrationKubernetesRedHatOpenShiftOverviewList.subTitleDescription}
            />
          </div>
        ))
      }
      </div>
    </div>
  );
}
